import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Avatar, Grid, IconButton } from "@mui/material";
import {
  DarkMode,
  Info,
  LightMode,
  Person,
  ShoppingBag,
  ShoppingCart,
} from "@mui/icons-material";
import SimpleDialog from "../dialogs/SimpleDialog";
import ListView from "../ui/ListView";
import { getFinalProductPrice, toServerImage } from "../../utils/generic";
import Title from "../ui/Title";
import TabContainer from "../tabs/TabContainer";
import Message from "../ui/Message";

const customIcon = new L.Icon({
  iconUrl: "/marker-icon.png",
  //   iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const LeafletMap = ({ data }) => {
  const [cart, setCart] = useState(null);
  const [mode, setMode] = useState(() => {
    const mode = localStorage.getItem("map-analytics-mode");
    if (["light", "dark"].indexOf(mode) === -1) {
      localStorage.setItem("map-analytics-mode", "light");
      return "light";
    }

    return mode;
  });
  const titleToModeMap = {
    light: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    dark: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
  };
  const [center, setCenter] = useState((prev) => {
    const spot = (data || []).find(
      (x) =>
        typeof x.geolocation?.location?.latitude !== "undefined" &&
        typeof x.geolocation?.location?.longitude !== "undefined"
    );

    let centerValue = [0, 0];
    if (spot) {
      centerValue = [
        spot.geolocation.location.latitude,
        spot.geolocation.location.longitude,
      ];
    }
    return centerValue;
  });
  const ref = useRef(null);
  const mapRef = useRef(null);
  useEffect(() => {
    if (data?.length && ref.current) {
      return;
    }

    const spot = (data || []).find(
      (x) =>
        typeof x.geolocation?.location?.latitude !== "undefined" &&
        typeof x.geolocation?.location?.longitude !== "undefined"
    );

    let centerValue = [0, 0];
    if (spot) {
      centerValue = [
        spot.geolocation.location.latitude,
        spot.geolocation.location.longitude,
      ];
    }
    setCenter(centerValue);

    ref.current = true;
  }, [data]);

  const currentCartItems = useMemo(() => {
    if (!cart) {
      return [];
    }

    if (!data?.length) {
      return [];
    }

    return data
      ?.find((x) => x.session_id === cart)
      ?.cart?.items?.map((item) => ({
        primary: (
          <Title>
            <Avatar src={toServerImage(item.image)} /> {item.name}
          </Title>
        ),
        secondary: (
          <small>
            {item.quantity} x {getFinalProductPrice(item)} &euro;
          </small>
        ),
      }));
  }, [data, cart]);

  const currentCartInfo = useMemo(() => {
    if (!cart) {
      return [];
    }

    if (!data?.length) {
      return [];
    }

    return data?.find((x) => x.session_id === cart);
  }, [cart, data]);

  return (
    <>
      <IconButton
        disabled={mode === "light"}
        onClick={() => {
          setMode("light");
          localStorage.setItem("map-analytics-mode", "light");
        }}
      >
        <LightMode />
      </IconButton>
      <IconButton
        disabled={mode === "dark"}
        onClick={() => {
          setMode("dark");
          localStorage.setItem("map-analytics-mode", "dark");
        }}
      >
        <DarkMode />
      </IconButton>
      <MapContainer
        ref={mapRef}
        center={center} // Set your initial center here
        zoom={7} // Set the initial zoom level here
        style={{ height: "500px", width: "100%" }}
      >
        <TileLayer url={titleToModeMap[mode]} />

        {data
          .filter(
            (x) =>
              typeof x.geolocation?.location?.latitude !== "undefined" &&
              typeof x.geolocation?.location?.longitude !== "undefined"
          )
          .map((item, index) => (
            <Marker
              icon={customIcon}
              key={index}
              position={[
                item.geolocation.location.latitude,
                item.geolocation.location.longitude,
              ]}
            >
              <Popup>
                <div>
                  <h3 style={{ fontSize: "14px", wordBreak: "break-all" }}>
                    {item?.user?.email || item?.session_id}
                  </h3>
                  <hr />
                  <small style={{ wordBreak: "break-all" }}>
                    Path: <b>{item?.browser?.pathname}</b>
                  </small>
                  <hr />
                  <small style={{ wordBreak: "break-all" }}>
                    City: <b>{item?.geolocation?.city?.names?.en || "-"}</b>
                  </small>
                  <hr />
                  <small style={{ wordBreak: "break-all" }}>
                    Localization: <b>{item?.localization?.language || "-"}</b>
                  </small>
                  <hr />
                  <small style={{ wordBreak: "break-all" }}>
                    Device Type: <b>{item?.device?.deviceType || "-"}</b>
                  </small>
                  <hr />
                  <small style={{ wordBreak: "break-all" }}>
                    Cart:
                    <IconButton
                      onClick={() => setCart(item.session_id)}
                      disabled={!item?.cart?.items?.length}
                    >
                      <Info />
                    </IconButton>
                  </small>
                </div>
              </Popup>
            </Marker>
          ))}
      </MapContainer>
      <SimpleDialog
        title={
          <Title divider>
            <Person sx={{ mr: 2 }} />
            {currentCartInfo?.user?.email || currentCartInfo?.session_id || "-"}
          </Title>
        }
        open={!!cart}
        onCancel={() => setCart(null)}
      >
        <TabContainer
          tabs={[
            { label: "Cart", icon: <ShoppingCart /> },
            { label: "Order", icon: <ShoppingBag /> },
          ]}
        >
          <ListView items={currentCartItems} />
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              {!currentCartInfo?.order ? (
                <Message message="Nothing at the moment" />
              ) : (
                <ListView
                  items={[
                    {
                      primary: currentCartInfo?.order?.id,
                      secondary: "Order ID",
                    },
                    {
                      primary: currentCartInfo?.order?.payment_method?.name,
                      secondary: "Payment Method",
                    },
                    {
                      primary: currentCartInfo?.order?.order_status?.name,
                      secondary: "Order Status",
                    },
                  ]}
                />
              )}
            </Grid>
          </Grid>
        </TabContainer>
      </SimpleDialog>
    </>
  );
};

export default LeafletMap;
