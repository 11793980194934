import React, { useEffect, useMemo, useState } from "react";
import { ROLES } from "../data/roles";
import withRoles from "../hoc/withRoles";
import withAuth from "../hoc/withAuth";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../apiClient";
import { toApiUrl, toTrackingServiceUrl } from "../utils/generic";
import Message from "../components/ui/Message";
import BackdropLoader from "../components/ui/BackdropLoader";
import { Grid, IconButton, Paper, Tooltip } from "@mui/material";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import {
  Block,
  Check,
  Delete,
  FlagCircle,
  InfoOutlined,
  Language,
  PeopleAltOutlined,
  StackedLineChart,
} from "@mui/icons-material";

import ListView from "../components/ui/ListView";
import Title from "../components/ui/Title";
import LeafletMap from "../components/analytics/AnalyticsMap";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import GoBackButton from "../components/ui/GoBackButton";
import { addNotification } from "../store/actions/notifications";
import WebsiteTimeSeries from "../components/websites/WebsiteTimeSeries";
import PopularWebsiteProducts from "../components/websites/PopularWebsiteProducts";
function AnalyticsPage() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [website, setWebsite] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWebsite = async () => {
      try {
        const { data } = await axios.get(toApiUrl(`/websites/${id}`));
        setWebsite(data);
      } catch (error) {
        setWebsite(null);
        navigate("/404");
      }
    };
    const listener = async () => {
      try {
        const { data } = await axios.get(toTrackingServiceUrl("/tracking"), {
          params: { website_id: id },
        });
        setData(() => data);
      } catch (error) {
        setData(null);
      }
    };

    let interval;
    setLoading(true);

    fetchWebsite()
      .then(() => {
        listener()
          .then(() => {
            interval = setInterval(listener, 5000);
            setLoading(false);
          })
          .catch((err) => setLoading(false));
      })
      .catch((err) => setLoading(false));

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [id, navigate]);

  const popularityFilters = useMemo(() => {
    return {
      website: website?.id,
      order_by: "popularity:desc",
      view: 12,
    };
  }, [website?.id]);

  const mostViewedFilters = useMemo(() => {
    return {
      website: website?.id,
      order_by: "most_viewed:desc",
      view: 12,
    };
  }, [website?.id]);

  if (!website) {
    return null;
  }

  if (!data) {
    return <Message message="Nothing at the moment" />;
  }

  const {
    events = [],
    aggregate = { byCountry: [], byPathName: [] },
    total = 0,
    logs = [],
  } = data || {};

  const { byCountry, byPathName } = aggregate;

  const handleClearCache = async () => {
    setLoading(true);
    try {
      await axios.delete(toTrackingServiceUrl(`/tracking/${website.id}`));
      const { data } = await axios.get(toTrackingServiceUrl("/tracking"), {
        params: { website_id: id },
      });
      setData(() => data);
      addNotification("Action Completed", "success");
    } catch (error) {
      addNotification("Action Failed", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BackdropLoader open={loading} />
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <AppWidgetSummary icon={<StackedLineChart />} title={"Analytics"} />
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <AnalyticsCard
            title={website.domain}
            icon={<Language />}
            count={
              website.enabled ? (
                <Check color="success" />
              ) : (
                <Block color="error" />
              )
            }
          />
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <AnalyticsCard
            icon={<PeopleAltOutlined />}
            count={total}
            title="Online Users"
          />
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <AnalyticsCard
            icon={<FlagCircle />}
            count={byCountry?.length}
            title="Countries"
          />
        </Grid>

        <Grid item>
          <GoBackButton to={-1} />
        </Grid>
        <Grid item flexGrow={1} justifySelf={"flex-end"}>
          <Tooltip title="Clear Cache">
            <IconButton onClick={handleClearCache}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item md={12} xs={12}>
          <Paper sx={{ p: 2 }}>
            <LeafletMap data={events} />
          </Paper>
        </Grid>
        <Grid item md={12} xs={12}>
          <PopularWebsiteProducts
            title={`Most Popular Products for ${website?.domain || "-"}`}
            filters={popularityFilters}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <PopularWebsiteProducts
            title={`Most Viewed Products for ${website?.domain || "-"}`}
            filters={mostViewedFilters}
          />
        </Grid>
        {/* 
        <Grid item md={6} xs={12}>
          <WebsiteTimeSeries
            id={website.id}
            title={"Traffic Every 5 seconds"}
          />
        </Grid> */}

        <Grid item md={6} xs={12}>
          <ListView
            sx={{ maxHeight: "450px", overflowY: "auto" }}
            items={logs.map((log, idx, logs) => ({
              icon: <InfoOutlined />,
              primary: (
                <>
                  ({logs.length - idx}){log?.user?.email || log?.session_id}
                </>
              ),
              secondary: (
                <>
                  {log.event_type}(
                  {new Date(log?.timestamp)?.toLocaleString("el-GR", {
                    timeZone: "Europe/Athens",
                  })}
                  )
                </>
              ),
            }))}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <ListView
                sx={{ minHeight: "100%" }}
                title={
                  <Title divider>
                    <Language /> By Page
                  </Title>
                }
                items={byPathName.map((x) => ({
                  primary: (
                    <Title>
                      <PeopleAltOutlined /> {x.events?.length || 0}
                    </Title>
                  ),
                  secondary: (
                    <small>
                      <a
                        style={{ textDecoration: "none" }}
                        href={`${website.domain}${x.pathname}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {x.pathname}
                      </a>
                    </small>
                  ),
                }))}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ListView
                title={
                  <Title divider>
                    <FlagCircle /> By Country
                  </Title>
                }
                items={byCountry.map((x) => ({
                  primary: (
                    <Title>
                      <PeopleAltOutlined /> {x.events?.length || 0}
                    </Title>
                  ),
                  secondary: x.name,
                }))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withAuth(
  withRoles(AnalyticsPage, [ROLES.SUPER_ADMIN, ROLES.ADMIN])
);
