/* eslint-disable react/prop-types */
import React from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

export default function Input({
  name,
  onChange,
  value,
  label,
  startIcon,
  endIcon,
  iconAction,
  type,
  isValid,
  isTouched = true,
  text,
  error,
  variant,
  sx,
  fullWitdth = true,
  disabled,
  id,
  className,
  style,
  multiline,
  rows,
  onBlur,
  focused = false,
  ...rest
}) {
  const renderHelperText = () => {
    if (!isValid && isTouched) return error || undefined;
    if (text) return text;
  };

  return (
    <TextField
      size="small"
      focused={focused}
      rows={rows}
      multiline={multiline}
      id={id}
      className={className}
      style={style}
      disabled={disabled}
      label={label}
      sx={sx}
      fullWidth={fullWitdth}
      variant={variant}
      value={value}
      name={name}
      type={type}
      error={isTouched && !isValid}
      onChange={onChange}
      onBlur={onBlur}
      helperText={renderHelperText()}
      InputProps={{
        ...(typeof startIcon !== "undefined" && {
          startAdornment: (
            <InputAdornment
              position="start"
              onClick={iconAction}
              sx={{ cursor: "pointer" }}
            >
              {startIcon}
            </InputAdornment>
          ),
        }),
        ...(typeof endIcon !== "undefined" && {
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={iconAction}
              sx={{ cursor: "pointer" }}
            >
              {endIcon}
            </InputAdornment>
          ),
        }),
      }}
      {...rest}
    />
  );
}
