import { Lock } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ResetPasswordForm from "../components/auth/ResetPasswordForm";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import BackdropLoader from "../components/ui/BackdropLoader";
import ValidationService from "../services/ValidationService";
import { verifyResetPasswordRequest } from "../store/actions/auth";
import useQueryParams from "../store/hooks/useQueryParams";

function ResetPasswordPage({ verifyResetPasswordRequest, loading }) {
  const [email, setEmail] = useState(null);
  const query = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    verifyResetPasswordRequest(query.get("token"))
      .then((res) => {
        if (!ValidationService.validateEmail(res?.email)) {
          navigate("/");
          return;
        }
        setEmail(res?.email);
      })
      .catch((err) => {
        navigate("/");
      });
  }, [navigate, query, verifyResetPasswordRequest]);

  if (!email) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <BackdropLoader open={loading} />
      <AnalyticsCard title="Reset Password" icon={<Lock />} divider>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <ResetPasswordForm email={email} token={query.get("token")} />
          </Grid>
        </Grid>
      </AnalyticsCard>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    loading: auth.loading,
  }),
  { verifyResetPasswordRequest }
)(ResetPasswordPage);
