import { Language, Mail, ShoppingBag } from "@mui/icons-material";
import { Avatar, Chip } from "@mui/material";
import { toServerImage } from "../../utils/generic";
import ActionsMenu from "../dataGrid/ActionsMenu";

export const InventoryColumns = [
  {
    name: "image",
    label: "image",
    renderComponent: ({ row }) => (
      <Avatar src={toServerImage(row?.product?.image)} />
    ),
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ row }) => (
      <Chip icon={<ShoppingBag />} label={row.product?.name} size="small" />
    ),
  },
  { name: "id", label: "Stock ID" },

  {
    name: "quantity",
    label: "Stock",
    renderComponent: ({ row }) => {
      return row?.suppliers?.length
        ? row?.suppliers.reduce(
            (acc, x) => acc + (x.quantity >= 0 ? x.quantity : 0),
            0
          )
        : 0;
    },
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const SupplierColumns = [
  {
    name: "image",
    label: "image",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
  },
  {
    name: "email",
    label: "Email",
    renderComponent: ({ value }) => (
      <Chip icon={<Mail />} label={`${value || "-"}`} size="small" />
    ),
  },
  {
    name: "name",
    label: "Name",
  },
  {
    name: "website",
    label: "XML Link",
    renderComponent: ({ value }) => (
      <Chip
        icon={<Language />}
        label={`${value || "-"}`}
        size="small"
        onClick={() => window.open(value, "_blank")}
      />
    ),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];
