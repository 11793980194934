import { Mail } from "@mui/icons-material";
import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import Message from "../components/ui/Message";
import { resendVerifyAccount } from "../store/actions/auth";

function VerifyEmailPage({ resendVerifyAccount, loading }) {
  const [disabled, setDisabled] = useState(false);

  const handleSendVerification = async () => {
    const res = await resendVerifyAccount();
    setDisabled(Boolean(res));
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Message message={"Please verify your acount"} />
        </Grid>
        <Grid item md={12} xs={12}>
          <Button
            variant="contained"
            size="small"
            startIcon={<Mail />}
            disabled={loading || disabled}
            onClick={handleSendVerification}
          >
            Verify Account
          </Button>
        </Grid>
        <Grid item md={12} xs={12} display="flex" justifyContent="center">
          <img
            src="/verify-mail.png"
            alt="Verify"
            style={{
              width: "100%",
              height: "400px",
              objectFit: "fill",
              aspectRatio: "2/1",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    loading: auth.loading,
  }),
  { resendVerifyAccount }
)(VerifyEmailPage);
