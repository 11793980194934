import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { NewsletterColumns, newsletterFilters } from "./constants";
import { getToArrayOrToEmptyArray, toApiUrl } from "../../utils/generic";
import { Add, ArrowRight, Delete, Edit } from "@mui/icons-material";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import Input from "../forms/Input";
import { IconButton } from "@mui/material";
import useProducts from "../../store/hooks/useProducts";
import CustomButton from "../ui/Button";
import Message from "../ui/Message";
import { useNavigate } from "react-router-dom";
import SelectInput from "../forms/SelectInput";

export default function Newsletter() {
  const [entity, setEntity] = useState(null);
  const navigate = useNavigate();
  const { websites } = useProducts();
  const formConfig = useForm({
    email: {
      defaultValue: "",
      validators: ValidationService.validateEmail,
      formatter: (val) =>
        ValidationService.isString(val) ? val.trim().toLowerCase() : "",
    },
    website_id: {
      defaultValue: "",
      validators: (val) => !ValidationService.isNullOrUndefinedOrEmpty(val),
    },
  });

  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm(obj);
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };
  const [dataSlice, getData, setLoading] = useDataSlice("newsletter");

  const onEdit = (row) => {
    openDialogForm(row);
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/newsletter/${row.id}`));
      addNotification("Recipient Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification("Recipient was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const onSubmit = async () => {
    const form = formConfig.getFormValues();
    const handler = entity?.id ? axios.put : axios.post;
    const url = entity?.id ? `/newsletter/${entity.id}` : "/newsletter";
    try {
      setLoading(true);
      await handler(toApiUrl(url), form);
      await getData(dataSlice?.page, dataSlice?.view);
      addNotification("Action completed", "success");
      closeDialog();
    } catch (error) {
      addNotification("Action failed", "error");
    } finally {
      setLoading(false);
    }
  };

  const websiteOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(websites).map((website) => ({
      value: website.id,
      label: website.domain,
    }));
  }, [websites]);

  const newsletterFiltersMemoized = useMemo(() => {
    return newsletterFilters.concat({
      name: "website_id",
      label: "Website",
      grid: { md: 12, xs: 12 },
      value: undefined,
      type: "select",
      options: [{ label: "All", value: "All" }].concat(
        (websites || []).map((x) => ({
          label: x.domain,
          value: x.id,
        }))
      ),
      formatter: (value) => (value === "All" ? undefined : value),
    });
  }, [websites]);

  return (
    <>
      <TableDataLoader
        withFilters
        filters={{ order_by: "created_at:desc" }}
        defaultSortingColumn={"created_at"}
        defaultOrderBy="desc"
        filtersConfig={newsletterFiltersMemoized}
        endpoint={toApiUrl("/newsletter")}
        entityName="results"
        identifier="newsletter"
        columns={NewsletterColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: onEdit,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <IconButton onClick={() => openDialogForm({ email: "" })}>
              <Add />
            </IconButton>
          );
        }}
      />
      <SimpleDialog
        maxWidth="lg"
        onCancel={closeDialog}
        onConfirm={async () => {
          const res = await onSubmit();
          if (res) {
            getData(dataSlice?.page, dataSlice?.view);
            closeDialog();
          }
        }}
        title={
          <Title>
            <Edit /> {entity?.id ? "Edit" : "Add New"} Recipient
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        {!websiteOptions.length ? (
          <>
            <Message message="No websites were created yet. You have to register at least one website in order to start creating product super categories" />
            <CustomButton
              fullWidth
              endIcon={<ArrowRight />}
              onClick={() => navigate("/websites")}
            >
              Go To Websites
            </CustomButton>
          </>
        ) : (
          <>
            <Input
              {...formConfig.form.email}
              name="email"
              onChange={formConfig.handleChange}
              label="Email"
            />
            <SelectInput
              {...formConfig.form.website_id}
              multiple
              options={websiteOptions}
              value={formConfig.form.website_id.value}
              label="Website"
              onChange={formConfig.handleChange}
            />
          </>
        )}
      </SimpleDialog>
    </>
  );
}
