import React, { useCallback, useMemo, useState } from "react";
import ValidationService from "../../services/ValidationService";
import { Badge, Grid, IconButton, Tooltip } from "@mui/material";
import SelectInput from "../forms/SelectInput";
import CustomSwitch from "../ui/Switch";
import Input from "../forms/Input";
import { clearSearchFilters, setSearchFilters } from "../../store/actions/data";
import { useDispatch } from "react-redux";
import useDataSlice from "./useDataSlice";
import CustomButton from "../ui/Button";
import SimpleDialog from "../dialogs/SimpleDialog";
import { FilterList } from "@mui/icons-material";
import { debounce } from "lodash";

export default function Filters({
  config,
  onChange,
  onApply,
  onClear,
  identifier,
  onSelectItems,
}) {
  const [dataSlice, getData] = useDataSlice(identifier);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { searchFilters } = dataSlice || {};

  const renderInput = useCallback(
    (input) => {
      switch (input.type) {
        case "select":
          return (
            <SelectInput
              sx={{ width: "100%" }}
              fullWidth
              disabled={input?.isDisabled?.(searchFilters)}
              onChange={(e) => {
                const newState = {
                  ...searchFilters,
                  [input.name]: input.formatter
                    ? input.formatter(e.target.value)
                    : e.target.value,
                  ...(input.onChange &&
                    input.onChange(e.target.value, input, searchFilters)),
                };
                if (onChange) {
                  onChange(newState, input);
                }
                dispatch(setSearchFilters({ identifier, filters: newState }));
                return newState;
              }}
              label={input.label || input.name}
              name={input.name}
              options={input.options || []}
              value={searchFilters?.[input.name]}
              multiple={!!input.multiple}
              isValid
              isTouched
            />
          );
        case "checkbox":
          return (
            <CustomSwitch
              name={input.name}
              label={input.label || input.name}
              disabled={input?.isDisabled?.(searchFilters)}
              checked={searchFilters?.[input.name]}
              onChange={(_, val) => {
                const newState = {
                  ...searchFilters,
                  [input.name]: input.formatter ? input.formatter(val) : val,
                  ...(input.onChange &&
                    input.onChange(val, input, searchFilters)),
                };
                if (onChange) {
                  onChange(newState, input);
                }
                dispatch(setSearchFilters({ identifier, filters: newState }));
                return newState;
              }}
            />
          );
        default:
          return (
            <Input
              onChange={debounce((e) => {
                e.persist();
                const newState = {
                  ...searchFilters,
                  [input.name]: input.formatter
                    ? input.formatter(e.target.value)
                    : e.target.value,
                  ...(input.onChange &&
                    input.onChange(e.target.value, input, searchFilters)),
                };
                if (onChange) {
                  onChange(newState, input);
                }
                dispatch(setSearchFilters({ identifier, filters: newState }));
                return newState;
              }, 200)}
              name={input.name}
              disabled={input?.isDisabled?.(searchFilters)}
              type={input.type}
              defaultValue={searchFilters?.[input.name]}
              label={input.label || input.name}
              isTouched
              isValid
            />
          );
      }
    },
    [searchFilters, onChange, dispatch, identifier]
  );

  const appliedFilters = useMemo(() => {
    return ValidationService.isObject(searchFilters) &&
      Array.isArray(config) &&
      config?.length
      ? Object.entries(searchFilters).reduce((acc, [key, value]) => {
          const element = config.find((input) => input.name === key);
          if (!element) {
            return acc;
          }

          switch (element.type) {
            case "select":
              if (element.multiple) {
                if (Array.isArray(value) && value.length ? 1 : 0) {
                  acc.push(element.label || key);
                }
              } else if (Boolean(value) || typeof value === "boolean") {
                acc.push(element.label || key);
              }
              break;
            case "checkbox":
              if (Boolean(value)) {
                acc.push(element.label || key);
              }
              break;
            case "text":
              if (Boolean(value)) {
                acc.push(element.label || key);
              }
              break;
            case "number":
              if (!ValidationService.isNullOrUndefinedOrEmpty(value)) {
                acc.push(element.label || key);
              }
              break;

            default:
              if (Boolean(value)) {
                acc.push(element.label || key);
              }
          }

          return acc;
        }, [])
      : [];
  }, [searchFilters, config]);
  if (!ValidationService.isNotEmptyArray(config)) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={`Filters  ${
          appliedFilters?.length ? "(" : ""
        }${appliedFilters?.join(",")}${appliedFilters?.length ? ")" : ""}`}
      >
        <IconButton onClick={() => setOpen(true)} variant="outlined">
          <Badge badgeContent={appliedFilters?.length || 0} color="primary">
            <FilterList />
          </Badge>
        </IconButton>
      </Tooltip>

      <SimpleDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={async () => {
          await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
          setOpen(false);
          try {
            if (onSelectItems) {
              onSelectItems(null);
            }
          } catch (error) {}
        }}
      >
        <Grid container spacing={2}>
          {config.map((input) => (
            <Grid item md={12} xs={12} {...input.grid} key={input.name}>
              {renderInput(input)}
            </Grid>
          ))}

          <Grid item md={12} xs={12}>
            <CustomButton
              variant="outlined"
              onClick={async () => {
                dispatch(clearSearchFilters({ identifier }));
                await getData(
                  dataSlice?.page,
                  dataSlice?.view,
                  dataSlice?.filters
                );
                if (onClear) {
                  onClear();
                }
                try {
                  if (onSelectItems) {
                    onSelectItems(null);
                  }
                } catch (error) {}
                setOpen(false);
              }}
            >
              Clear
            </CustomButton>
          </Grid>
        </Grid>
      </SimpleDialog>
    </>
  );
}
