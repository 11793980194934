import ValidationService from "../../services/ValidationService";
import useForm from "../../store/hooks/useForm";

export default function useProfileForm(user) {
  return useForm({
    id: {
      defaultValue: user?.id,
      // validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
    },
    profile_image: {
      defaultValue: user?.profile_image,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({
          min: 2,
          max: 500,
          noWhiteSpace: true,
          value,
        }),
    },
    email: {
      defaultValue: user?.email,
      validators: (value) => ValidationService.validateEmail(value),
    },
    name: {
      defaultValue: user?.name,
      validators: (value) =>
        ValidationService.validateString({
          min: 2,
          max: 50,
          noWhiteSpace: true,
          value,
        }),
    },
    last_name: {
      defaultValue: user?.last_name,
      validators: (value) =>
        ValidationService.validateString({
          min: 2,
          max: 50,
          noWhiteSpace: true,
          value,
        }),
    },
    country_id: {
      defaultValue: user?.country_id || "",
    },
    city: {
      defaultValue: user?.city || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    address: {
      defaultValue: user?.address || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    postal_code: {
      defaultValue: user?.postal_code || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    business_name: {
      defaultValue: user?.business_name || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    business_address: {
      defaultValue: user?.business_address || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    vat: {
      defaultValue: user?.vat || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    phone: {
      defaultValue: user?.phone || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    business_country_id: {
      defaultValue: user?.business_country_id || "",
    },
    blacklist: {
      defaultValue: user?.blacklist || false,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.isBoolean(value),
    },
    verified: {
      defaultValue: !!user?.verified,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.isBoolean(value),
    },
    role_id: {
      defaultValue: user?.role_id,
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
    },
    password: {
      defaultValue: "",
      validators: !!user?.id
        ? (value) => true
        : (value) =>
            ValidationService.validateString({
              min: 8,
              max: 16,
              noWhiteSpace: true,
              value,
            }),
    },
    parent_id: {
      defaultValue: user?.parent_id,
    },
    website_id: {
      defaultValue: user?.website_id,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 50 }),
    },
  });
}
