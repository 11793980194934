import React from "react";
import useOrders from "../../store/hooks/useOrders";
import { Grid } from "@mui/material";
import BasicOrderDetails from "./BasicOrderDetails";
import BillingDetails from "./BillingDetails";
import ShippingDetails from "./ShippingDetails";
import OrderCommentsForm from "./OrderCommentsForm";
import { updateOrder } from "../../store/actions/orders";
import { connect } from "react-redux";

function OrderDetails({ updateOrder }) {
  const { order, loading } = useOrders();
  if (!order) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      {/* {"LEFT"} */}
      <Grid item md={6} lg={4} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            {!loading ? <BasicOrderDetails order={order} withEdit /> : null}
          </Grid>
        </Grid>
      </Grid>
      {/* {"MIDDLE"} */}
      <Grid item md={6} lg={4} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <BillingDetails order={order} withEdit />
          </Grid>
        </Grid>
      </Grid>
      {/* {"RIGHT"} */}
      <Grid item md={6} lg={4} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <ShippingDetails order={order} withEdit />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}>
        <OrderCommentsForm
          key={order?.id}
          order={order}
          onSubmit={async (form) => {
            await updateOrder(order.id, form);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default connect(null, { updateOrder })(OrderDetails);
