import React, { useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { OrderStatusColumns } from "./constants";
import { toApiUrl } from "../../utils/generic";
import { Add, Delete, Edit } from "@mui/icons-material";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import { IconButton } from "@mui/material";

export default function OrderStatuses() {
  const [entity, setEntity] = useState(null);
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
  });
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({ id: obj?.id || "", name: obj?.name || "" });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("order_statuses");

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { name, id } = formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/order-status"), {
          name,
        });
      } else {
        res = await axios.put(toApiUrl(`/order-status/${id}`), {
          name,
        });
      }
      addNotification(`Order Status ${id ? "Updated" : "Created"}`, "success");

      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification(
        `Order Status was not ${id ? "Updated" : "Created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/order-status/${row.id}`));
      addNotification("Order Status Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification("Order Status was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const { form, handleChange } = formConfig;
  return (
    <>
      <TableDataLoader
        endpoint={toApiUrl("/order-status")}
        entityName="results"
        identifier="order_statuses"
        columns={OrderStatusColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <IconButton onClick={() => openDialogForm({ name: "" })}>
              <Add />
            </IconButton>
          );
        }}
      />
      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Order Status
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        <Input
          {...form.name}
          name="name"
          label="Order Status"
          disabled
          value={form.name.value}
          onChange={handleChange}
        />
      </SimpleDialog>
    </>
  );
}
