import React from "react";
import useAggregator from "../../store/hooks/useAggregator";
import { CircularProgress, Grid } from "@mui/material";
import { PeopleAlt, ShoppingBag, ShoppingCart } from "@mui/icons-material";
import AnalyticsCard from "../ui/AnalyticsCard";
import useAuth from "../../store/hooks/useAuth";
import { ROLES } from "../../data/roles";

const mapKeyToConfig = {
  platformUsers: {
    title: "Users",
    icon: <PeopleAlt />,
    canAcces: (role) => [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
  },
  // accountUsers: {
  //   title: "Account Users",
  //   icon: <PeopleAlt />,
  //   canAcces: (role) => true,
  // },
  platformOrders: {
    title: "Orders",
    icon: <ShoppingCart />,
    canAcces: (role) => [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
  },
  // accountOrders: {
  //   title: "Account Orders",
  //   icon: <ShoppingCart />,
  //   canAcces: (role) => true,
  // },
  platformProducts: {
    title: "Products",
    icon: <ShoppingBag />,
    canAcces: (role) => [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
  },
  // accountProducts: {
  //   title: "Account Products",
  //   icon: <ShoppingBag />,
  //   canAcces: (role) => true,
  // },
};

export default function DashboardCards() {
  const { loading, dashboard } = useAggregator();
  const { user } = useAuth();
  return (
    <Grid container spacing={2}>
      {Object.entries(dashboard || {}).map(([key, value]) =>
        !!mapKeyToConfig[key] &&
        mapKeyToConfig[key].canAcces(user?.role?.name) ? (
          <Grid key={key} item lg={4} md={4} sm={12} xs={12}>
            <AnalyticsCard
              {...mapKeyToConfig[key]}
              count={loading ? <CircularProgress size={20} /> : value}
            />
          </Grid>
        ) : null
      )}
    </Grid>
  );
}
