import React, { useMemo, useState } from "react";
import useProducts from "../../store/hooks/useProducts";
import Message from "../ui/Message";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import SelectInput from "../forms/SelectInput";
import {
  CopyAll,
  Delete,
  Edit,
  EuroOutlined,
  Inventory2Outlined,
  Save,
} from "@mui/icons-material";
import {
  numericFormFormater,
  toApiUrl,
  toServerImage,
  uploadFile,
} from "../../utils/generic";
import FileImport from "../forms/FileImport";
import Title from "../ui/Title";
import useProductForm from "./useProductForm";
import ProductForm from "./ProductForm";
import SimpleDialog from "../dialogs/SimpleDialog";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import Input from "../forms/Input";

function generateRandomString(length) {
  const characters = "0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function AutogenerateVariantsForm({
  product,
  onClose,
  onSubmit,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { product_attribute_categories } = useProducts();
  const { formConfig } = useProductForm();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [variations, setVariations] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const currentProduct = useMemo(() => {
    return product?.parent_product ? product?.parent_product?.product : product;
  }, [product]);

  const options = useMemo(() => {
    if (!Array.isArray(product_attribute_categories)) {
      return [];
    }
    return product_attribute_categories.flatMap((a) =>
      (a?.attributes || []).map((attr) => ({
        label: `${a.name}: ${attr.name}`,
        value: attr.id,
      }))
    );
  }, [product_attribute_categories]);

  if (!currentProduct) {
    return <Message message="Configuration seems to be invalid" />;
  }

  if (
    !Array.isArray(product_attribute_categories) ||
    !product_attribute_categories.length ||
    !product_attribute_categories.some((c) => c?.attributes?.length)
  ) {
    return (
      <Message message="Please create some attributes in order to proceed!" />
    );
  }

  const produceVariations = (ids) => {
    const variationsByCategory = ids.reduce((acc, id) => {
      const attrCat = product_attribute_categories.find((a) =>
        a.attributes.some((x) => x.id === id)
      );
      const idx = acc.findIndex((x) => x.id === attrCat.id);
      if (idx < 0) {
        acc.push({ ...attrCat, variations: [id] });
      } else {
        acc[idx].variations.push(id);
      }
      return acc;
    }, []);

    function makeCombinations(categories) {
      if (!categories || !categories.length) {
        return [];
      }
      const results = [];
      const [initial, ...others] = categories;

      for (const variation of initial.variations) {
        const otherResults = makeCombinations(others || []);
        if (!otherResults.length) {
          results.push([variation]);
        } else {
          for (const res of otherResults) {
            results.push([variation, res].flatMap((x) => x));
          }
        }
      }

      return results;
    }

    setVariations(
      makeCombinations(variationsByCategory)
        .map((items) =>
          items.map((item) => {
            const category = product_attribute_categories.find((x) =>
              x.attributes.some((p) => p.id === item)
            );
            const attribute = category.attributes.find((x) => x.id === item);
            return attribute;
          })
        )
        .map((entity) => ({
          productForm: {
            sku: generateRandomString(12),
            price: product.price,
            discount: product.discount,
            quantity: product.quantity,
            attributes: entity.map((x) => x.id),
          },
          productImage: currentProduct.image,
          combinations: entity,
        }))
    );
  };

  const variationExists = (items, currentIndex) => {
    return (
      (currentProduct?.variants || [])
        .concat({ variant: currentProduct })
        .some((x) =>
          x.variant.product_attributes.every((attr) =>
            items.some((item) => item.id === attr.attribute_id)
          )
        ) ||
      variations.some(
        (variation, index) =>
          index !== currentIndex &&
          items.every((item) =>
            variation.combinations.some((x) => {
              return x.id === item.id;
            })
          )
      )
    );
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      const {
        id,
        slug,
        sku,
        variants,
        parent_product,
        product_attributes,
        views,
        created_at,
        updated_at,
        ...rest
      } = currentProduct;
      const products = variations.map((variation) => ({
        ...rest,
        categories: (rest?.categories || []).map((c) => c.category_id),
        ...variation.productForm,
        attributes: variation.combinations.map((c) => c.id),
      }));

      const res = await axios.post(
        toApiUrl(
          `/products/${currentProduct.id}/variations/product-attributes`
        ),
        { products }
      );
      if (onSubmit) {
        onSubmit(res.data);
      }
    } catch (error) {
      addNotification(
        error?.response?.data?.message || "Operation Failed",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid spacing={2} container>
      <SimpleDialog
        open={openCategoryDialog}
        onCancel={() => {
          setOpenCategoryDialog(false);
          setSelectedCategories([]);
        }}
        onConfirm={() => {
          const options = product_attribute_categories
            .filter((x) => selectedCategories.some((p) => p === x.id))
            .flatMap((x) => x.attributes.map((attr) => attr.id));
          setSelectedOptions(options);
          produceVariations(options);
          setSelectedCategories([]);
          setOpenCategoryDialog(false);
        }}
      >
        <SelectInput
          label="Select attribute categories"
          autoComplete
          multiple
          options={(product_attribute_categories || [])?.map((x) => ({
            label: x.name,
            value: x.id,
          }))}
          isValid
          isTouched
          value={selectedCategories}
          onChange={(e) => {
            setSelectedCategories(e.target.value);
          }}
        />
      </SimpleDialog>
      {editIndex === null ? (
        <>
          <Grid item md={11} xs={12}>
            <SelectInput
              label="Select attributes"
              autoComplete
              multiple
              options={options}
              isValid
              isTouched
              value={selectedOptions}
              onChange={(e) => {
                setSelectedOptions(e.target.value);
                produceVariations(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={1}>
            <Tooltip title="Add All by attribute categories">
              <IconButton
                onClick={() => {
                  setOpenCategoryDialog(true);
                }}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear All">
              <IconButton
                onClick={() => {
                  setSelectedOptions([]);
                  produceVariations([]);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item md={12} xs={12}>
            <Title sx={{ justifyContent: "space-between" }}>
              <Title>
                <CopyAll /> {variations?.length || 0} Variations
              </Title>
              <Title>
                <Tooltip title="Generate">
                  <IconButton
                    onClick={onSave}
                    disabled={
                      !variations?.length ||
                      variations.some((x, idx) =>
                        variationExists(x.combinations || [], idx)
                      )
                    }
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete already existing variations due to attributes">
                  <IconButton
                    onClick={() =>
                      setVariations((prev) => {
                        const newResults = prev.filter(
                          (x, idx) => !variationExists(x.combinations, idx)
                        );
                        if (!newResults.length) {
                          setSelectedOptions([]);
                        } else {
                          setSelectedOptions((prev) =>
                            prev.filter((x) =>
                              newResults.some((p) =>
                                p.combinations.some((y) => y.id === x)
                              )
                            )
                          );
                        }
                        return newResults;
                      })
                    }
                    disabled={
                      !variations?.length ||
                      !variations.some((x, idx) =>
                        variationExists(x.combinations || [], idx)
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Title>
            </Title>
          </Grid>
        </>
      ) : null}

      <Grid item md={12} xs={12} sx={{ border: "1px dashed #eee" }}>
        {variations.map(({ combinations, ...rest }, idx) =>
          editIndex === null || editIndex === idx ? (
            <Grid item md={12} xs={12} key={idx}>
              <Grid container spacing={1} sx={{ alignItems: "center" }}>
                <Grid item md={1} xs={12}>
                  <>
                    <b>{idx + 1}.</b>
                    <FileImport
                      disabled={idx === editIndex}
                      sx={{ mr: 2 }}
                      accept="image/*"
                      icon={<Avatar src={toServerImage(rest.productImage)} />}
                      onFileUpload={async (file) => {
                        try {
                          const { filename } = await uploadFile(file, {
                            type: "frontend",
                          });
                          setVariations((prev) =>
                            prev.map((x, i) =>
                              i !== idx ? x : { ...x, productImage: filename }
                            )
                          );
                        } catch (error) {}
                      }}
                    />
                  </>
                </Grid>
                {editIndex === null ? (
                  <Grid item md={4} xs={12}>
                    <SelectInput
                      size="small"
                      name="attributes"
                      label="Attributes"
                      limitTags={2}
                      autoComplete
                      multiple
                      options={options || []}
                      isValid
                      isTouched
                      value={rest?.productForm?.attributes || []}
                      onChange={(e) => {
                        const newItems = [];

                        setVariations((prev) => {
                          const flatOptions =
                            product_attribute_categories.flatMap(
                              (x) => x.attributes || []
                            );

                          const newResults = prev
                            .map((x, i) => {
                              if (i !== idx) {
                                return x;
                              }
                              const newEntry = {
                                ...x,
                                productForm: {
                                  ...x?.productForm,
                                  attributes: e.target.value,
                                },
                                combinations: e.target.value
                                  .map((attribute_id) =>
                                    flatOptions.find(
                                      (opt) => opt.id === attribute_id
                                    )
                                  )
                                  .filter(Boolean),
                              };

                              newItems.push(
                                ...newEntry.combinations
                                  .filter(
                                    (comb) =>
                                      !selectedOptions.some(
                                        (x) => x === comb.id
                                      )
                                  )
                                  .map((x) => x.id)
                              );
                              return newEntry;
                            })
                            .filter((x) => x.combinations.length);
                          if (!newResults.length) {
                            setSelectedOptions([]);
                          } else {
                            setSelectedOptions((prev) =>
                              prev
                                .concat(newItems)
                                .filter((x) =>
                                  newResults.some((p) =>
                                    p.combinations.some((y) => y.id === x)
                                  )
                                )
                            );
                          }
                          return newResults;
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid item md={3} xs={12}>
                  <>
                    {editIndex === null ? (
                      <>
                        <Input
                          size="small"
                          focused
                          type="number"
                          sx={{ mx: 2 }}
                          min={0}
                          fullWitdth={false}
                          label="Price"
                          name="price"
                          value={rest?.productForm?.price}
                          startIcon={<EuroOutlined />}
                          isValid={rest?.productForm?.price >= 0.01}
                          isTouched
                          onChange={(e) => {
                            setVariations((prev) =>
                              prev.map((variation, i) => {
                                if (i !== idx) {
                                  return variation;
                                }

                                formConfig.resetForm({});
                                setEditIndex(null);
                                return {
                                  ...variation,

                                  productForm: {
                                    ...rest?.productForm,
                                    [e.target.name]: numericFormFormater(
                                      "float"
                                    )(e.target.value),
                                  },
                                };
                              })
                            );
                          }}
                        />
                      </>
                    ) : null}
                  </>
                </Grid>
                <Grid item md={3} xs={12}>
                  <>
                    {editIndex === null ? (
                      <>
                        <Input
                          size="small"
                          name="quantity"
                          focused
                          type="number"
                          sx={{ mx: 2 }}
                          min={0}
                          fullWitdth={false}
                          label="Quantity"
                          value={rest?.productForm?.quantity}
                          startIcon={<Inventory2Outlined />}
                          isValid={rest?.productForm?.quantity >= 0}
                          isTouched
                          onChange={(e) => {
                            setVariations((prev) =>
                              prev.map((variation, i) => {
                                if (i !== idx) {
                                  return variation;
                                }

                                formConfig.resetForm({});
                                setEditIndex(null);
                                return {
                                  ...variation,

                                  productForm: {
                                    ...rest?.productForm,
                                    [e.target.name]: numericFormFormater("int")(
                                      e.target.value
                                    ),
                                  },
                                };
                              })
                            );
                          }}
                        />
                      </>
                    ) : null}
                  </>
                </Grid>
                <Grid item md={1} xs={12}>
                  {editIndex === null ? (
                    <IconButton
                      disabled={idx === editIndex}
                      onClick={() => {
                        setEditIndex(idx);
                        formConfig.resetForm({
                          ...currentProduct,
                          categories: (currentProduct?.categories || []).map(
                            (c) => c.category_id
                          ),
                          ...rest?.productForm,
                          image: rest?.productImage,
                          id: null,
                          sku:
                            rest?.productForm?.sku || generateRandomString(12),
                          attributes: Array.isArray(
                            rest?.productForm?.attributes
                          )
                            ? rest?.productForm?.attributes
                            : combinations.map((item) => item.id),
                        });
                      }}
                    >
                      <Edit />
                    </IconButton>
                  ) : null}
                </Grid>

                {variationExists(combinations, idx) ? (
                  <Grid item md={12} xs={12} sx={{ mb: 2 }}>
                    {editIndex === null ? (
                      <Message
                        severity="error"
                        message="This variation already exists"
                        onClose={() => {
                          setVariations((prev) => {
                            const newVariations = prev.filter(
                              (x, i) => i !== idx
                            );
                            if (!newVariations.length) {
                              setSelectedOptions([]);
                            }
                            return newVariations;
                          });
                        }}
                      />
                    ) : null}
                  </Grid>
                ) : (
                  <Grid item md={12} xs={12} sx={{ mb: 2 }}>
                    {editIndex === null ? (
                      <Message
                        severity="info"
                        message=" This variation seems to be new"
                        onClose={() => {
                          setVariations((prev) =>
                            prev.filter((x, i) => i !== idx)
                          );
                        }}
                      />
                    ) : null}
                  </Grid>
                )}
                <Grid item md={12} xs={12}>
                  {editIndex === idx ? (
                    <>
                      <Button
                        sx={{ my: 2, mr: 2 }}
                        variant="contained"
                        onClick={() => {
                          setVariations((prev) =>
                            prev.map((variation, i) => {
                              if (i !== idx) {
                                return variation;
                              }

                              const newProduct = formConfig.getFormValues();
                              formConfig.resetForm({});
                              setEditIndex(null);

                              return {
                                ...variation,
                                productImage: newProduct.image,
                                productForm: newProduct,
                              };
                            })
                          );
                        }}
                        disabled={formConfig && !formConfig.isValid}
                      >
                        Save
                      </Button>
                      <Button
                        sx={{ my: 2 }}
                        onClick={() => {
                          setEditIndex(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <ProductForm
                        {...formConfig}
                        setLoading={setIsLoading}
                        isLoading={isLoading}
                        disableAttributes
                      />
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider sx={{ my: 4 }} />
              </Grid>
            </Grid>
          ) : null
        )}
      </Grid>
    </Grid>
  );
}
