import React, { useEffect, useMemo, useState } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  TimeSeriesScale,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid, Paper } from "@mui/material";
import Message from "../components/ui/Message";
import BackdropLoader from "../components/ui/BackdropLoader";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import { Computer, Memory, Monitor, Terminal } from "@mui/icons-material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import ListView from "../components/ui/ListView";
import Title from "../components/ui/Title";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monitoring",
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend,
  TimeSeriesScale
);

function MonitoringPage({ socket, socketConnected }) {
  const [data, setData] = useState();
  const cpuOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Cpu Usage(%)",
      },
    },
  };

  const memoryOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Memory Usage(MB)",
      },
    },
  };

  const labels = useMemo(() => {
    if (!Array.isArray(data?.performance)) {
      return [];
    }
    return data?.performance?.map(
      ({ timestamp }) => `${new Date(timestamp).toUTCString()}`
    );
  }, [data]);

  const memoryData = useMemo(() => {
    if (!data || !Array.isArray(data?.performance)) {
      return {
        labels,
        datasets: [
          {
            label: "Memory Usage(MB)",
            data: [],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      };
    }
    return {
      labels,
      datasets: [
        {
          label: "Memory Usage(MB)",
          data:
            data?.performance?.map(({ memoryUsageMB }) => memoryUsageMB) || [],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  }, [data, labels]);

  const cpuData = useMemo(() => {
    if (!data || !Array.isArray(data?.performance)) {
      return {
        labels,
        datasets: [
          {
            label: "CPU Usage(%)",
            data: [],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      };
    }
    return {
      labels,
      datasets: [
        {
          label: "CPU Usage(%)",
          data:
            data?.performance?.map(
              ({ cpuUsagePercentage }) => cpuUsagePercentage
            ) || [],
          borderColor: "#259",
          backgroundColor: "#259",
        },
      ],
    };
  }, [data, labels]);

  const cpuItems = useMemo(() => {
    if (!data?.cpus?.length) {
      return [];
    }

    return (data.cpus || []).map((cpu) => ({
      primary: cpu.model,
      secondary: `${cpu.speed || "-"}GHz`,
      icon: <Memory />,
    }));
  }, [data]);
  const cpuSpecItems = useMemo(() => {
    if (!data?.cpus?.length) {
      return [];
    }

    return (data.cpus || []).map((cpu) => ({
      primary: Object.entries(cpu.times || {})
        .map(([key, value]) => `${key}: ${value}`)
        .join(","),
      secondary: cpu.model,
      icon: <Memory />,
    }));
  }, [data]);
  useEffect(() => {
    if (!socketConnected) {
      return;
    }

    socket.emit("performance");

    socket.on("performance", (data) => {
      if (data) {
        try {
          setData((prev) => JSON.parse(data) || []);
        } catch (error) {}
      }
    });
  }, [socket, socketConnected]);

  if (!socketConnected || !data) {
    return (
      <>
        <BackdropLoader open={!socketConnected} />
        <Message message="Connecting..." />
      </>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title="Application Monitoring" icon={<Monitor />} />
      </Grid>

      <Grid item md={4} xs={12}>
        <AnalyticsCard
          icon={<Memory />}
          count={data?.pid || "-"}
          title="Process ID"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AnalyticsCard
          icon={<Computer />}
          count={data?.platform || "-"}
          title="OS"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AnalyticsCard
          icon={<Memory />}
          count={data?.cpus?.length || "-"}
          title="CPUS"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AnalyticsCard
          icon={<Terminal />}
          count={data?.nodeVersion || "-"}
          title="Runtime"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AnalyticsCard
          icon={<Memory />}
          count={!isNaN(data?.freeMemory) ? data?.freeMemory?.toFixed(2) : "-"}
          title="Free Memory (GB)"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AnalyticsCard
          icon={<Memory />}
          count={
            !isNaN(data?.totalMemory) ? data?.totalMemory?.toFixed(2) : "-"
          }
          title="Total Memory (GB)"
        />
      </Grid>
      <Grid item lg={6} md={12} xs={12}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Line
            options={{
              ...memoryOptions,
              scales: {
                x: {
                  type: "category",
                },
              },
            }}
            data={memoryData}
          />
        </Paper>
      </Grid>
      <Grid item lg={6} md={12} xs={12}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Line options={cpuOptions} data={cpuData} />
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <ListView
          items={cpuItems}
          title={
            <Title>
              <Memory sx={{ mr: 2 }} /> CPUs ({data?.cpus?.length || 0})
            </Title>
          }
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ListView
          items={cpuSpecItems}
          title={
            <Title>
              <Memory sx={{ mr: 2 }} /> CPU Times
            </Title>
          }
        />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(MonitoringPage, [ROLES.SUPER_ADMIN]));
