import { Download } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  IconButton,
  Tooltip,
} from "@mui/material";
import React from "react";

export default function TableCard({ model, onDownload }) {
  return (
    <Card>
      <CardHeader
        title={model.table}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          typography: { fontSize: "12px" },
        }}
      />
      <CardMedia
        component="img"
        src={"/db.jpg"}
        alt={model.table}
        height="100px"
        width="100px"
        sx={{ objectFit: "contain" }}
      />

      <CardActions>
        <Tooltip title="Download">
          <IconButton onClick={() => onDownload(model)}>
            <Download />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
