import { SupervisedUserCircle } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { ROLES } from "../data/roles";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import UsersView from "../components/administration/UsersView";
import useAuth from "../store/hooks/useAuth";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import GoBackButton from "../components/ui/GoBackButton";
import { removeDataSlice } from "../store/actions/data";
import { useDispatch } from "react-redux";
import { getWebsites } from "../store/actions/products";

function AdministrationPage({
  url = "/users",
  isAccountBased = false,
  title,
  identifier = "users",
}) {
  const { user } = useAuth();
  const filters = isAccountBased
    ? { parent_id: user?.parent_id || user.id }
    : {};
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebsites());
    return () => {
      dispatch(removeDataSlice({ identifier }));
    };
  }, [dispatch, identifier]);
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary icon={<SupervisedUserCircle />} title={title} />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <UsersView
          key={identifier}
          url={url}
          filters={filters}
          identifier={identifier}
        />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(
    AdministrationPage,
    [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    ({ role, location }) => {
      if (location?.pathname === "/users") {
        return role?.name === ROLES.SUPER_ADMIN;
      }

      return [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(role?.name) !== -1;
    }
  )
);
