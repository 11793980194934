import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { login } from "../store/actions/auth";
import { useNavigate } from "react-router-dom";
import useTheme from "../store/hooks/useTheme";
import LoginForm from "../components/auth/LoginForm";
import BackdropLoader from "../components/ui/BackdropLoader";
import { Box, Container } from "@mui/material";

function LoginPage({ login, loading }) {
  const navigate = useNavigate();

  const handleSubmit = async (event, form) => {
    event.preventDefault();

    const res = await login(form);
    if (res) {
      navigate("/");
    }
  };

  return (
    <Container maxWidth="sm">
      <BackdropLoader open={loading} />
      <CssBaseline />
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {" "}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <LoginForm onSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    loading: auth.loading,
  }),
  { login }
)(LoginPage);
