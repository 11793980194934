import React, { useState } from "react";
import Container from "../grid/Container";
import Row from "../grid/Row";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import {
  Block,
  CardMembership,
  Check,
  Edit,
  Info,
  Mail,
  Person,
  Phone,
  PinDrop,
} from "@mui/icons-material";
import SimpleDialog from "../dialogs/SimpleDialog";
import OrderBillingShippingForm from "./OrderBillingShippingForm";
import useOrderBillingShippingForm from "./useOrderBillingShippingForm";
import { IconButton } from "@mui/material";

export default function BillingDetails({ order, withEdit }) {
  const [open, setOpen] = useState(false);
  const [formConfig, onSubmit] = useOrderBillingShippingForm({ order });
  return (
    <Container>
      <Row>
        <ListView
          title={
            <Title divider sx={{ justifyContent: "space-between" }}>
              <Title>
                <Info sx={{ mr: 2 }} /> Billing Details
              </Title>
              {withEdit ? (
                <IconButton onClick={() => setOpen(true)}>
                  <Edit />
                </IconButton>
              ) : null}
            </Title>
          }
          items={[
            {
              primary: `${order.name} ${order.last_name}`,
              secondary: "Full Name",
              icon: <Person />,
            },
            {
              primary: `${order.email}`,
              secondary: "Email",
              icon: <Mail />,
            },
            {
              primary: `${order.address || "-"}, ${order.city || "-"}, ${
                order.postal_code || "-"
              }, ${order?.country?.name || ""}`,
              secondary: "Billing Address",
              icon: <PinDrop />,
            },
            {
              primary: order?.phone || "-",
              secondary: "Phone",
              icon: <Phone />,
            },
            {
              primary: !!order.user_id ? (
                <Check color="success" />
              ) : (
                <Block color="error" />
              ),
              secondary: "Member (user)",
              icon: <CardMembership />,
            },
          ]}
        />
      </Row>
      <SimpleDialog
        open={open}
        disableConfirm={!formConfig.isValid || !formConfig.isTouched}
        onCancel={() => setOpen(false)}
        onConfirm={async () => {
          const res = await onSubmit();
          if (res) {
            setOpen(false);
          }
        }}
        title={
          <Title>
            <Edit sx={{ mr: 2 }} /> Billing Details
          </Title>
        }
      >
        <OrderBillingShippingForm {...formConfig} />
      </SimpleDialog>
    </Container>
  );
}
