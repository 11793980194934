import React from "react";
import ForbiddenPage from "../pages/ForbiddenPage";
import useAuth from "../store/hooks/useAuth";
import { useLocation } from "react-router-dom";

export default function withRoles(Component, roles = [], expression) {
  return (props) => {
    const { user } = useAuth();
    const { role } = user || {};
    const location = useLocation();

    if (expression) {
      if (!expression({ ...props, user, role, location })) {
        return <ForbiddenPage />;
      }
    } else if (!role || !(roles || []).some((r) => r === role?.name)) {
      return <ForbiddenPage />;
    }

    return <Component {...props} />;
  };
}
