import ValidationService from "../../services/ValidationService";

export const parserFunctions = {
  prefixParser: {
    value: "prefixParser",
    label: "Supplier Prefix Parser",
    fields: [],
    defaultChecked: true,
    disabled: true,
    helperText:
      "* Adds Supplier's name as a prefix to avoid conflicts on ids (e.g Supplier::external_id)",
  },
  toIntParser: {
    value: "toIntParser",
    label: "Integer Number Parser",
    defaultChecked: false,
    fields: [],
    helperText: "* Parse a number like field to integer (e.g 2.2 -> 2)",
  },
  toFixedDeximalParser: {
    value: "toFixedDeximalParser",
    label: "Decimal Number Parser",
    defaultChecked: false,
    fields: [
      {
        name: "decimals",
        value: 2,
        type: "number",
        formatter: (value) => (isNaN(value) ? value : parseInt(value)),
        validators: (value) =>
          ValidationService.validateNumber({ value, min: 1, max: 32 }),
      },
    ],
    helperText:
      "* Parse a number like field to decimal (e.g 2.2343 -> 2.23 or 56 -> 56.00)",
  },
  toArrayParser: {
    value: "toArrayParser",
    label: "Collection Transformer Parser",
    defaultChecked: false,
    fields: [
      {
        name: "delimiter",
        label: "Delimiter",
        value: "",
        type: "text",
        validators: (value) => true,
      },
    ],
    helperText:
      "* Parse a single field to a collection providing its delimiter e.g labels: a,b,c using delimiter ',' will become a collection of [a,b,c]",
  },
  toArrayPickerParser: {
    value: "toArrayPickerParser",
    label: "Collection Picker",
    defaultChecked: false,
    fields: [
      {
        name: "delimiter",
        label: "Delimiter",
        value: "",
        type: "text",
        validators: (value) => true,
      },
      {
        name: "index",
        label: "Index",
        value: 0,
        type: "number",
        formatter: (value) => (isNaN(value) ? value : parseInt(value)),
        validators: (value) =>
          ValidationService.validateNumber({ value, min: 0 }),
      },
    ],
    helperText:
      "* Parse a single field to a collection providing its delimiter e.g labels: a,b,c using delimiter ',' will become a collection of [a,b,c].Then pick the selected item by providing its index (starting from 0)",
  },
};
export const productFieldOptions = [
  { value: undefined, label: "None", functions: [] },
  {
    value: "external_id",
    label: "External ID",
    required: true,
    functions: [parserFunctions.prefixParser],
  },
  {
    value: "quantity",
    label: "Quantity",
    required: true,
    functions: [parserFunctions.toFixedDeximalParser],
  },
  {
    value: "price",
    label: "Price",
    required: true,
    functions: [parserFunctions.toFixedDeximalParser],
  },
  {
    value: "labels",
    label: "Labels",
    functions: [parserFunctions.toArrayParser],
  },
  { value: "name", label: "Name", functions: [] },
  {
    value: "category",
    label: "Category",
    required: true,
    functions: [parserFunctions.toArrayPickerParser],
  },
  { value: "description", label: "Description" },
  { value: "image", label: "Main Image" },
  {
    value: "images",
    label: "Images",
    functions: [parserFunctions.toArrayParser],
  },
  { value: "sku", label: "SKU", functions: [] },
  {
    value: "length",
    label: "Length",
    functions: [parserFunctions.toFixedDeximalParser],
  },
  {
    value: "width",
    label: "Width",
    functions: [parserFunctions.toFixedDeximalParser],
  },
  {
    value: "height",
    label: "Height",
    functions: [parserFunctions.toFixedDeximalParser],
  },
  {
    value: "weight",
    label: "Weight",
    functions: [parserFunctions.toFixedDeximalParser],
  },
  {
    value: "discount",
    label: "Discount",
    functions: [parserFunctions.toFixedDeximalParser],
  },
  {
    value: "brand",
    label: "Brand",
    functions: [parserFunctions.toArrayPickerParser],
  },
];
