import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Label } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import ProductSuperCategories from "../components/products/ProductSuperCategories";
import { getGroups, getWebsites } from "../store/actions/products";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useProducts from "../store/hooks/useProducts";
import { useCallback } from "react";

function ProductSuperCategoriesPage({
  identifier = "product_super_categories",
}) {
  const dispatch = useDispatch();
  const { group_id } = useParams();
  const { groups } = useProducts();
  useEffect(() => {
    dispatch(getWebsites());
    dispatch(getGroups());
  }, [dispatch]);

  const getTitle = useCallback(() => {
    if (!group_id) {
      return "Product Super Categories";
    }

    const group = (groups || []).find((g) => g.id === group_id)?.name;

    return group ? `Group: ${group}` : "Product Super Categories";
  }, [group_id, groups]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={getTitle()} icon={<Label />} />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <ProductSuperCategories key={identifier} identifier={identifier} />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(ProductSuperCategoriesPage, [ROLES.SUPER_ADMIN])
);
