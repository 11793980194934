import { toServerImage } from "../../utils/generic";
import { Block, Check, Label } from "@mui/icons-material";
import { Avatar, Chip, Tooltip } from "@mui/material";
import ActionsMenu from "../dataGrid/ActionsMenu";
import ValidationService from "../../services/ValidationService";

export const blogPostsFiltersConfig = [
  { name: "title", label: "Title", type: "text" },
  {
    name: "enabled",
    label: "Status",
    type: "select",
    value: undefined,
    options: [
      { value: "All", label: "All" },
      { label: "Active", value: true },
      { label: "Disabled", value: false },
    ],
    formatter: (value) => (value === "All" ? undefined : value),
  },
];

export const BlogPostsColumns = [
  {
    name: "image",
    label: "Post",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
    selected: true,
    disableSorting: true,
  },
  {
    name: "title",
    label: "Title",
    renderComponent: ({ value }) => (
      <Chip
        icon={<Label />}
        label={
          ValidationService.isString(value)
            ? value.split().slice(0, 15).join("") +
              (value.length > 15 ? "..." : "")
            : value
        }
        size="small"
      />
    ),
    selected: true,
  },

  {
    name: "websites",
    label: "Websites",
    renderComponent: ({ value }) => value?.length || 0,
    disableSorting: true,
    selected: true,
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
    disableSorting: true,
    selected: true,
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
    selected: true,
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];
