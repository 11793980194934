import React, { useState } from "react";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";

export default function withSelectionWrapper(Component) {
  return (props) => {
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const handleSelectItems = (items) => setSelected((prev) => items || []);

    const handleSubmitMany = async ({ url, form, onSuccess, onError }) => {
      setLoading(true);
      try {
        const res = await axios.post(url, {});
        if (onSuccess) {
          await onSuccess();
        }
        addNotification("Action Completed", "success");
      } catch (error) {
        if (onError) {
          await onError().catch((err) => err);
        }
        addNotification("Action Failed", "error");
      } finally {
        setLoading(false);
      }
    };

    return (
      <Component
        {...props}
        onSelectItems={handleSelectItems}
        onSubmitSelectedItems={handleSubmitMany}
        isBatchLoading={loading}
        selectedItems={selected}
      />
    );
  };
}
