import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";

import GoBackButton from "../components/ui/GoBackButton";

import { connect, useDispatch } from "react-redux";
import { getBoard } from "../store/actions/boards";
import { useNavigate, useParams } from "react-router-dom";
import BoardView from "../components/boards/BoardView";
import useBoards from "../store/hooks/useBoards";
import BackdropLoader from "../components/ui/BackdropLoader";

function BoardPage({ getBoard }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { board, loading } = useBoards();

  useEffect(() => {
    getBoard(id).then((b) => {
      if (!b) {
        navigate("/boards");
      }
    });
  }, [id, navigate, getBoard]);

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        {!board ? null : <BoardView board={board} />}
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(connect(null, { getBoard })(BoardPage), [
    ROLES.SUPER_ADMIN,
    ROLES.ADMIN,
  ])
);
