import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Label } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import ProductCategories from "../components/products/ProductCategories";
import { useDispatch } from "react-redux";
import { getProductSuperCategories } from "../store/actions/products";
import { useParams } from "react-router-dom";
import useProducts from "../store/hooks/useProducts";

function ProductCategoriesPage({ identifier = "product_categories" }) {
  const dispatch = useDispatch();
  const { supercategory_id } = useParams();
  useEffect(() => {
    dispatch(getProductSuperCategories());
  }, [dispatch]);
  const { product_supercategories } = useProducts();
  const getTitle = () => {
    if (!product_supercategories?.length) {
      return "";
    }

    return `Super Category: ${
      product_supercategories.find((x) => x.id === supercategory_id)?.name || ""
    }`;
  };
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          title={!supercategory_id ? "Product Categories" : getTitle()}
          icon={<Label />}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to={-1} />
      </Grid>
      <Grid item md={12} xs={12}>
        <ProductCategories
          identifier={identifier}
          key={supercategory_id}
          filters={{ supercategory_id }}
        />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(ProductCategoriesPage, [ROLES.SUPER_ADMIN]));
