import React from "react";
import withAuth from "../hoc/withAuth";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Inventory as Stock } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import Inventory from "../components/inventory/Inventory";
import { ROLES } from "../data/roles";
import withRoles from "../hoc/withRoles";

function InventoryPage({ url }) {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={"Inventory"} icon={<Stock />} />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <Inventory url={url} />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(InventoryPage, [ROLES.SUPER_ADMIN]));
