import { useDispatch, useSelector } from "react-redux";
import { fetchData, setLoading } from "../../store/actions/data";

export default function useDataSlice(identifier) {
  const dataSlice = useSelector((state) => state.data?.[identifier] || {});

  const dispatch = useDispatch();

  const getData = (page, view, filters) => {
    return dispatch(fetchData({ page, view, filters, identifier }));
  };

  const setIsLoading = (loading) =>
    dispatch(setLoading({ loading, identifier }));

  return [dataSlice, getData, setIsLoading];
}
