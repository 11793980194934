const initialState = {
  orders: null,
  loading: false,
  order: null,
};

export const OrderActions = {
  GetOrders: "GetOrders",
  GetOrder: "GetOrder",
  OrdersLoading: "OrdersLoading",
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrderActions.OrdersLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case OrderActions.GetOrder:
      return {
        ...state,
        order: action.payload,
      };
    default:
      return state;
  }
};
