import { BusinessCenter, Edit, Save } from "@mui/icons-material";
import { Avatar, Button, Container, Grid } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../store/actions/auth";
import Input from "../forms/Input";
import AnalyticsCard from "../ui/AnalyticsCard";
import useProfileForm from "./useProfileForm";
import { toServerImage, uploadFile } from "../../utils/generic";
import FileImport from "../forms/FileImport";
import useCountries from "../countries/useCountries";
import BackdropLoader from "../ui/BackdropLoader";
import SelectInput from "../ui/SelectInput";
import Title from "../ui/Title";

function ProfileForm({ loading, user, updateProfile }) {
  const {
    form,
    handleChange,
    isValid,
    isTouched,
    getFormValues,
    handleBaseChange,
  } = useProfileForm(user);
  const { countriesOptions, loading: isLoading } = useCountries();
  if (!user) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <BackdropLoader open={loading || isLoading} />
      <AnalyticsCard title="User Details" divider icon={<Edit />}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} display={"flex"} justifyContent={"center"}>
            {form.profile_image.value ? (
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={toServerImage(form.profile_image.value)}
              />
            ) : null}
          </Grid>
          <Grid item md={12} xs={12} display={"flex"} justifyContent={"center"}>
            <FileImport
              label="Upload Profile Image"
              onFileUpload={async (files) => {
                const file = await uploadFile(files);
                if (file) {
                  handleBaseChange("profile_image", file?.filename);
                }
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              {...form.email}
              name="email"
              label="Email"
              onChange={handleChange}
              value={form.email.value}
              disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.name}
              name="name"
              label="Name"
              onChange={handleChange}
              value={form.name.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.last_name}
              name="last_name"
              label="Last Name"
              onChange={handleChange}
              value={form.last_name.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.phone}
              name="phone"
              label="Phone"
              onChange={handleChange}
              value={form.phone.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.vat}
              name="vat"
              label="Vat Number"
              onChange={handleChange}
              value={form.vat.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectInput
              {...form.country_id}
              name="country_id"
              label="Country"
              onChange={handleChange}
              options={countriesOptions}
              value={form.country_id.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.city}
              name="city"
              label="City"
              onChange={handleChange}
              value={form.city.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.address}
              name="address"
              label="Address"
              onChange={handleChange}
              value={form.address.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.postal_code}
              name="postal_code"
              label="Zip Code"
              onChange={handleChange}
              value={form.postal_code.value}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Title divider sx={{ mb: 2 }}>
              <BusinessCenter /> Business Details
            </Title>
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              {...form.business_name}
              name="business_name"
              label="Business Name"
              onChange={handleChange}
              value={form.business_name.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectInput
              {...form.business_country_id}
              name="business_country_id"
              label="Country"
              onChange={handleChange}
              options={countriesOptions}
              value={form.business_country_id.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.business_address}
              name="business_address"
              label="Address"
              onChange={handleChange}
              value={form.business_address.value}
            />
          </Grid>

          <Grid item md={12} xs={12} display="flex" justifyContent="center">
            <Button
              disabled={!isValid || !isTouched || loading || isLoading}
              variant="contained"
              startIcon={<Save />}
              onClick={async () => {
                await updateProfile(getFormValues());
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </AnalyticsCard>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
    loading: auth.loading,
  }),
  { updateProfile }
)(ProfileForm);
