export const ProductActions = {
  ProductsLoading: "ProductsLoading",
  GetProducts: "GetProducts",
  GetProductAttributeCategories: "GetProductAttributeCategories",
  GetProductAttributes: "GetProductAttributes",
  GetProductCategories: "GetProductCategories",
  GetProductSuperCategories: "GetProductSuperCategories",
  GetProductBrands: "GetProductBrands",
  GetProduct: "GetProduct",
  GetProductAttributeCategory: "GetProductAttributeCategory",
  GetProductAttribute: "GetProductAttribute",
  GetProductCategory: "GetProductCategory",
  GetWebsites: "GetWebsites",
  GetWebsite: "GetWebsite",
  GetGroups: "GetGroups",
};

const initialState = {
  products: null,
  product: null,
  product_attribte_categories: null,
  product_supercategories: null,
  product_brands: null,
  product_attribute_category: null,
  product_attributes: null,
  product_attribute: null,
  product_categories: null,
  product_category: null,
  groups: null,
  group: null,
  websites: null,
  website: null,
  loading: false,
  page: 1,
  view: 10,
  total: 0,
  limit: 1,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductActions.ProductsLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case ProductActions.GetProductAttributeCategories:
    case ProductActions.GetProductAttributes:
    case ProductActions.GetProductCategories:
    case ProductActions.GetProducts:
    case ProductActions.GetProductSuperCategories:
    case ProductActions.GetWebsites:
    case ProductActions.GetProductBrands:
    case ProductActions.GetGroups:
      return {
        ...state,
        ...action.payload,
      };
    case ProductActions.GetGroup:
      return {
        ...state,
        group: action.payload,
      };
    case ProductActions.GetWebsite:
      return {
        ...state,
        website: action.payload,
      };
    case ProductActions.GetProductAttributeCategory:
      return {
        ...state,
        product_attribute_category: action.payload,
      };
    case ProductActions.GetProductAttribute:
      return {
        ...state,
        product_attribute: action.payload,
      };
    case ProductActions.GetProductCategory:
      return {
        ...state,
        product_category: action.payload,
      };
    case ProductActions.GetProduct:
      return {
        ...state,
        product: action.payload,
      };
    default:
      return state;
  }
};
