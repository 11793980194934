import { Block, Check, Redeem } from "@mui/icons-material";
import { Avatar, Chip, Tooltip } from "@mui/material";
import ActionsMenu from "../dataGrid/ActionsMenu";
import { toServerImage } from "../../utils/generic";
import ValidationService from "../../services/ValidationService";

export const CouponsColumns = [
  {
    name: "image",
    label: "Coupon",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => (
      <Chip icon={<Redeem />} label={value} size="small" />
    ),
  },
  {
    name: "discount",
    label: "Discount",
    formatter: (value) => ` - ${value} %`,
  },
  {
    name: "website",
    label: "Website",
    renderComponent: ({ value }) => `${value?.domain || "-"}`,
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "enabled_until",
    label: "Expires At",
    formatter: (value) =>
      !value ? "No expiration" : new Date(value).toDateString("el-GR"),
  },
  {
    name: "max_usage",
    label: "Remaining Applications",
    formatter: (value) =>
      ValidationService.isNumber(value) ? value : "Not Restricted",
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString("el-GR"),
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const couponsFiltersConfig = [
  {
    name: "name",
    label: "Coupon",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
  },
  {
    name: "enabled",
    label: "Status",
    type: "select",
    value: undefined,
    options: [
      { value: "All", label: "All" },
      { label: "Active", value: true },
      { label: "Disabled", value: false },
    ],
    formatter: (value) => (value === "All" ? undefined : value),
  },
  {
    name: "enabled_until",
    label: "Expires Until",
    focused: true,
    type: "date",
    value: undefined,
    formatter: (value) => {
      return !value ? undefined : value;
    },
  },
];
