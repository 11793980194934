import React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";

export default function CustomSwitch(props) {
  const handleChange = (event) => {
    if (typeof props.onChange === "function") {
      props.onChange(event, event.target.checked);
    }
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              size="medium"
              type={"checkbox"}
              name={props.name ?? "switch_input"}
              checked={props.checked}
              onChange={handleChange}
              color={props.color ?? "primary"}
              inputProps={{ "aria-label": "controlled" }}
              disabled={props.disabled}
            />
          }
          label={props.label ?? ""}
        />
      </FormGroup>
    </FormControl>
  );
}
