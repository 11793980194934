import React, { useMemo } from "react";
import Container from "../grid/Container";
import Row from "../grid/Row";
import Input from "../forms/Input";
import SelectInput from "../ui/SelectInput";
import useOrdersStaticData from "../../store/hooks/useOrdersStaticData";

export default function OrderBillingShippingForm({
  form,
  handleChange,
  type = "billing",
}) {
  const { countries, shippingCompanies } = useOrdersStaticData();
  const deriveFormConfig = (key) => {
    let transformer = type === "shipping" ? `shipping_` : "";

    return {
      ...form?.[`${transformer}${key}`],
      name: `${transformer}${key}`,
      value: form?.[`${transformer}${key}`]?.value,
    };
  };

  const countriesOptions = useMemo(() => {
    if (!Array.isArray(countries)) {
      return [];
    }
    return countries.map((x) => ({ label: x.name, value: x.id }));
  }, [countries]);

  const shippingCompaniesOptions = useMemo(() => {
    if (type !== "shipping") {
      return [];
    }

    return shippingCompanies.map((c) => ({ label: c.name, value: c.id }));
  }, [shippingCompanies, type]);

  return (
    <Container>
      <Row>
        <Input
          {...deriveFormConfig("name")}
          label="Name"
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Input
          {...deriveFormConfig("last_name")}
          label="Last Name"
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Input
          {...deriveFormConfig("email")}
          label="Email"
          onChange={handleChange}
        />
      </Row>
      <Row>
        <SelectInput
          {...deriveFormConfig("country_id")}
          label="Country"
          options={countriesOptions}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Input
          {...deriveFormConfig("city")}
          label="City"
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Input
          {...deriveFormConfig("address")}
          label="Address"
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Input
          {...deriveFormConfig("postal_code")}
          label="Postal Code"
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Input
          {...deriveFormConfig("phone")}
          label="Phone"
          onChange={handleChange}
        />
      </Row>
      {type === "shipping" && (
        <Row>
          <SelectInput
            {...deriveFormConfig("company_id")}
            label="Shipping Company"
            options={shippingCompaniesOptions}
            onChange={handleChange}
          />
        </Row>
      )}
      {type === "shipping" && (
        <Row>
          <Input
            {...form.shipping_cost}
            type="number"
            name="shipping_cost"
            onChange={handleChange}
            label="Shipping Cost €"
            value={form.shipping_cost.value}
          />
        </Row>
      )}
      {type === "shipping" && (
        <Row>
          <Input
            {...form.cash_on_delivery}
            type="number"
            name="cash_on_delivery"
            onChange={handleChange}
            label="Cash On Delivery €"
            value={form.cash_on_delivery.value}
          />
        </Row>
      )}
    </Container>
  );
}
