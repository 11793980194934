import { useCallback, useEffect, useState } from "react";
import axios from "../../apiClient";
import { toTransactionServiceApiUrl } from "../../utils/generic";

export default function useFetchTransactionDetails(identifier) {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);

  const refetch = useCallback(() => {
    setLoading(true);
    axios
      .get(toTransactionServiceApiUrl(`/transactions/${identifier}`))
      .then((res) => {
        setTransaction(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setTransaction(null);
      });
  }, [identifier]);

  useEffect(() => {
    refetch();
  }, [refetch]);
  return { transaction, loading, refetch };
}
