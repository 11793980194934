import React, { useState } from "react";
import RestTable from "../tables/rest/RestTable";
import { OrderProductsColumns } from "./constants";
import Title from "../ui/Title";
import { Grid } from "@mui/material";
import { Edit, ShoppingCart } from "@mui/icons-material";
import OrderCost from "./OrderCost";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import {
  deriveSafeResponse,
  getPrice,
  toApiUrl,
  toFixedDecimal,
  toServerImage,
} from "../../utils/generic";
import SimpleDialog from "../dialogs/SimpleDialog";
import Input from "../forms/Input";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import { useDispatch } from "react-redux";
import { getOrder } from "../../store/actions/orders";

export default function OrderProducts({ order }) {
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const formConfig = useForm({
    discount: {
      defaultValue: 0,
      formatter: (value) => {
        if (!ValidationService.isNumber(parseFloat(value))) {
          return 0;
        }
        try {
          return parseFloat(parseFloat(value).toFixed(2));
        } catch (error) {
          return value;
        }
      },
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0, max: 100 }),
    },
    price: {
      defaultValue: 0,
      formatter: (value) => {
        if (!ValidationService.isNumber(parseFloat(value))) {
          return 0.01;
        }
        try {
          return parseFloat(value);
        } catch (error) {
          return value;
        }
      },
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0.01 }),
    },
    quantity: {
      defaultValue: 0,
      formatter: (value) => {
        if (!ValidationService.isNumber(parseInt(value))) {
          return 0;
        }
        try {
          return parseInt(value);
        } catch (error) {
          return value;
        }
      },
      validators: (value) => ValidationService.isNumber(value),
    },
  });

  if (!order?.products?.length) {
    return null;
  }

  const handleSelectProduct = (row) => {
    formConfig.resetForm({
      price: toFixedDecimal(row.price, row.price),
      discount: toFixedDecimal(row.discount || 0, row.discount || 0),
      quantity: toFixedDecimal(row.quantity || 0, row.quantity || 0),
    });
    setSelected((prev) => row);
  };

  const handleCancelEdit = () => {
    formConfig.resetForm({
      price: 0,
      discount: 0,
      quantity: 0,
    });
    setSelected(null);
  };

  const { form, handleChange } = formConfig;

  const handleSubmit = async () => {
    let res;
    try {
      const formValues = formConfig.getFormValues();
      setIsLoading(true);
      res = await axios.put(
        toApiUrl(`/orders/order-items/${selected.id}`),
        formValues
      );
      addNotification("Action completed", "success");
      dispatch(getOrder(selected.order_id));
      formConfig.resetForm({ price: 0, quantity: 0, discount: 0 });
      setSelected(null);
    } catch (error) {
      addNotification("Action failed", "error");
    } finally {
      setIsLoading(false);
      return deriveSafeResponse(res);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid md={12} xs={12} sx={{ p: 2 }}>
          <RestTable
            title={
              <Title>
                <ShoppingCart sc={{ mr: 2 }} /> Order Products
              </Title>
            }
            disableSelection
            disablePagination
            sx={{ mt: -2 }}
            columns={OrderProductsColumns}
            rows={order?.products || []}
            view={-1}
            columnProps={{
              actions: {
                actions: [
                  {
                    type: "edit",
                    name: "edit",
                    title: "Edit",
                    icon: <Edit />,
                    action: handleSelectProduct,
                  },
                ],
              },
            }}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <OrderCost order={order} />
        </Grid>
      </Grid>
      <SimpleDialog
        open={!!selected}
        onConfirm={handleSubmit}
        title={
          <Title>
            <Edit sx={{ mr: 2 }} /> Edit Product{" "}
            <b style={{ marginLeft: "1rem" }}>{selected?.product?.name}</b>
          </Title>
        }
        onCancel={handleCancelEdit}
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || isLoading
        }
        disableBackdropClick={isLoading}
      >
        {!!selected ? (
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <img
                src={toServerImage(selected.product?.image)}
                alt={selected?.product?.name}
                width={"300px"}
              />
            </Grid>
            <Grid item md={12} xs={12}></Grid>
            <Grid item md={4} xs={12}>
              <Input
                {...form.price}
                type="number"
                name="price"
                value={form.price.value}
                label="Price €"
                onChange={handleChange}
                text={
                  form.discount.value
                    ? ` Discount Price: ${getPrice(
                        form.price.value,
                        form.discount.value
                      )}€`
                    : null
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input
                {...form.discount}
                type="number"
                name="discount"
                value={form.discount.value}
                label="Discount %"
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input
                {...form.quantity}
                type="number"
                name="quantity"
                value={form.quantity.value}
                label="Quantity"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        ) : null}
      </SimpleDialog>
    </>
  );
}
