import axios from "axios";
import ValidationService from "./services/ValidationService";
import { Config } from "./config";
import { store } from "./store";
import { AuthActions } from "./store/reducers/authReducer";

export async function refreshAccessToken(refresh_token) {
  if (!refresh_token) {
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    store.dispatch({ type: AuthActions.LOGOUT });
    window.location.replace("/login");
    throw new Error();
  }
  const {
    data: { access_token: accessToken, refresh_token: refreshToken },
  } = await axios.post(`${Config.API_BASE_URL}/auth/refresh-token`, {
    refresh_token,
  });
  localStorage.setItem("access_token", accessToken);
  localStorage.setItem("refresh_token", refreshToken);
  return accessToken;
}

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (!ValidationService.isNullOrUndefinedOrEmpty(token)) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      const refresh_token = localStorage.getItem("refresh_token");
      if (!refresh_token) {
        return Promise.reject(error);
      }
      originalRequest._retry = true;
      return refreshAccessToken(refresh_token)
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          store.dispatch({ type: AuthActions.LOGOUT });
          window.location.replace("/login");
        });
    }

    return Promise.reject(error);
  }
);

export default axios;
