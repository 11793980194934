import { Mail, Person, SupervisedUserCircle } from "@mui/icons-material";
import { Avatar, Container, Grid } from "@mui/material";
import React from "react";
import { getUserFullName, toServerImage } from "../../utils/generic";
import Title from "../ui/Title";

export default function ProfileBasicInfo({ user }) {
  if (!user) return null;

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {user?.profile_image ? (
            <Avatar
              sx={{ width: 100, height: 100 }}
              src={toServerImage(user.profile_image)}
            />
          ) : null}
          <Title sx={{ my: 1 }} divider>
            <Mail sx={{ mr: 2 }} /> {user?.email}
          </Title>
          <Title sx={{ my: 1 }} divider>
            <Person sx={{ mr: 2 }} /> {getUserFullName(user)}
          </Title>
          <Title sx={{ my: 1 }} divider>
            <SupervisedUserCircle sx={{ mr: 2 }} />{" "}
            {user?.role?.name?.toUpperCase()}
          </Title>
        </Grid>
      </Grid>
    </Container>
  );
}
