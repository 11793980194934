import { Avatar, Button, Chip, Grid, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  getPrice,
  toFixedDecimal,
  toServerImage,
  toServerThumbnailImage,
  uploadFile,
} from "../../utils/generic";
import FileImport from "../forms/FileImport";
import Input from "../forms/Input";
import {
  Add,
  ChevronLeft,
  ChevronRight,
  Delete,
  Label,
  PhotoAlbum,
  Settings,
  Visibility,
} from "@mui/icons-material";
import Title from "../ui/Title";
import { useDispatch } from "react-redux";
import useProducts from "../../store/hooks/useProducts";
import {
  getProductAttributes,
  getProductBrands,
  getProductCategories,
} from "../../store/actions/products";
import BackdropLoader from "../ui/BackdropLoader";
import SelectInput from "../forms/SelectInput";
import CustomSwitch from "../ui/Switch";
import SimpleDialog from "../dialogs/SimpleDialog";
import ValidationService from "../../services/ValidationService";
import SortableList from "../ui/SortableList";

function generateRandomString(length) {
  const characters = "0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function ProductForm({
  form,
  handleChange,
  handleBaseChange,
  setLoading,
  isLoading,
  withDimensions = false,
  disableAttributes = false,
}) {
  const [label, setLabel] = useState("");
  const [imageViewIndex, setImageViewIndex] = useState(-1);
  const [beforeUploadFilePayload, setBeforeUploadFilePayload] = useState({});
  const dispatch = useDispatch();
  const {
    product_categories,
    product_brands,
    loading,
    product_attribute_categories,
  } = useProducts();
  const [productPricesModal, setProductPricesModal] = useState(false);

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getProductBrands());
    dispatch(getProductAttributes());
  }, [dispatch]);

  const productCategoryOptions = useMemo(() => {
    if (!product_categories?.length) {
      return [];
    }

    return product_categories.map((product_category) => ({
      label: product_category.name,
      value: product_category.id,
    }));
  }, [product_categories]);

  const productBrandsOptions = useMemo(() => {
    if (!product_brands?.length) {
      return [];
    }

    return [{ label: "None", value: null }].concat(
      product_brands.map((x) => ({
        label: x.name,
        value: x.id,
      }))
    );
  }, [product_brands]);

  const productAttributeCategoriesOptions = useMemo(() => {
    if (!Array.isArray(product_attribute_categories)) {
      return [];
    }
    return product_attribute_categories.flatMap((a) =>
      (a?.attributes || []).map((attr) => ({
        label: `${a.name}: ${attr.name}`,
        value: attr.id,
      }))
    );
  }, [product_attribute_categories]);

  const handleFileUpload = async (files) => {
    try {
      if (
        withDimensions &&
        !beforeUploadFilePayload.dimensions.every((x) =>
          Object.keys(x)
            .filter((key) => key !== "name")
            .every(
              (key) => typeof x[key] === "number" && x[key] > 0 && x[key] < 2000
            )
        )
      ) {
        return;
      }
      setLoading(true);
      const file = await uploadFile(files, {
        type: "frontend",
        dimensions: JSON.stringify(
          (beforeUploadFilePayload.dimensions || []).map(
            ({ name, ...rest }) => rest
          )
        ),
      });
      setBeforeUploadFilePayload({});
      if (file) {
        handleBaseChange("image", file?.filename);
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading || isLoading} />
      <Grid item md={4} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <FileImport
              accept={"image/*"}
              label={
                <Title divider>
                  <PhotoAlbum /> Main Image
                </Title>
              }
              name="image"
              onFileUpload={async (files) => {
                if (files) {
                  if (withDimensions) {
                    setBeforeUploadFilePayload({
                      file: files,
                      dimensions: [
                        { name: "Thumbnail", width: 200, height: 200 },
                        { name: "Second Thumbnail", width: 600, height: 600 },
                      ],
                    });
                  } else {
                    setBeforeUploadFilePayload({
                      dimensions: [
                        { name: "Thumbnail", width: 200, height: 200 },
                        { name: "Second Thumbnail", width: 600, height: 600 },
                      ],
                    });
                    handleFileUpload(files);
                  }
                }
              }}
            />
            {form.image.value ? (
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  maxWidth: "100%",
                  maxHeight: "300px",
                }}
                src={toServerThumbnailImage(form.image.value)}
                alt="product main"
              />
            ) : null}
          </Grid>
          <Grid item md={12} xs={12}>
            <FileImport
              disabled={form.images.value?.length > 15}
              accept={"image/*"}
              multiple
              label="Product Images"
              name="image"
              onFileUpload={async (files) => {
                const promises = ([...files] || []).map((file) =>
                  uploadFile(file, { type: "frontend" })
                );
                setLoading(true);
                const uploads = (await Promise.all(promises))
                  .filter(Boolean)
                  .map((x) => x.filename);
                if (uploads.length) {
                  handleBaseChange(
                    "images",
                    (form.images.value || []).concat(uploads)
                  );
                }
                setLoading(false);
              }}
            />
            <SortableList
              onReorder={(items) => {
                try {
                  handleBaseChange(
                    "images",
                    items.map((x) => x.secondary)
                  );
                } catch (error) {}
              }}
              items={(form.images.value || []).map((img, idx) => ({
                primary: <Avatar src={toServerImage(img)} />,
                secondary: img,
                renderActions: () => (
                  <>
                    <IconButton
                      onClick={() => {
                        setImageViewIndex(idx);
                      }}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleBaseChange(
                          "images",
                          form.images.value.filter((x) => x !== img)
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </>
                ),
              }))}
              title={
                <Title divider sx={{ mb: 2 }}>
                  <PhotoAlbum /> Uploaded Product Images
                </Title>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <CustomSwitch
              name="enabled"
              label="Status"
              checked={form.enabled.value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.name}
              type="text"
              name="name"
              value={form.name.value}
              label="Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.sku}
              type="text"
              name="sku"
              value={form.sku.value}
              label="SKU"
              onChange={handleChange}
              endIcon={
                <IconButton
                  disabled={!!form.sku.value}
                  onClick={() => {
                    try {
                      handleBaseChange("sku", generateRandomString(12));
                    } catch (error) {}
                  }}
                >
                  <Add />
                </IconButton>
              }
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <SelectInput
              {...form.categories}
              disabled={!productCategoryOptions?.length}
              name="categories"
              label="Categories"
              onChange={handleChange}
              options={productCategoryOptions}
              value={form.categories.value}
              autoComplete
              multiple
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <SelectInput
              {...form.brand_id}
              name="brand_id"
              label="Brand"
              onChange={handleChange}
              disabled={!productBrandsOptions?.length}
              options={productBrandsOptions || []}
              autoComplete
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <SelectInput
              {...form.attributes}
              name="attributes"
              label="Attributes"
              autoComplete
              multiple
              disabled={
                !productAttributeCategoriesOptions?.length || disableAttributes
              }
              options={productAttributeCategoriesOptions}
              isValid
              isTouched
              value={form.attributes.value || []}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Input
              {...form.price}
              type="number"
              name="price"
              value={form.price.value}
              label="Price €"
              onChange={handleChange}
              startIcon={
                <IconButton onClick={() => setProductPricesModal(true)}>
                  <Settings />
                </IconButton>
              }
              text={
                form.discount.value
                  ? ` Discount Price: ${getPrice(
                      form.price.value,
                      form.discount.value
                    )}€`
                  : null
              }
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Input
              {...form.discount}
              type="number"
              name="discount"
              value={form.discount.value}
              label="Discount %"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Input
              {...form.quantity}
              type="number"
              name="quantity"
              value={form.quantity.value}
              label="Quantity"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Input
              {...form.length}
              type="number"
              name="length"
              value={form.length.value}
              label="Length (cm)"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Input
              {...form.width}
              type="number"
              name="width"
              value={form.width.value}
              label="Width (cm)"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Input
              {...form.height}
              type="number"
              name="height"
              value={form.height.value}
              label="Height (cm)"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Input
              {...form.weight}
              type="number"
              name="weight"
              value={form.weight.value}
              label="Weight (Kg)"
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Input
                      {...form.description}
                      type="text"
                      name="description"
                      value={form.description.value}
                      label="Description"
                      onChange={handleChange}
                      multiline
                      rows={5}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Input
                      {...form.keywords}
                      type="text"
                      name="keywords"
                      value={form.keywords.value}
                      label="Keywords / SEO"
                      onChange={handleChange}
                      multiline
                      rows={2}
                      helperText="Seperate keywords with comma"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Input
                      {...form.labels}
                      name="labels"
                      onChange={(e) => setLabel(e.target.value)}
                      label="Product Labels"
                      value={label}
                      endIcon={
                        <IconButton
                          onClick={() => {
                            let value = label.trim();
                            if (value.toLowerCase() === "προσφορές") {
                              value = "Προσφορές";
                            }
                            handleBaseChange(
                              "labels",
                              form.labels.value
                                .filter(
                                  (x) => x.toLowerCase() !== value.toLowerCase()
                                )
                                .concat(value)
                            );
                            setLabel("");
                          }}
                          disabled={label?.trim()?.length < 2}
                        >
                          <Add />
                        </IconButton>
                      }
                    />
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      {(form.labels.value || []).map((label) => (
                        <Grid item key={label}>
                          <Chip
                            icon={<Label />}
                            label={label}
                            onDelete={() => {
                              handleBaseChange(
                                "labels",
                                form.labels.value.filter((x) => x !== label)
                              );
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Title divider>
                      <Settings sx={{ mr: 2 }} /> Advanced
                    </Title>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomSwitch
                      name="disableCoupons"
                      label="Coupons not apply"
                      checked={form.meta.value?.disableCoupons}
                      onChange={(e) => {
                        handleBaseChange("meta", {
                          ...(form.meta.value || {}),
                          disableCoupons: e.target.checked,
                        });
                      }}
                    />
                    <CustomSwitch
                      name="manualStatusHandling"
                      label="Disable automatic status check after order"
                      checked={form.meta.value?.manualStatusHandling}
                      onChange={(e) => {
                        handleBaseChange("meta", {
                          ...(form.meta.value || {}),
                          manualStatusHandling: e.target.checked,
                        });
                      }}
                    />
                    <CustomSwitch
                      name="disableCart"
                      label="Disable Add to Cart"
                      checked={form.meta.value?.disableCart}
                      onChange={(e) => {
                        handleBaseChange("meta", {
                          ...(form.meta.value || {}),
                          disableCart: e.target.checked,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12}>
                        <Input
                          type="number"
                          isValid={
                            !form.meta?.value?.minimumCartQuantity ||
                            form.meta?.value?.minimumCartQuantity >= 1
                          }
                          sx={{ my: 2 }}
                          isTouched
                          label="Minimum Cart Quantity"
                          value={form.meta?.value?.minimumCartQuantity || 1}
                          onChange={(e) => {
                            let quantity = parseInt(e.target.value);
                            if (!quantity || quantity < 1) {
                              quantity = 1;
                            }
                            handleBaseChange("meta", {
                              ...(form.meta.value || {}),
                              minimumCartQuantity: quantity,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {" "}
                        <Input
                          type="number"
                          isValid={
                            !form.meta?.value?.maximumCartQuantity ||
                            form.meta?.value?.maximumCartQuantity >= 1
                          }
                          sx={{ my: 2 }}
                          isTouched
                          label="Maximum Cart Quantity"
                          value={form.meta?.value?.maximumCartQuantity || 1}
                          onChange={(e) => {
                            let quantity = parseInt(e.target.value);
                            if (!quantity || quantity < 1) {
                              quantity = 1;
                            }
                            handleBaseChange("meta", {
                              ...(form.meta.value || {}),
                              maximumCartQuantity: quantity,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Input
                          type="number"
                          isValid={
                            !form.meta?.value?.cartQuantityPairing ||
                            form.meta?.value?.cartQuantityPairing >= 1
                          }
                          isTouched
                          sx={{ my: 2 }}
                          label="Product Pairing"
                          helperText="* Describes the product's quantity that is going to be added in cart per click"
                          value={form.meta?.value?.cartQuantityPairing || 1}
                          onChange={(e) => {
                            let quantity = parseInt(e.target.value);
                            if (!quantity || quantity < 1) {
                              quantity = 1;
                            }
                            handleBaseChange("meta", {
                              ...(form.meta.value || {}),
                              cartQuantityPairing: quantity,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item md={12} xs={12}>
                    <Title>
                      <GifBoxTwoTone /> Offers
                      <IconButton
                        onClick={() => {
                          handleBaseChange("meta", {
                            ...(form.meta.value || {}),
                            offers: [
                              ...(form.meta.value?.offers || []),
                              {
                                buy: 1,
                                free: 1,
                                enabled: false,
                              },
                            ],
                          });
                        }}
                      >
                        <Add />
                      </IconButton>
                    </Title>
                    {(form.meta.value?.offers || []).map((offer, index) => (
                      <Grid key={index} container spacing={2}>
                        <Grid item md={4} xs={12} sx={{ my: 2 }}>
                          <Input
                            name="buy"
                            type="number"
                            label="Buy X"
                            isValid={offer.buy > 0}
                            isTouched
                            value={offer.buy}
                            onChange={(e) => {
                              let quantity = parseInt(e.target.value);
                              if (!quantity || quantity < 1) {
                                quantity = 1;
                              }
                              handleBaseChange("meta", {
                                ...(form.meta.value || {}),
                                offers: form.meta.value.offers.map((o, idx) =>
                                  idx === index ? { ...o, buy: quantity } : o
                                ),
                              });
                            }}
                          />
                        </Grid>
                        <Grid item md={4} xs={12} sx={{ my: 2 }}>
                          <Input
                            name="buy"
                            type="number"
                            label="Free Y"
                            isValid={offer.free > 0}
                            isTouched
                            value={offer.free}
                            onChange={(e) => {
                              let quantity = parseInt(e.target.value);
                              if (!quantity || quantity < 1) {
                                quantity = 1;
                              }
                              handleBaseChange("meta", {
                                ...(form.meta.value || {}),
                                offers: form.meta.value.offers.map((o, idx) =>
                                  idx === index ? { ...o, free: quantity } : o
                                ),
                              });
                            }}
                          />
                        </Grid>
                        <Grid item md={2} xs={12} sx={{ my: 2 }}>
                          <CustomSwitch
                            name="enabled"
                            label="Active"
                            checked={offer.enabled}
                            onChange={(e) => {
                              handleBaseChange("meta", {
                                ...(form.meta.value || {}),
                                offers: form.meta.value.offers.map((o, idx) =>
                                  idx === index
                                    ? { ...o, enabled: e.target.checked }
                                    : o
                                ),
                              });
                            }}
                          />
                        </Grid>
                        <Grid item md={2} xs={12} sx={{ my: 2 }}>
                          <IconButton
                            onClick={() =>
                              handleBaseChange("meta", {
                                ...(form.meta.value || {}),
                                offers: form.meta.value.offers.filter(
                                  (o, idx) => idx !== index
                                ),
                              })
                            }
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SimpleDialog
        maxWidth="md"
        open={imageViewIndex >= 0}
        onCancel={() => setImageViewIndex(-1)}
      >
        <Grid container spacing={2}>
          <Grid
            item
            md={1}
            xs={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              onClick={() => {
                setImageViewIndex((prev) =>
                  prev > 0 ? prev - 1 : form.images.value.length - 1
                );
              }}
            >
              <ChevronLeft />
            </IconButton>
          </Grid>
          <Grid item md={10} xs={10} display="flex" justifyContent="center">
            {ValidationService.validateNumber({
              value: imageViewIndex,
              min: 0,
              max: form.images.value?.length,
            }) ? (
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  maxWidth: "100%",
                  maxHeight: "300px",
                }}
                src={toServerImage(form.images.value?.[imageViewIndex])}
                alt="product"
              />
            ) : null}
          </Grid>
          <Grid
            item
            md={1}
            xs={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              size="large"
              onClick={() => {
                setImageViewIndex((prev) =>
                  prev === form.images.value.length - 1 ? 0 : prev + 1
                );
              }}
            >
              <ChevronRight />
            </IconButton>
          </Grid>
        </Grid>
      </SimpleDialog>
      {withDimensions && !!beforeUploadFilePayload?.file ? (
        <SimpleDialog
          disableBackdropClick={loading}
          disableConfirm={loading}
          maxWidth="md"
          open={!!beforeUploadFilePayload?.file}
          onCancel={() => setBeforeUploadFilePayload({})}
          onConfirm={handleFileUpload}
        >
          <Grid container spacing={3}>
            {(beforeUploadFilePayload?.dimensions || []).map(
              (dimension, idx) => (
                <Grid item key={idx} md={12} xs={12}>
                  <Title divider sx={{ my: 2 }}>
                    {dimension.name}
                  </Title>
                  {["width", "height"].map((key) => (
                    <Input
                      key={key}
                      type="number"
                      value={dimension[key]}
                      label={`${key} (px)`}
                      onChange={(e) => {
                        setBeforeUploadFilePayload((prev) => ({
                          ...prev,
                          dimensions: prev.dimensions.map((x, index) =>
                            index === idx
                              ? {
                                  ...x,
                                  [key]: parseInt(e.target.value, 10),
                                }
                              : x
                          ),
                        }));
                      }}
                      helperText={"* Between 1 and 2000 pixels"}
                      isTouched
                      isValid={
                        typeof dimension[key] === "number" &&
                        dimension[key] > 0 &&
                        dimension[key] < 2000
                      }
                    />
                  ))}
                </Grid>
              )
            )}
          </Grid>
        </SimpleDialog>
      ) : null}
      {productPricesModal ? (
        <SimpleDialog
          open={productPricesModal}
          onCancel={() => setProductPricesModal(false)}
          title={
            <Title>
              <Settings /> Product Prices Settings
            </Title>
          }
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Input
                {...form.price}
                type="number"
                name="price"
                value={form.price.value}
                label="Price €"
                onChange={handleChange}
                text={
                  form.discount.value
                    ? ` Discount Price: ${getPrice(
                        form.price.value,
                        form.discount.value
                      )}€`
                    : null
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                {...form.wholesale_price}
                type="number"
                name="wholesale_price"
                value={form.wholesale_price.value}
                label="Wholesale Price €"
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                {...form.tax}
                type="number"
                name="tax"
                value={form.tax.value}
                label="Tax %"
                onChange={handleChange}
                text="* Applies to Wholesale price and forms the product price (w*(100 + tax)/100) = product_price"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                disabled={
                  !form.price.isValid ||
                  !form.wholesale_price.isValid ||
                  !form.tax.isValid ||
                  !form.tax.value
                }
                color="primary"
                onClick={() => {
                  const values = {
                    price: form.price.value,
                    wholesale_price: form.wholesale_price.value,
                    tax: form.tax.value,
                  };
                  const taxDecimal = toFixedDecimal((100 + values.tax) / 100);
                  handleBaseChange(
                    "wholesale_price",
                    toFixedDecimal(values.price / taxDecimal)
                  );
                }}
              >
                Calculate Wholesale Price
              </Button>
              <Button
                sx={{ mx: 2 }}
                variant="contained"
                disabled={
                  !form.price.isValid ||
                  !form.wholesale_price.isValid ||
                  !form.tax.isValid ||
                  !form.tax.value
                }
                color="primary"
                onClick={() => {
                  const values = {
                    price: form.price.value,
                    wholesale_price: form.wholesale_price.value,
                    tax: form.tax.value,
                  };
                  const taxDecimal = toFixedDecimal((100 + values.tax) / 100);
                  handleBaseChange(
                    "price",
                    toFixedDecimal(values.wholesale_price * taxDecimal)
                  );
                }}
              >
                Calculate Product Price
              </Button>
            </Grid>
          </Grid>
        </SimpleDialog>
      ) : null}
    </Grid>
  );
}
