import { useCallback, useEffect, useState } from "react";
import axios from "../../apiClient";
import { toApiUrl, toServerImage, uploadFile } from "../../utils/generic";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  CopyAllOutlined,
  Delete,
  Download,
  Edit,
  Search,
} from "@mui/icons-material";
import Paginator from "../ui/Paginator";
import Input from "../forms/Input";
import FileImport from "../forms/FileImport";
import { addNotification } from "../../store/actions/notifications";
import BackdropLoader from "../ui/BackdropLoader";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export default function FileLibraryList() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [deleteEntity, setDeleteEntity] = useState(null);

  const handleDeleteEntity = async () => {
    setLoading(true);
    try {
      await axios.delete(toApiUrl(`/file-library/${deleteEntity.id}`));
      refetch(data?.page || 1, data?.view || 12, {
        name: search || undefined,
      });
      addNotification("Operation completed", "success");
    } catch (error) {
      addNotification("Operation failed", "error");
    } finally {
      setLoading(false);
      setDeleteEntity(null);
    }
  };

  const refetch = useCallback(async (page, view, filters = {}) => {
    setLoading(true);
    try {
      const { data } = await axios.get(toApiUrl("/file-library"), {
        params: {
          ...filters,
          page,
          view,
          order_by: "created_at:desc",
        },
      });
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { results = [], ...paginationData } = data || {};
  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={6} xs={12}></Grid>
      <Grid item md={2}>
        <IconButton>
          <FileImport
            multiple
            onFileUpload={async (f) => {
              try {
                const files = [...f];
                if (!files.length) {
                  return;
                }
                setLoading(true);

                const promises = [];
                files.forEach((file) => {
                  promises.push(uploadFile(file, { type: "frontend" }));
                });
                const fileResults = await Promise.all(promises);
                for (const file of fileResults) {
                  try {
                    await axios.post(toApiUrl("/file-library"), {
                      name: file.file.originalname,
                      path: file.filename,
                      meta: { mimetype: file.file.mimetype },
                    });
                    addNotification(
                      `Operation completed ${file.file.originalname}`,
                      "success"
                    );
                  } catch (error) {
                    addNotification(
                      `Operation possibly ${file.file.originalname}`,
                      "error"
                    );
                  }
                }

                refetch(data?.page || 1, data?.view || 12, {
                  name: search || undefined,
                });
                addNotification("Operation completed", "success");
              } catch (error) {
                addNotification("Operation possibly failed", "error");
              } finally {
                setLoading(false);
              }
            }}
          />
        </IconButton>
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          isValid
          isTouched
          label="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onBlur={(e) => {
            if (!e.target.value) {
              refetch(data?.page || 1, data?.view || 12, { name: undefined });
            }
          }}
          endIcon={
            <IconButton
              onClick={() =>
                refetch(data?.page || 1, data?.view || 10, {
                  name: search || undefined,
                })
              }
            >
              <Search />
            </IconButton>
          }
        />
      </Grid>
      {(results || []).map((item) => (
        <Grid item md={3} xs={12}>
          <Card>
            <CardHeader
              title={item?.name || ""}
              titleTypographyProps={{ fontSize: 10 }}
            />
            <CardContent>
              <img
                src={
                  item?.meta?.mimetype?.startsWith("image")
                    ? toServerImage(item.path)
                    : "/file.png"
                }
                alt={item.name}
                width={"100%"}
                style={{
                  objectFit: "contain !important",
                  display: "table",
                  margin: "auto",
                  height: 200,
                }}
              />
            </CardContent>
            <CardActions>
              <IconButton
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      toServerImage(item.path)
                    );
                    addNotification("File Path Copied");
                  } catch (error) {}
                }}
              >
                <CopyAllOutlined />
              </IconButton>
              <IconButton
                onClick={() => {
                  try {
                    const a = document.createElement("a");
                    a.href = toServerImage(item.path);
                    a.download = item.name;
                    a.target = "_blank";
                    a.click();
                    a.remove();
                  } catch (error) {}
                }}
              >
                <Download />
              </IconButton>
              <IconButton onClick={() => setDeleteEntity(item)}>
                <Delete />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      ))}
      <Grid item md={12} xs={12}>
        <Paginator
          allowedViews={[]}
          page={data?.page || 1}
          view={data?.view || 12}
          total={paginationData?.total || 0}
          onChange={(page) =>
            refetch(page, data?.view || 12, { name: search || undefined })
          }
        />
      </Grid>
      <ConfirmationDialog
        open={!!deleteEntity}
        onCancel={() => setDeleteEntity(null)}
        onConfirm={handleDeleteEntity}
        disableBackdropClick={loading}
        disableConfirm={loading}
      />
    </Grid>
  );
}
