import React from "react";
import useFetchTransactionDetails from "./useFetchTransactionDetails";
import { Grid } from "@mui/material";
import BackdropLoader from "../ui/BackdropLoader";
import ListView from "../ui/ListView";
import { CalendarMonthOutlined, CreditCard } from "@mui/icons-material";
import OrderHistory from "./OrderHistory";

export default function TransactionDetails({ order }) {
  const { transaction, loading } = useFetchTransactionDetails(
    order?.external_id
  );

  if (loading) {
    return <BackdropLoader open={loading} />;
  }

  if (!transaction) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <ListView
          items={[
            {
              name: "provider",
              secondary: "Provider",
              icon: <CreditCard />,
              primary: transaction.provider?.toUpperCase(),
            },
            {
              name: "type",
              secondary: "Type",
              icon: <CreditCard />,
              primary: transaction.type?.toUpperCase(),
            },
            {
              name: "amount",
              secondary: "Amount",
              icon: <CreditCard />,
              primary: `${
                transaction.metaData?.paymentData?.totalWithShipping
              } (${transaction.metaData?.paymentData?.totalPrice} ${
                transaction.currency
              }   + ${transaction?.metaData?.shipping_cost || 0} ${
                transaction.currency
              } Shipping)`,
            },
            {
              name: "identifier",
              secondary: "Identifier",
              icon: <CreditCard />,
              primary: transaction.identifier,
            },
            {
              name: "status",
              secondary: "Status",
              icon: <CreditCard />,
              primary: transaction.status?.toUpperCase(),
            },
            {
              name: "createdAt",
              secondary: "Created",
              icon: <CalendarMonthOutlined />,
              primary: new Date(transaction.createdAt)?.toUTCString(),
            },
            {
              name: "updatedAt",
              secondary: "Updated",
              icon: <CreditCard />,
              primary: new Date(transaction.updatedAt)?.toUTCString(),
            },
          ]}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <OrderHistory
          history={transaction?.history || []}
          titleKey="status"
          messageKey="command"
        />
      </Grid>
    </Grid>
  );
}
