import React from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { toApiUrl } from "../../utils/generic";
import { Grid } from "@mui/material";
import { FavoriteColumns } from "./constants";
import { useNavigate } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";

export default function UserFavorites({ user }) {
  const navigate = useNavigate();
  const [dataSlice, getData, setLoading] = useDataSlice("user_favorites");

  const onEdit = (row) => navigate(`/products/${row?.product_id}`);
  const onDelete = async (row) => {
    setLoading(true);
    let res;
    try {
      res = await axios.delete(toApiUrl(`/favorites/${row.id}`));
      await getData(dataSlice.page, dataSlice.view, dataSlice.filters);
      addNotification("Action Completed", "success");
      setLoading(false);
    } catch (error) {
      addNotification("Action Failed", "error");
      setLoading(false);
    }

    return res;
  };
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <TableDataLoader
          endpoint={toApiUrl(`/favorites`)}
          entityName="results"
          identifier="user_favorites"
          filters={{ user_id: user?.id }}
          columns={FavoriteColumns}
          onRowClick={(row) => navigate(`/products/${row?.product_id}`)}
          actions={[
            {
              name: "edit",
              title: "Edit",
              icon: <Edit />,
              action: onEdit,
            },
            {
              name: "delete",
              title: "Delete",
              icon: <Delete />,
              action: onDelete,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
