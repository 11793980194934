import React, { useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { toApiUrl } from "../../utils/generic";
import { Delete, Info, NotificationsActive } from "@mui/icons-material";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import { NotificationColumns, notificationStatusToProps } from "./constants";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import { Grid, Tooltip, Typography } from "@mui/material";
import useTheme from "../../store/hooks/useTheme";

export default function Notifications() {
  const [dataSlice, getData, setLoading] = useDataSlice("notifications");
  const [entity, setEntity] = useState(null);
  const theme = useTheme();

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/notifications/${row.id}`));
      addNotification("Notification Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification("Notification was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const onView = (row) => setEntity(row);

  const viewProps =
    notificationStatusToProps[entity?.data?.status] ||
    notificationStatusToProps.info;

  return (
    <>
      <TableDataLoader
        endpoint={toApiUrl("/notifications")}
        entityName="results"
        identifier="notifications"
        columns={NotificationColumns}
        actions={[
          {
            name: "info",
            title: "Details",
            icon: <Info />,
            action: onView,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
      />
      <SimpleDialog
        title={
          <Title>
            <NotificationsActive sx={{ mr: 2 }} /> Details
          </Title>
        }
        open={!!entity}
        onCancel={() => setEntity(null)}
      >
        {!entity ? null : (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Title divider>
                <Tooltip title={viewProps?.title || "-"} sx={{ mr: 2 }}>
                  {viewProps?.icon}
                </Tooltip>
                {entity?.data?.title || "-"}
              </Title>
            </Grid>
            {entity?.data?.message ? (
              <Grid item md={12} xs={12}>
                <Typography sx={{ p: 3 }} variant="body2">
                  {entity?.data?.message}
                </Typography>
              </Grid>
            ) : null}
            <Grid item md={12} xs={12}>
              <pre
                style={{
                  maxWidth: "100%",
                  maxHeight: "400px",
                  overflowY: "auto",
                  overflowX: "auto",
                  padding: "30px",
                  background: theme?.themeMode === "dark" ? "#000" : "#eee",
                }}
              >
                {JSON.stringify(entity?.data || {}, null, 4)}
              </pre>
            </Grid>
          </Grid>
        )}
      </SimpleDialog>
    </>
  );
}
