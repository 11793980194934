import React from "react";
import { useFetchProducts } from "../../store/hooks/useProducts";
import { CircularProgress, Grid } from "@mui/material";
import {
  getFinalProductPrice,
  getToArrayOrToEmptyArray,
  toServerThumbnailImage,
} from "../../utils/generic";
import { Link } from "react-router-dom";
import Title from "../ui/Title";
import { Info } from "@mui/icons-material";
import AnalyticsCard from "../ui/AnalyticsCard";

export default function PopularWebsiteProducts({
  filters,
  title,
  gridOptions = { md: 3, sm: 6 },
}) {
  const { data, loading } = useFetchProducts(filters);
  if (!data?.length) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Title divider sx={{ my: 2 }}>
          <Info sx={{ mr: 2 }} /> {title}
        </Title>
      </Grid>
      {getToArrayOrToEmptyArray(data).map((product) => (
        <Grid item {...gridOptions} key={product.id}>
          <AnalyticsCard
            sx={{ height: "250px", maxHeight: "250px" }}
            count={loading ? <CircularProgress size={20} /> : null}
          >
            {!loading ? (
              <Link
                to={`/products/${product.id}`}
                style={{
                  fontSize: "10px",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      objectFit: "contain",
                    }}
                    src={toServerThumbnailImage(product?.image)}
                    alt={product.name}
                  />
                  <span style={{ fontSize: "18px" }}>
                    {getFinalProductPrice({ ...product, quantity: 1 })} &euro;
                  </span>
                </div>

                <span style={{ display: "block", marginTop: "1rem" }}>
                  {product.name}
                </span>
              </Link>
            ) : null}
          </AnalyticsCard>
        </Grid>
      ))}
    </Grid>
  );
}
