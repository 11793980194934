import React, { useEffect } from "react";
import withRoles from "../hoc/withRoles";
import withAuth from "../hoc/withAuth";
import { ROLES } from "../data/roles";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrder } from "../store/actions/orders";
import useOrders from "../store/hooks/useOrders";
import { Grid } from "@mui/material";
import BackdropLoader from "../components/ui/BackdropLoader";
import GoBackButton from "../components/ui/GoBackButton";
import TabContainer from "../components/tabs/TabContainer";
import { CreditCard, EditAttributes, History, Info } from "@mui/icons-material";
import OrderDetails from "../components/orders/OrderDetails";
import Message from "../components/ui/Message";
import { OrderActions } from "../store/reducers/ordersReducer";
import TransactionDetails from "../components/orders/TransactionDetails";
import OrderProducts from "../components/orders/OrderProducts";
import ManageOrderStateButton from "../components/orders/ManageOrderStateButton";
import OrderHistory from "../components/orders/OrderHistory";

function OrderPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, order } = useOrders();

  useEffect(() => {
    dispatch(getOrder(id));
    return () => {
      dispatch({ type: OrderActions.GetOrder, payload: null });
    };
  }, [id, dispatch]);

  if (!order && !loading) {
    return (
      <>
        <GoBackButton to={-1} />
        <Message
          sx={{ my: 2 }}
          message={`Order ${id} was not found`}
          severity="error"
        />
      </>
    );
  }
  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item>
        <GoBackButton to={-1} />
      </Grid>

      <Grid item>
        <ManageOrderStateButton
          order={order}
          onSubmit={async () => dispatch(getOrder(id))}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        {order ? (
          <TabContainer
            sx={{ p: 2 }}
            tabs={[
              { label: "Order Details", icon: <Info /> },
              { label: "Edit Order Products", icon: <EditAttributes /> },
              {
                label: "Order History",
                icon: <History />,
              },
              {
                label: "Transaction Details",
                icon: <CreditCard />,
                disabled:
                  ["stripe", "paypal"].indexOf(order?.payment_method?.name) ===
                  -1,
              },
            ]}
          >
            <OrderDetails />
            <OrderProducts order={order} />
            <OrderHistory history={order?.history?.logs} />
            <TransactionDetails order={order} />
          </TabContainer>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default withRoles(withAuth(OrderPage), [ROLES.ADMIN, ROLES.SUPER_ADMIN]);
