import React from "react";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { Grid, IconButton } from "@mui/material";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import { Add, Delete, Mail } from "@mui/icons-material";
import Input from "../forms/Input";
import CustomButton from "../ui/Button";

export default function ResendCompleteOrderEmailForm({ order, onSubmit }) {
  const formConfig = useForm({
    emails: {
      defaultValue: [...new Set([order?.email, order?.shipping_email])].filter(
        Boolean
      ),
      validators: [
        (value) => (value || []).every(ValidationService.validateEmail),
        (value) =>
          !value?.length ||
          !(value || []).some((x, i) =>
            value.some((p, idx) => p === x && i !== idx)
          ),
      ],
    },
  });

  const { form, handleBaseChange, isValid } = formConfig;

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <ListView
          title={
            <Title divider sx={{ justifyContent: "space-between" }}>
              <Title>
                <Mail sx={{ mr: 2 }} /> Order Completed Mail Recipients
              </Title>{" "}
              <IconButton
                onClick={() =>
                  handleBaseChange("emails", form.emails.value.concat(""))
                }
              >
                <Add />
              </IconButton>
            </Title>
          }
          items={form.emails.value.map((x, i) => ({
            icon: <Mail />,
            primary: (
              <Input
                type="email"
                {...form.emails}
                value={x}
                label="Recipient"
                onChange={(e) =>
                  handleBaseChange(
                    "emails",
                    form.emails.value.map((p, idx) =>
                      idx !== i ? p : e.target.value
                    )
                  )
                }
              />
            ),
            renderActions: () => (
              <IconButton
                onClick={() =>
                  handleBaseChange(
                    "emails",
                    form.emails.value.filter((_, idx) => i !== idx)
                  )
                }
              >
                <Delete />
              </IconButton>
            ),
          }))}
        />
      </Grid>

      <Grid
        item
        md={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <CustomButton
          disabled={!isValid}
          fullWidth
          onClick={async () => {
            await onSubmit(formConfig.getFormValues());
          }}
        >
          Submit
        </CustomButton>
      </Grid>
    </Grid>
  );
}
