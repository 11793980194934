import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchData,
  initializeDataSlice,
  removeDataSlice,
  setSearchFilters,
} from "../../store/actions/data";
import RestTable from "../tables/rest/RestTable";
import Message from "../ui/Message";
import Filters from "./Filters";
import ColumnSelector from "./ColumnSelector";

const withDataLoader = (Component) => {
  return connect((state) => ({ dataReducer: state.data }), {
    initializeDataSlice,
    fetchData,
    removeDataSlice,
  })(
    ({
      dataReducer,
      identifier,
      initializeDataSlice,
      columns,
      method = "GET",
      endpoint,
      pageKey = "page",
      viewKey = "view",
      totalKey = "total",
      entityName,
      fetchData,
      filters = {},
      withFilters = false,
      filtersConfig = {},
      removeDataSlice,
      withColumnSelection = false,
      ...rest
    }) => {
      const [selectedColumns, setSelectedColumns] = useState(() =>
        (columns || []).map((x) => ({ ...x }))
      );

      useEffect(() => {
        initializeDataSlice({
          identifier,
          payload: {
            method,
            endpoint,
            columns,
            pageKey,
            viewKey,
            totalKey,
            entityName,
            filters,
            filtersConfig,
            withFilters,
          },
        });
        return () => {
          removeDataSlice({
            identifier,
          });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      const { isInitialized } = dataReducer?.[identifier] || {};
      if (!isInitialized) {
        return null;
      }
      return (
        <Component
          {...rest}
          withColumnSelection={withColumnSelection}
          onSelectColumns={setSelectedColumns}
          {...dataReducer[identifier]}
          columns={selectedColumns}
          getData={fetchData}
          identifier={identifier}
          filtersConfig={filtersConfig}
        />
      );
    }
  );
};

function TableDataLoader({
  data,
  page,
  view,
  total,
  loading,
  columns,
  columnProps,
  renderToolbarChildren,
  getData,
  identifier,
  filters,
  filtersConfig,
  withFilters = false,
  isInitialized,
  disableSelection = true,
  onSelect,
  onSelectAll,
  selectionType = "MULTIPLE",
  selectionFn,
  selectionId,
  selectedItems,
  withColumnSelection,
  onSelectColumns,
  tableStyles,
  ...rest
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isInitialized) {
      getData({ identifier, page, view, filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12} display="flex" justifyContent="flex-end">
        {withColumnSelection ? (
          <ColumnSelector
            columns={columns || []}
            onSelectColumns={onSelectColumns}
          />
        ) : // <SelectInput
        //   isValid
        //   isTouched
        //   sx={{ width: "20%" }}
        //   fullWidth={false}
        //   multiple
        //   onChange={(e) => {
        //     onSelectColumns(
        //       columns.map((c) => ({
        //         ...c,
        //         selected: e.target.value.some((x) => x === c.name),
        //       }))
        //     );
        //   }}
        //   value={(columns || []).filter((x) => x.selected).map((x) => x.name)}
        //   options={(columns || [])
        //     .map((c) => ({
        //       label: c.label,
        //       value: c.name,
        //     }))
        //     .filter((x) => x.value !== "actions")}
        // />
        null}
        {withFilters ? (
          <Filters
            onSelectItems={onSelect}
            identifier={identifier}
            config={filtersConfig}
          />
        ) : null}
      </Grid>

      <Grid item md={12} xs={12}>
        <RestTable
          tableStyles={tableStyles}
          rows={data}
          selectedItems={selectedItems || []}
          page={page}
          view={view}
          count={total}
          isLoading={loading}
          columns={
            withColumnSelection
              ? columns.filter((x) => x.selected || x.name === "actions")
              : columns
          }
          allowedViews={[5, 10, 25, 50, 100]}
          onRowClick={rest?.onRowClick}
          columnProps={{
            ...columnProps,
            actions: {
              actions: rest.actions,
              getData: async () =>
                await getData({ page, view, filters, identifier }),
            },
          }}
          renderToolbarChildren={renderToolbarChildren}
          onPageChange={async (page) => {
            await getData({ identifier, page, view, filters });
          }}
          onViewChange={async (view) => {
            await getData({ identifier, page, view, filters });
          }}
          onSortChange={async (newOrder) => {
            if (!newOrder) {
              dispatch(
                setSearchFilters({
                  identifier,
                  filters: { ...rest.searchFilters, order_by: undefined },
                })
              );
            } else {
              dispatch(
                setSearchFilters({
                  identifier,
                  filters: {
                    ...rest.searchFilters,
                    order_by: Object.entries(newOrder)
                      .map(([key, value]) => {
                        return `${key}:${value}`;
                      })
                      .join(","),
                  },
                })
              );
            }
            await getData({ identifier, page, view, filters });
          }}
          defaultOrderBy={rest.defaultOrderBy}
          defaultSortingColumn={rest.defaultSortingColumn}
          disableSelection={disableSelection}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          selectionType={selectionType}
          selectionFn={selectionFn}
          id={selectionId}
        />
        {!loading && !data?.length ? (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Message message="No data at the moment" />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default withDataLoader(TableDataLoader);
