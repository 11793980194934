import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { toApiUrl, toServerImage } from "../../utils/generic";
import Title from "../ui/Title";
import { useNavigate } from "react-router-dom";
import { Info, LinkOffOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import axios from "../../apiClient";
import { getProduct } from "../../store/actions/products";
import { addNotification } from "../../store/actions/notifications";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import BackdropLoader from "../ui/BackdropLoader";

export default function ChildrenProductVariants({ product }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [deleteEntity, setDeleteEntity] = useState(null);

  if (!product?.parent_product?.product) {
    return null;
  }

  const parent = product.parent_product.product;

  const deleteVariant = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        toApiUrl(`/product-variants/${product.parent_product.id}`)
      );
      dispatch(getProduct(product.id));
      addNotification("Action completed", "success");
    } catch (error) {
      addNotification("Action failed", "error");
    } finally {
      setDeleteEntity(false);
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      <BackdropLoader open={isLoading} />

      <Grid item md={4} xs={false}></Grid>
      <Grid item md={4} xs={12}>
        <Title divider sx={{ my: 2, justifyContent: "space-between" }}>
          <div>
            Parent Product: <b>{parent.name}</b>
          </div>
          <Box>
            <Tooltip title="Go to Parent Product">
              <IconButton onClick={() => navigate(`/products/${parent.id}`)}>
                <Info />
              </IconButton>
            </Tooltip>
            <Tooltip title="Disassociate products">
              <IconButton onClick={() => setDeleteEntity(true)}>
                <LinkOffOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Title>
        <Box justifyContent={"center"} display="flex">
          <img
            src={toServerImage(parent.image)}
            height="200px"
            alt={parent.name}
          />
        </Box>

        <Typography
          color="text.secondary"
          variant="body2"
          component="p"
          textAlign="center"
        >
          * The current product's parent
        </Typography>
      </Grid>
      <ConfirmationDialog
        open={deleteEntity}
        onCancel={() => setDeleteEntity(false)}
        disableBackdropClick={isLoading}
        disableConfirm={isLoading}
        onConfirm={deleteVariant}
      />
    </Grid>
  );
}
