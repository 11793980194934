export const AggregateActions = {
  AggregateLoading: "AggregateLoading",
  GetDashboardData: "GetDashboardData",
};

const initialState = {
  loading: false,
  dashboard: {
    platformUsers: 0,
    platformOrders: 0,
    platformProducts: 0,
    accountUsers: 0,
    accountOrders: 0,
    accountProducts: 0,
  },
};

export const aggregatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case AggregateActions.AggregateLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case AggregateActions.GetDashboardData:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
