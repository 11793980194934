import React, { useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { PaymentMethodsColumns } from "./constants";
import { toApiUrl, toServerImage, uploadFile } from "../../utils/generic";
import { Add, Delete, Edit } from "@mui/icons-material";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import FileImport from "../forms/FileImport";
import { Avatar, IconButton } from "@mui/material";

export default function PaymentMethods() {
  const [entity, setEntity] = useState(null);
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
    image: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 1000 }),
    },
  });
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      name: obj?.name || "",
      enabled: !!obj?.enabled,
      image: obj?.image || "",
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("payment_methods");

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { name, id, enabled, image } = formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/payment-methods"), {
          name,
          enabled,

          image,
        });
      } else {
        res = await axios.put(toApiUrl(`/payment-methods/${id}`), {
          name,
          enabled,

          image,
        });
      }
      addNotification(
        `Payment Method ${id ? "Updated" : "Created"}`,
        "success"
      );

      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification(
        `Payment Method was not ${id ? "Updated" : "Created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/payment-methods/${row.id}`));
      addNotification("Payment Method Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification("Payment Method was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const { form, handleChange } = formConfig;
  return (
    <>
      <TableDataLoader
        endpoint={toApiUrl("/payment-methods")}
        entityName="results"
        identifier="payment_methods"
        columns={PaymentMethodsColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <IconButton onClick={() => openDialogForm({ name: "" })}>
              <Add />
            </IconButton>
          );
        }}
      />
      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Payment Method
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        {form.image.value ? (
          <Avatar
            sx={{ width: 100, height: 100 }}
            src={toServerImage(form.image.value)}
          />
        ) : null}
        <FileImport
          label="Upload  Image"
          onFileUpload={async (files) => {
            setLoading(true);
            const file = await uploadFile(files);
            if (file) {
              formConfig.handleBaseChange("image", file?.filename);
            }
            setLoading(false);
          }}
        />
        <CustomSwitch
          name="enabled"
          label="Status"
          checked={form.enabled.value}
          onChange={handleChange}
        />
        <Input
          sx={{ my: 2 }}
          {...form.name}
          name="name"
          label="Name"
          value={form.name.value}
          disabled
          onChange={handleChange}
        />
      </SimpleDialog>
    </>
  );
}
