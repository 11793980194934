import React, { useState } from "react";
import Container from "../grid/Container";
import Column from "../grid/Column";
import Input from "../forms/Input";
import CustomButton from "../ui/Button";

export default function OrderCommentsForm({ order, onSubmit }) {
  const [recipientComments, setRecipientComments] = useState(
    order?.recipient_comments || ""
  );
  const [orderComments, setOrderComments] = useState(
    order?.order_comments || ""
  );

  return (
    <Container>
      <Column md={6} xs={12}>
        <Input
          focused
          isValid
          isTouched
          multiline
          fullWitdth
          rows={6}
          name="recipient_comments"
          label="Customer Comments"
          onChange={(e) => setRecipientComments(e.target.value || "")}
          helperText="* Refers to the comments added by the customer"
          value={recipientComments}
        />
        <CustomButton
          sx={{ my: 1 }}
          onClick={async () =>
            await onSubmit({ recipient_comments: recipientComments })
          }
        >
          Save
        </CustomButton>
      </Column>
      <Column md={6} xs={12}>
        <Input
          focused
          isValid
          isTouched
          multiline
          fullWitdth
          rows={6}
          name="order_comments"
          label="Order Internal Comments"
          onChange={(e) => setOrderComments(e.target.value || "")}
          helperText="* Refers to the comments added by the administrators"
          value={orderComments}
        />
        <CustomButton
          sx={{ my: 1 }}
          onClick={async () =>
            await onSubmit({ order_comments: orderComments })
          }
        >
          Save
        </CustomButton>
      </Column>
    </Container>
  );
}
