import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function GoBackButton({ to }) {
  const navigate = useNavigate();
  return (
    <Tooltip title="Go Back">
      <IconButton onClick={() => navigate(-1)} color="primary">
        <ArrowBackIos />
      </IconButton>
    </Tooltip>
  );
}
