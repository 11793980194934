import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { BrandingWatermark, Label } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import ProductBrands from "../components/products/ProductBrands";

function ProductBrandsPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          title={"Product Brands"}
          icon={<BrandingWatermark />}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <ProductBrands />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(ProductBrandsPage, [ROLES.SUPER_ADMIN]));
