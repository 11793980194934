import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ValidationService from "../services/ValidationService";
import useAuth from "../store/hooks/useAuth";

export default function AuthWrapper({ children }) {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (
      !ValidationService.isNullOrUndefinedOrEmpty(
        localStorage.getItem("access_token")
      )
    ) {
      navigate("/");
    }
  }, [navigate]);

  if (token || isAuthenticated) {
    return null;
  }
  return children;
}
