import { ChevronRight } from "@mui/icons-material";
import { Drawer, IconButton } from "@mui/material";
import React from "react";
import Container from "../grid/Container";
import Row from "../grid/Row";
import Title from "./Title";

export default function CustomDrawer({
  onClose,
  children,
  open,
  drawerWidth = 300,
  variant = "persistent",
  anchor = "right",
  title = "",
}) {
  if (!open) {
    return null;
  }
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        zIndex: 1280,
        p: 2,
        mt: 4,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          zIndex: 1280,
        },
      }}
      variant={variant}
      anchor={anchor}
      style={{ zIndex: 1280 }}
      open={open}
      onClose={onClose}
    >
      <Title divider>
        <IconButton onClick={onClose}>
          <ChevronRight />
        </IconButton>
        {title}
      </Title>

      <Container sx={{ p: 2 }}>
        <Row>{children}</Row>
      </Container>
    </Drawer>
  );
}
