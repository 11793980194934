import { Home } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from "react";
import NavigationLink from "../components/ui/NavigationLink";
import withAuth from "../hoc/withAuth";

function ForbiddenPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12} display="flex" justifyContent={"center"}>
        <img src="/forbidden.png" alt="Forbidden" />
      </Grid>
      <Grid item md={12} xs={12} display="flex" justifyContent={"center"}>
        <Button
          LinkComponent={NavigationLink}
          to="/"
          variant="contained"
          startIcon={<Home />}
        >
          Home Page
        </Button>
      </Grid>
    </Grid>
  );
}

export default withAuth(ForbiddenPage, "SOFT");
