import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import {
  ProductCategoriesColumns,
  productCategoriesFiltersConfig,
} from "./constants";
import {
  getToArrayOrToEmptyArray,
  toApiUrl,
  toServerImage,
  uploadFile,
} from "../../utils/generic";
import { Add, ArrowRight, Delete, Edit, Reorder } from "@mui/icons-material";
import CustomButton from "../ui/Button";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import FileImport from "../forms/FileImport";
import { Avatar, IconButton } from "@mui/material";
import useProducts from "../../store/hooks/useProducts";
import SelectInput from "../forms/SelectInput";
import Message from "../ui/Message";
import { useNavigate } from "react-router-dom";
import SortableList from "../ui/SortableList";

export default function ProductCategories({
  filters,
  identifier = "product_categories",
}) {
  const [entity, setEntity] = useState(null);
  const { product_supercategories } = useProducts();
  const [reorderConfig, setReorderConfig] = useState(null);
  const navigate = useNavigate();
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    description: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 2, max: 1000 }),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
    image: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 1000 }),
    },
    supercategories: {
      defaultValue: [filters?.supercategory_id || ""].filter(Boolean),
      validators: (value) => Array.isArray(value),
    },
    keywords: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({
          value: value?.trim(),
          min: 1,
          max: 1000,
        }),
    },
  });
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      name: obj?.name || "",
      description: obj?.description || "",
      enabled: !!obj?.enabled,
      image: obj?.image || "",
      supercategories: Array.isArray(obj.supercategories)
        ? obj.supercategories.map((x) =>
            ValidationService.isObject(x) ? x?.supercategory_id : x
          )
        : [],
      keywords: obj?.keywords || "",
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const superCategoriesOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(product_supercategories).map((entity) => ({
      label: entity.name,
      value: entity.id,
    }));
  }, [product_supercategories]);

  const [dataSlice, getData, setLoading] = useDataSlice(identifier);

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { name, id, description, enabled, image, supercategories, keywords } =
      formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/product-categories"), {
          name,
          enabled,
          description,
          image,
          supercategories,
          keywords,
        });
      } else {
        res = await axios.put(toApiUrl(`/product-categories/${id}`), {
          name,
          enabled,
          description,
          image,
          supercategories,
          keywords,
        });
      }
      addNotification(
        `Product Category ${id ? "Updated" : "Created"}`,
        "success"
      );

      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification(
        `Product Category was not ${id ? "Updated" : "Created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/product-categories/${row.id}`));
      addNotification("Product Category Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view, filters);
    } catch (error) {
      addNotification("Product Category was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const productCategoriesFiltersConfigMemoized = useMemo(() => {
    if (filters?.supercategory_id) {
      return productCategoriesFiltersConfig;
    }

    return [
      {
        isDisabled: (filters) => filters?.ngroup,
        name: "supercategory_id",
        label: "Super Category",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          superCategoriesOptions
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
    ].concat(productCategoriesFiltersConfig);
  }, [filters?.supercategory_id, superCategoriesOptions]);

  const { form, handleChange } = formConfig;
  return (
    <>
      <TableDataLoader
        withColumnSelection
        defaultOrderBy="asc"
        defaultSortingColumn="name"
        filters={{ order_by: "name:asc", ...filters }}
        withFilters
        filtersConfig={productCategoriesFiltersConfigMemoized}
        endpoint={toApiUrl("/product-categories")}
        entityName="results"
        identifier={identifier}
        columns={ProductCategoriesColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <IconButton
                sx={{ mr: 2 }}
                onClick={() =>
                  openDialogForm({
                    name: "",
                    supercategories: [filters?.supercategory_id].filter(
                      Boolean
                    ),
                  })
                }
              >
                <Add />
              </IconButton>
              <IconButton
                variant="outlined"
                disabled={!dataSlice?.data?.length}
                onClick={async () => {
                  try {
                    setLoading(true);
                    const { data } = await axios.get(
                      toApiUrl("/static/product-categories", {
                        params: {
                          supercategory_id: filters?.supercategory_id,
                        },
                      })
                    );
                    setReorderConfig({
                      entity: "product_categories",
                      items: (data?.results || [])
                        .filter((x) =>
                          filters?.supercategory_id
                            ? x.supercategory_id === filters.supercategory_id
                            : true
                        )
                        .map((x) => ({
                          ...x,
                          primary: <Avatar src={toServerImage(x.image)} />,
                          secondary: x.name,
                          icon: <Reorder />,
                          divider: true,
                        })),
                    });
                  } catch (error) {
                    setReorderConfig(null);
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <Reorder />
              </IconButton>
            </div>
          );
        }}
      />
      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Product Category
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        {!superCategoriesOptions.length ? (
          <>
            <Message message="No super categories were created yet. You have to register at least one super category in order to start creating product categories" />
            <CustomButton
              fullWidth
              endIcon={<ArrowRight />}
              onClick={() => navigate("/products/supercategories")}
            >
              Go To Super Categories
            </CustomButton>
          </>
        ) : (
          <>
            {form.image.value ? (
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={toServerImage(form.image.value)}
              />
            ) : null}
            <FileImport
              label="Upload Category Image"
              onFileUpload={async (files) => {
                setLoading(true);
                const file = await uploadFile(files);
                if (file) {
                  formConfig.handleBaseChange("image", file?.filename);
                }
                setLoading(false);
              }}
            />
            <CustomSwitch
              name="enabled"
              label="Status"
              checked={form.enabled.value}
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.name}
              name="name"
              label="Name"
              value={form.name.value}
              onChange={handleChange}
            />
            <SelectInput
              {...form.supercategories}
              value={form.supercategories.value || []}
              label="Super Category"
              onChange={handleChange}
              options={superCategoriesOptions}
              autoComplete
              multiple
            />
            <Input
              multiline
              rows={5}
              sx={{ my: 2 }}
              {...form.description}
              name="description"
              label="Description"
              value={form.description.value}
              onChange={handleChange}
            />
            <Input
              {...form.keywords}
              type="text"
              name="keywords"
              value={form.keywords.value}
              label="Keywords / SEO"
              onChange={handleChange}
              multiline
              rows={2}
              sx={{ my: 2 }}
              helperText="Seperate keywords with comma"
            />
          </>
        )}
      </SimpleDialog>
      <SimpleDialog
        title={
          <Title>
            <Reorder sx={{ mr: 2 }} /> Reorder Categories
          </Title>
        }
        open={!!reorderConfig}
        onCancel={() => setReorderConfig(null)}
        onConfirm={async () => {
          try {
            setLoading(true);
            await axios.put(toApiUrl("/aggregator/reorder"), {
              entity: reorderConfig.entity,
              ids: reorderConfig.items.map(({ id }) => id),
            });
            await getData(dataSlice?.page, dataSlice?.view, filters);
            setReorderConfig(null);
            addNotification("Action completed", "success");
          } catch (error) {
            addNotification("Action failed", "error");
          } finally {
            setLoading(false);
          }
        }}
      >
        {!!reorderConfig ? (
          <SortableList
            items={reorderConfig?.items}
            onReorder={(items) =>
              setReorderConfig((prev) => ({ ...prev, items }))
            }
          />
        ) : null}
      </SimpleDialog>
    </>
  );
}
