import { Lock } from "@mui/icons-material";
import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import Input from "../components/forms/Input";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import BackdropLoader from "../components/ui/BackdropLoader";
import Message from "../components/ui/Message";
import ValidationService from "../services/ValidationService";
import { requestPasswordReset } from "../store/actions/auth";
import useForm from "../store/hooks/useForm";

function RequestPasswordResetPage({ requestPasswordReset, loading }) {
  const formConfig = useForm({
    email: {
      defaultValue: "",
      validators: ValidationService.validateEmail,
    },
  });

  const [success, setSuccess] = useState(false);

  const { form, handleChange, isValid, isTouched, getFormValues, resetForm } =
    formConfig;
  return (
    <Container maxWidth="md">
      <BackdropLoader open={loading} />
      <AnalyticsCard title="Reset Password" icon={<Lock />} divider>
        <Grid container spacing={2}>
          {!success ? (
            <>
              <Grid item md={12} xs={12}>
                <Input
                  {...form.email}
                  name="email"
                  type="email"
                  onChange={handleChange}
                  label="Type your email"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={loading || !isValid || !isTouched}
                  onClick={async () => {
                    const formValues = getFormValues();
                    const res = await requestPasswordReset(formValues);
                    if (res) {
                      resetForm();
                      setSuccess(true);
                    }
                  }}
                >
                  Send
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} display="flex" justifyContent="center">
              <div>
                <img src={"/completed.png"} alt="ok" />
                <Message message="Please check your email inbox" />
              </div>
            </Grid>
          )}
        </Grid>
      </AnalyticsCard>
    </Container>
  );
}

export default connect(
  ({ auth }) => ({
    loading: auth.loading,
  }),
  { requestPasswordReset }
)(RequestPasswordResetPage);
