import React, { useEffect, useState } from "react";
import {
  getProductAttributes,
  getProductSuperCategories,
} from "../../store/actions/products";
import { connect } from "react-redux";
import useProducts from "../../store/hooks/useProducts";
import BackdropLoader from "../ui/BackdropLoader";
import { Grid } from "@mui/material";
import EditProductForm from "./EditProductForm";
import TabContainer from "../tabs/TabContainer";
import { Info, Label } from "@mui/icons-material";
import EditProductAttributes from "./EditProductAttributes";

function DuplicateProductForm({
  product,
  getProductAttributes,
  getProductSuperCategories,
  onDuplicateSave,
  onAttributeChange,
}) {
  const { loading } = useProducts();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // await getProductAttributes();
      await getProductSuperCategories();

      setIsLoading(false);
    };
    fetchData();
  }, [getProductSuperCategories]);

  return (
    <>
      <BackdropLoader open={loading || isLoading} />
      <Grid container spacing={2}>
        {product ? (
          <Grid item md={12} xs={12}>
            <TabContainer
              disableUnmount
              sx={{ p: 2 }}
              tabs={[{ label: "Basic Info", icon: <Info /> }]}
            >
              <EditProductForm
                product={product}
                isDuplicateMode
                onDuplicateSave={async (res) => {
                  if (res?.data) {
                    await onDuplicateSave(res?.data);
                  }
                }}
              />
              {/* <EditProductAttributes
                product={product}
                onAttributeChange={onAttributeChange}
                isDuplicateMode
              /> */}
            </TabContainer>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default connect(null, {
  getProductAttributes,
  getProductSuperCategories,
})(DuplicateProductForm);
