import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { toGreekDate } from "../../utils/generic";
import { LockClockOutlined } from "@mui/icons-material";

export default function OrderHistory({
  history,
  titleKey = "type",
  messageKey = "message",
  id = "id",
}) {
  return (
    <Timeline position="alternate">
      {(history || []).map((log, idx) => (
        <TimelineItem key={log?.[id] || idx}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {toGreekDate(log.timestamp)}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <LockClockOutlined />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="p" component="span">
              {log?.[titleKey]}
            </Typography>
            <Typography>{log?.[messageKey] || ""}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
