import { Grid } from "@mui/material";
import React from "react";
import Input from "../forms/Input";
import SelectInput from "../forms/SelectInput";

export default function SupplierForm({
  form,
  handleChange,
  supplierOptions,
  isEdit,
}) {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {supplierOptions?.length && !isEdit ? (
        <Grid item md={12} xs={12}>
          <SelectInput
            {...form.supplier_id}
            options={supplierOptions}
            label={"Supplier"}
            value={form.supplier_id.value}
            onChange={handleChange}
            name="supplier_id"
            disabled={isEdit}
          />
        </Grid>
      ) : null}
      <Grid item md={12} xs={12}>
        <Input
          type="number"
          {...form.quantity}
          name="quantity"
          value={form.quantity.value}
          onChange={handleChange}
          label="Quantity"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Input
          type="text"
          {...form.barcode}
          name="barcode"
          value={form.barcode.value}
          onChange={handleChange}
          label="Barcode"
        />
      </Grid>
    </Grid>
  );
}
