import { ProductActions } from "../reducers/productReducer";
import axios from "../../apiClient";
import { deriveSafeResponse, toApiUrl } from "../../utils/generic";
export const setProductsLoading = (loading) => async (dispatch) =>
  dispatch({ type: ProductActions.ProductsLoading, payload: loading });

export const getProducts =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/products"), {
        params: { ...filters, page, view },
      });

      dispatch({ type: ProductActions.GetProducts, payload: res.data });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getProductAttributeCategories =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/static/product-attribute-categories"), {
        params: { ...filters },
      });

      dispatch({
        type: ProductActions.GetProductAttributeCategories,
        payload: res.data,
      });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getProductAttributes =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/static/product-attribute-categories"), {
        params: { ...filters },
      });

      dispatch({
        type: ProductActions.GetProductAttributeCategories,
        payload: {
          product_attribute_categories: res.data?.results || res.data,
        },
      });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getProductSuperCategories =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/static/product-super-categories"), {
        params: { ...filters },
      });

      dispatch({
        type: ProductActions.GetProductSuperCategories,
        payload: { product_supercategories: res.data?.results || res.data },
      });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getGroups =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/static/groups"), {
        params: { ...filters },
      });

      dispatch({
        type: ProductActions.GetGroups,
        payload: { groups: res.data?.results || res.data },
      });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getProductBrands =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/static/brands"), {
        params: { ...filters },
      });

      dispatch({
        type: ProductActions.GetProductBrands,
        payload: { product_brands: res.data?.results || res.data },
      });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getWebsites =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/static/websites"), {
        params: { ...filters },
      });

      dispatch({
        type: ProductActions.GetWebsites,
        payload: { websites: res.data?.results || res.data },
      });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getWebsite = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(setProductsLoading(true));
    res = await axios.get(toApiUrl(`/websites/${id}`));

    dispatch({
      type: ProductActions.GetWebsite,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setProductsLoading(false));
    return deriveSafeResponse(res);
  }
};

export const getProductCategories =
  (page = 1, view = 10, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setProductsLoading(true));
      res = await axios.get(toApiUrl("/static/product-categories"), {
        params: { ...filters },
      });

      dispatch({
        type: ProductActions.GetProductCategories,
        payload: { product_categories: res.data?.results || res.data },
      });
    } catch (error) {
    } finally {
      dispatch(setProductsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getProduct = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(setProductsLoading(true));
    res = await axios.get(toApiUrl(`/products/${id}`));
    dispatch({ type: ProductActions.GetProduct, payload: res.data });
  } catch (error) {
  } finally {
    dispatch(setProductsLoading(false));
    return deriveSafeResponse(res);
  }
};

export const getProductAttributeCategory = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(setProductsLoading(true));
    res = await axios.get(toApiUrl(`/product-attribute-categories/${id}`));
    dispatch({
      type: ProductActions.GetProductAttributeCategory,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setProductsLoading(false));
    return deriveSafeResponse(res);
  }
};

export const getProductAttribute = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(setProductsLoading(true));
    res = await axios.get(toApiUrl(`/product-attributes/${id}`));
    dispatch({
      type: ProductActions.GetProductAttribute,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setProductsLoading(false));
    return deriveSafeResponse(res);
  }
};

export const getProductCategory = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(setProductsLoading(true));
    res = await axios.get(toApiUrl(`/product-categories/${id}`));
    dispatch({
      type: ProductActions.GetProductCategory,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setProductsLoading(false));
    return deriveSafeResponse(res);
  }
};
