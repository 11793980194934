import { TablePagination } from "@mui/material";
import React from "react";

export default function Paginator({
  total = 0,
  page,
  view = 10,
  allowedViews = [5, 10, 20, 50],
  onChange,
}) {
  return (
    <TablePagination
      rowsPerPageOptions={allowedViews}
      component="div"
      count={total}
      rowsPerPage={view}
      page={page - 1}
      onPageChange={(_, newPage) => onChange(newPage + 1, view)}
      onRowsPerPageChange={(e) => onChange(page, e.target.value)}
    />
  );
}
