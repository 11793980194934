import React from "react";
import Title from "../ui/Title";
import { Grid } from "@mui/material";
import { LocalShipping, Payment, ShoppingBag } from "@mui/icons-material";
import { getTotalOrderPrice } from "../../utils/generic";

export default function OrderCost({ order, title = "Cost Summary" }) {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Title divider>
          <ShoppingBag sx={{ mr: 2 }} /> {title}
        </Title>
        <Title divider sx={{}}>
          <Payment sx={{ mr: 2 }} /> Products Total:{" "}
          <b>{getTotalOrderPrice(order)} &euro;</b>
        </Title>
        <Title divider sx={{}}>
          <LocalShipping sx={{ mr: 2 }} /> Shipping:{" "}
          <b>{order?.shipping_cost} &euro;</b>
        </Title>
        <Title divider sx={{}}>
          <Payment sx={{ mr: 2 }} /> Total:{" "}
          <b>{getTotalOrderPrice(order, true)} &euro;</b>
        </Title>
        {order?.cash_on_delivery ? (
          <Title divider sx={{}}>
            <LocalShipping sx={{ mr: 2 }} /> Cash On Delivery:{" "}
            <b>{order?.cash_on_delivery} &euro;</b>
          </Title>
        ) : null}
      </Grid>
    </Grid>
  );
}
