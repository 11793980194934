import React from "react";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { Avatar, Grid, IconButton } from "@mui/material";
import { toServerThumbnailImage } from "../../utils/generic";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import { Add, Delete, Mail, Remove, ShoppingBag } from "@mui/icons-material";
import Input from "../forms/Input";
import CustomButton from "../ui/Button";

export default function CompleteOrderForm({ order, onSubmit }) {
  const paymentMethod = order?.payment_method?.name;
  const orderStatus = order?.order_status?.name;

  const shouldTheValueBeAffected = false; // so far always the amount of quantity is reduced during order creation
  // orderStatus !== "completed" &&
  // ["cod", "bank"].indexOf(paymentMethod) !== -1;

  const formConfig = useForm({
    emails: {
      defaultValue: [...new Set([order?.email, order?.shipping_email])].filter(
        Boolean
      ),
      validators: [
        (value) => (value || []).every(ValidationService.validateEmail),
        (value) =>
          !value?.length ||
          !(value || []).some((x, i) =>
            value.some((p, idx) => p === x && i !== idx)
          ),
      ],
    },
    products: {
      defaultValue: order?.products?.map((p) => ({
        id: p.id,
        quantity: !shouldTheValueBeAffected ? 0 : p.quantity,
        product_id: p.product_id,
        name: p.product.name,
        image: toServerThumbnailImage(p.product.image),
        order_quantity: p.quantity,
      })),
      validators: (value) =>
        (value || []).every((x) =>
          ValidationService.validateNumber({
            value: x.quantity,
            min: 0,
            max: x.order_quantity,
          })
        ),
    },
  });

  const { form, handleBaseChange, isValid } = formConfig;

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <ListView
          title={
            <Title divider sx={{ justifyContent: "space-between" }}>
              <Title>
                <Mail sx={{ mr: 2 }} /> Order Completed Mail Recipients
              </Title>{" "}
              <IconButton
                onClick={() =>
                  handleBaseChange("emails", form.emails.value.concat(""))
                }
              >
                <Add />
              </IconButton>
            </Title>
          }
          items={form.emails.value.map((x, i) => ({
            icon: <Mail />,
            primary: (
              <Input
                type="email"
                {...form.emails}
                value={x}
                label="Recipient"
                onChange={(e) =>
                  handleBaseChange(
                    "emails",
                    form.emails.value.map((p, idx) =>
                      idx !== i ? p : e.target.value
                    )
                  )
                }
              />
            ),
            renderActions: () => (
              <IconButton
                onClick={() =>
                  handleBaseChange(
                    "emails",
                    form.emails.value.filter((_, idx) => i !== idx)
                  )
                }
              >
                <Delete />
              </IconButton>
            ),
          }))}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ListView
          title={
            <Title divider>
              <ShoppingBag sx={{ mr: 2 }} /> Subtract from products' quantity
              (this doesn't affect stock)
            </Title>
          }
          items={form.products.value.map((x, i) => ({
            icon: <Avatar src={x.image} />,
            primary: x.name,
            secondary:
              x.quantity > x.order_quantity || x.quantity < 0 ? (
                <span style={{ color: "red" }}>Invalid quantity</span>
              ) : null,
            renderActions: () => (
              <>
                <IconButton
                  onClick={() =>
                    handleBaseChange(
                      "products",
                      form.products.value.map((p, idx) =>
                        i !== idx ? p : { ...p, quantity: p.quantity - 1 }
                      )
                    )
                  }
                >
                  <Remove />
                </IconButton>
                {x.quantity}
                <IconButton
                  onClick={() =>
                    handleBaseChange(
                      "products",
                      form.products.value.map((p, idx) =>
                        i !== idx ? p : { ...p, quantity: p.quantity + 1 }
                      )
                    )
                  }
                >
                  <Add />
                </IconButton>
              </>
            ),
          }))}
        />
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <CustomButton
          disabled={
            !isValid || !order.tracking_number || !order?.shipping_company_id
          }
          fullWidth
          onClick={async () => {
            await onSubmit(formConfig.getFormValues());
          }}
        >
          Submit
        </CustomButton>
      </Grid>
    </Grid>
  );
}
