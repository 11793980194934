import React, { useEffect, useState } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import apiClient from "../apiClient";
import { toApiUrl } from "../utils/generic";
import { Grid } from "@mui/material";
import BackdropLoader from "../components/ui/BackdropLoader";
import { DataArray, Search } from "@mui/icons-material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import GoBackButton from "../components/ui/GoBackButton";
import TableCard from "../components/database/TableCard";
import Input from "../components/forms/Input";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import Title from "../components/ui/Title";
import SelectInput from "../components/forms/SelectInput";
import { exportTypeOptions } from "../components/database/constants";
import RelationSelection from "../components/database/RelationSelection";
import { addNotification } from "../store/actions/notifications";

function DatabasePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [search, setSearch] = useState("");

  const [downloadEntity, setDownloadEntity] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    apiClient
      .get(toApiUrl("/database"))
      .then((res) => {
        setModels(res.data || []);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDownload = (model) => {
    setDownloadEntity({ ...model, exportType: "json", include: {} });
  };

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={isLoading} />
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={"Database"} icon={<DataArray />} />
      </Grid>
      <Grid item md={9} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          isValid
          isTouched
          endIcon={<Search />}
          onChange={(e) => setSearch(e.target.value?.trim())}
          label="Search Database Table"
          value={search}
        />
      </Grid>
      {models
        .filter((model) => {
          return !search || new RegExp(search, "gi").test(model.table);
        })
        .map((model) => (
          <Grid item md={3} xs={12} key={model.table}>
            <TableCard model={model} onDownload={handleDownload} />
          </Grid>
        ))}
      {downloadEntity ? (
        <SimpleDialog
          onConfirm={() => {
            setIsLoading(true);
            apiClient
              .post(toApiUrl(`/database/${downloadEntity.table}`), {
                exportType: downloadEntity.exportType,
                include: downloadEntity.include,
              })
              .then((res) => {
                if (downloadEntity.exportType === "json") {
                  const blob = new Blob([JSON.stringify(res.data)], {
                    type: "application/json",
                  });

                  const url = window.URL.createObjectURL(blob);

                  const a = document.createElement("a");
                  a.href = url;
                  a.download = `${downloadEntity.table}_${Math.random()}.json`;
                  document.body.appendChild(a);
                  a.click();

                  window.URL.revokeObjectURL(url);
                  // setDownloadEntity(null);
                }
              })
              .catch((err) => {
                addNotification("Something went wrong", "error");
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          disableConfirm={isLoading}
          open={!!downloadEntity}
          onCancel={() => setDownloadEntity(null)}
          disableBackdropClick={isLoading}
          title={
            <Title>
              <DataArray /> {downloadEntity?.table}
            </Title>
          }
        >
          <SelectInput
            isValid={!!downloadEntity?.exportType}
            isTouched
            label="Export Type"
            value={downloadEntity?.exportType}
            onChange={(e) =>
              setDownloadEntity((prev) => ({
                ...prev,
                exportType: e.target.value,
              }))
            }
            options={exportTypeOptions}
          />
          <small>
            * Relational Data are only supported on JSON export type
          </small>
          {(downloadEntity?.relations || []).map((relation, idx) => (
            <div key={idx}>
              <RelationSelection
                relation={relation}
                models={models}
                checked={!!downloadEntity?.include?.[relation.alias]}
                value={downloadEntity?.include || {}}
                onChange={(value) => {
                  setDownloadEntity((prev) => ({
                    ...prev,
                    ...value,
                  }));
                }}
              />
            </div>
          ))}
        </SimpleDialog>
      ) : null}
    </Grid>
  );
}

export default withAuth(withRoles(DatabasePage, [ROLES.SUPER_ADMIN]));
