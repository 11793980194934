/* eslint-disable react-hooks/rules-of-hooks */
import {
  Block,
  Check,
  CreditCard,
  Info,
  Label,
  Mail,
  Phone,
  Redeem,
} from "@mui/icons-material";
import { Avatar, Chip, Tooltip } from "@mui/material";
import ActionsMenu from "../dataGrid/ActionsMenu";
import {
  getFinalProductPrice,
  getTotalOrderPrice,
  toFixedDecimal,
  toServerImage,
} from "../../utils/generic";
import OpenProductSupplierPage from "../products/OpenProductSupplierPage";

const orderStatusToColorMap = {
  created: "success",
  paid: "success",
  completed: "primary",
  error: "error",
  canceled: "error",
  refunded: "secondary",
};

export const orderFiltersConfig = [
  {
    name: "id",
    label: "Order ID",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "external_id",
    label: "External ID",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "tracking_number",
    label: "Tracking Number",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 12, xs: 12 },
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "shipping_email",
    label: "Shipping Email",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "vat",
    label: "VAT",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "business_name",
    label: "Business Name",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "name",
    label: "Billing Name",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "last_name",
    label: "Billing Last Name",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "shipping_name",
    label: "Shipping Name",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
  {
    name: "shipping_last_name",
    label: "Shipping Last Name",
    type: "text",
    formatter: (value) => (!value ? undefined : value),
    grid: { md: 6, xs: 12 },
  },
];

export const OrderStatusColumns = [
  {
    name: "name",
    label: "Order Status",
    renderComponent: ({ value }) => (
      <Chip
        color={orderStatusToColorMap[value] || "primary"}
        icon={<Label />}
        label={value}
        size="small"
      />
    ),
  },

  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const PaymentMethodsColumns = [
  {
    name: "image",
    label: "Payment Method",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const ShippingCompaniesColumns = [
  {
    name: "image",
    label: "Shipping Company",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const OrdersColumns = [
  {
    selected: false,
    name: "id",
    label: "ID",
  },
  { name: "external_id", label: "External ID", selected: false },
  {
    selected: false,
    name: "tracking_number",
    label: "Tracking Number",
  },
  {
    selected: true,
    name: "order_status",
    label: "Order Status",
    renderComponent: ({ value }) => (
      <Chip
        color={orderStatusToColorMap?.[value?.name] || "primary"}
        icon={<CreditCard />}
        label={value?.name}
        size="small"
      />
    ),
  },
  {
    selected: true,
    name: "payment_method",
    label: "Payment Method",
    renderComponent: ({ value }) => (
      <Chip icon={<CreditCard />} label={value?.name} size="small" />
    ),
  },
  {
    selected: true,
    name: "emails",
    label: "Emails",
    renderComponent: ({ row }) => (
      <>
        <Chip icon={<Mail />} label={row?.email} size="small" />
        {row?.shipping_email && row.shipping_email !== row?.email ? (
          <Chip icon={<Mail />} label={row?.shipping_email} size="small" />
        ) : null}
      </>
    ),
  },
  {
    selected: true,
    name: "phones",
    label: "Phones",
    renderComponent: ({ row }) => (
      <>
        <Chip icon={<Phone />} label={row?.phone} size="small" />
        {row?.shipping_phone && row.shipping_phone !== row?.phone ? (
          <Chip icon={<Phone />} label={row?.shipping_phone} size="small" />
        ) : null}
      </>
    ),
  },
  {
    selected: true,
    name: "total",
    label: "Price",
    renderComponent: ({ row }) => `${getTotalOrderPrice(row)} €`,
  },
  {
    selected: true,
    name: "shipping",
    label: "Shipping",
    renderComponent: ({ row }) => `${row?.shipping_cost || 0} €`,
  },
  {
    selected: true,
    name: "created_at",
    label: "Created At",
    formatter: (value) => (!value ? "-" : new Date(value).toDateString()),
  },
  {
    name: "sent_at",
    label: "Sent At",
    formatter: (value) => (!value ? "-" : new Date(value).toDateString()),
  },
  {
    name: "returned_at",
    label: "Returned At",
    formatter: (value) => (!value ? "-" : new Date(value).toDateString()),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const OrderProductsColumns = [
  {
    name: "image",
    label: "image",
    renderComponent: ({ row }) => (
      <Avatar src={toServerImage(row?.product?.image)} />
    ),
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ row }) => {
      return <OpenProductSupplierPage product={row?.product} />;
    },
  },
  {
    name: "sku",
    label: "SKU",
    renderComponent: ({ row }) => row?.product?.sku || "-",
  },
  {
    name: "product_price",
    label: "Product Current Price",
    renderComponent: ({ row }) =>
      `${getFinalProductPrice(row.product)} € ${
        row?.product?.discount > 0 ? `(-${row?.product?.discount}%)` : ""
      }`,
  },
  {
    name: "price",
    label: "Price Per Unit",
    renderComponent: ({ row }) =>
      `${getFinalProductPrice({
        price: row.price,
        discount: row.discount,
      })} € ${row?.discount > 0 ? `(-${row?.discount}%)` : ""}`,
  },
  {
    name: "total",
    label: "Price Paid",
    renderComponent: ({ row }) =>
      `${toFixedDecimal(
        getFinalProductPrice({
          price: row.price,
          discount: row.discount,
        }) * row.quantity,
        row.price * row.quantity
      )} € ${row?.discount > 0 ? `(-${row?.discount}%)` : ""}`,
  },
  {
    name: "discount",
    label: "Discount",
    formatter: (value) => `${toFixedDecimal(value, value)}%`,
  },
  {
    name: "quantity",
    label: "Quantity",
  },
  {
    name: "coupon",
    label: "Coupon",
    renderComponent: ({ value }) =>
      !value ? (
        "-"
      ) : (
        <Chip
          icon={<Redeem />}
          label={`${value?.name || "-"} (-${value?.discount}%)`}
        />
      ),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ({ row, actions = [] }) => {
      return (
        <ActionsMenu
          row={row}
          actions={[
            {
              type: "navigate",
              name: "navigate",
              title: "Details",
              icon: <Info />,
              action: (row, navigate) => {
                navigate(`/products/${row.product_id}`);
              },
            },
            ...actions,
          ]}
        />
      );
    },
  },
];
