import React, { useMemo } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { OrdersColumns, orderFiltersConfig } from "./constants";
import { toApiUrl } from "../../utils/generic";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useOrdersStaticData from "../../store/hooks/useOrdersStaticData";
import BackdropLoader from "../ui/BackdropLoader";

export default function Orders({ filters = {}, identifier = "orders" }) {
  const navigate = useNavigate();
  const {
    paymentMethods,
    orderStatuses,
    countries,
    websites,
    loading: isStaticDataLoading,
  } = useOrdersStaticData();

  const orderFiltersConfigMemoized = useMemo(() => {
    return [
      {
        name: "website_id",
        label: "Website",
        grid: { md: 12, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          websites.map((x) => ({
            label: x.domain,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
      {
        name: "order_status_id",
        label: "Order Status",
        grid: { md: 6, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          orderStatuses.map((x) => ({
            label: x.name,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
      {
        name: "payment_method_id",
        label: "Payment Method",
        grid: { md: 6, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          paymentMethods.map((x) => ({
            label: x.name,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },

      {
        name: "country_id",
        label: "Billing Country",
        grid: { md: 4, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          countries.map((x) => ({
            label: x.name,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
      {
        name: "shipping_country_id",
        label: "Shipping Country",
        grid: { md: 4, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          countries.map((x) => ({
            label: x.name,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
      {
        name: "business_country_id",
        label: "Business Country",
        grid: { md: 4, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          countries.map((x) => ({
            label: x.name,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
    ].concat(orderFiltersConfig);
  }, [countries, orderStatuses, paymentMethods, websites]);

  return (
    <>
      <BackdropLoader open={isStaticDataLoading} />
      <TableDataLoader
        withColumnSelection
        defaultOrderBy={"desc"}
        defaultSortingColumnId={"created_at"}
        withFilters
        onRowClick={(row) => navigate(`/orders/${row.id}`)}
        filters={{ order_by: "created_at:desc", ...filters }}
        filtersConfig={orderFiltersConfigMemoized}
        endpoint={toApiUrl("/orders")}
        entityName="results"
        identifier={identifier}
        columns={OrdersColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: (row) => navigate(`/orders/${row.id}`),
          },
        ]}
      />
    </>
  );
}
