import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentsActions } from "../reducers/paymentsReducer";
import axios from "../../apiClient";
import { toPaymentGatewayApiUrl } from "../../utils/generic";
import { useLocation, useNavigate } from "react-router-dom";

export const useIsCheckout = () => {
  const location = useLocation();

  return location?.pathname?.startsWith("/checkout");
};

export const useApplicationTransactionalTimeSeries = async (appId) => {
  const dispatch = useDispatch();
  const fetcher = useCallback(async () => {
    let res;
    try {
      dispatch({ type: PaymentsActions.PAYMENTS_LOADING, payload: true });
      res = await axios.get(
        toPaymentGatewayApiUrl(`/dashboard/application/${appId}`)
      );
      dispatch({
        type: PaymentsActions.SET_APP_DASHBOARD_DATA,
        payload: { appDashboardData: res.data.aggregate },
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PaymentsActions.PAYMENTS_LOADING, payload: false });
      return res?.data?.aggregate;
    }
  }, [appId, dispatch]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return fetcher;
};

export const useTransactionalTimeSeries = async () => {
  const dispatch = useDispatch();
  const fetcher = useCallback(async () => {
    let res;
    try {
      dispatch({ type: PaymentsActions.PAYMENTS_LOADING, payload: true });
      res = await axios.get(toPaymentGatewayApiUrl(`/dashboard`));
      dispatch({
        type: PaymentsActions.SET_DASHBOARD_DATA,
        payload: { dashboardData: res.data.aggregate },
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: PaymentsActions.PAYMENTS_LOADING, payload: false });
      return res?.data?.aggregate;
    }
  }, [dispatch]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return fetcher;
};
export const usePublicCheckoutMetaData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetcher = useCallback(
    async (key) => {
      let res;
      try {
        dispatch({ type: PaymentsActions.PAYMENTS_LOADING, payload: true });
        res = await axios.get(toPaymentGatewayApiUrl(`/checkout/${key}`));
        dispatch({
          type: PaymentsActions.GET_PUBLIC_CHECKOUT_METADATA,
          payload: res.data,
        });
      } catch (error) {
        navigate("/404");
      } finally {
        dispatch({ type: PaymentsActions.PAYMENTS_LOADING, payload: false });
        return res?.data;
      }
    },
    [dispatch, navigate]
  );

  return fetcher;
};

export const useFetchPaymentsMetaData = () => {
  const dispatch = useDispatch();

  const fetchConfig = useCallback(async () => {
    let res;
    try {
      dispatch({ type: PaymentsActions.PAYMENTS_LOADING, payload: true });
      res = await axios.get(toPaymentGatewayApiUrl("/applications/static"));
      dispatch({ type: PaymentsActions.GET_METADATA, payload: res.data });
    } catch (error) {
    } finally {
      dispatch({
        type: PaymentsActions.PAYMENTS_LOADING,
        payload: false,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return fetchConfig;
};

export default function usePayments() {
  return useSelector((state) => state.payments);
}
