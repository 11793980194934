const initialState = {
  metaData: null,
  loading: false,
  publicCheckoutMetaData: null,
  dashboardData: null,
  appDashboardData: null,
};

export const PaymentsActions = {
  PAYMENTS_LOADING: "PAYMENTS_LOADING",
  GET_METADATA: "GET_METADATA",
  GET_PUBLIC_CHECKOUT_METADATA: "GET_PUBLIC_CHECKOUT_METADATA",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  SET_APP_DASHBOARD_DATA: "SET_APP_DASHBOARD_DATA",
};

export const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentsActions.PAYMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PaymentsActions.GET_METADATA:
      return {
        ...state,
        metaData: action.payload,
      };
    case PaymentsActions.GET_PUBLIC_CHECKOUT_METADATA:
      return {
        ...state,
        publicCheckoutMetaData: action.payload,
      };
    case PaymentsActions.SET_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case PaymentsActions.SET_APP_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
