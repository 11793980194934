import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import MainCard from "./MainCard";

const AnalyticsCard = ({
  icon,
  color,
  title,
  count,
  percentage,
  isLoss,
  children,
  onClick,
  titleSx = {},
  sx,
  divider = false,
  elevation,
}) => (
  <MainCard
    contentSX={{
      p: 2.25,
      cursor: onClick ? "pointer" : "inherit",
      overflow: "visible",
      ...sx,
    }}
    onClick={onClick}
    elevation={elevation}
  >
    <Stack spacing={0.5}>
      <Typography
        variant="p"
        color="textSecondary"
        sx={{
          alignItems: "center",
          display: "flex",
          ...titleSx,
        }}
      >
        {icon} {title}
      </Typography>
      {divider ? <Divider /> : null}
      <Grid container alignItems="center">
        <Grid item md={12}>
          <Typography
            variant="h6"
            color="inherit"
            sx={{
              textAlign: "center",
            }}
          >
            {count}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
    <Box sx={{ pt: 2.25 }}>{children}</Box>
  </MainCard>
);

export default AnalyticsCard;
