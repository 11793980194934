import React, { useMemo } from "react";
import Title from "../ui/Title";
import { ArrowRightOutlined } from "@mui/icons-material";
import { Checkbox } from "@mui/material";

export default function RelationSelection({
  models,
  relation,
  checked = false,
  onChange,
  value,
  depth = 0,
}) {
  const resolvedRelations = useMemo(() => {
    if (!checked) {
      return [];
    }

    return (
      models.find((model) => model.table === relation.to_table)?.relations || []
    );
  }, [checked, models, relation]);

  return (
    <div style={{ marginLeft: `${depth * 15}px` }}>
      <Title>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            const checked = e.target.checked;
            const { [relation?.alias]: _, ...rest } = value || {};
            onChange({
              include: {
                ...rest,
                ...(checked && { [relation?.alias]: {} }),
              },
            });
          }}
        />
        {relation.alias} ({relation.from_table} <ArrowRightOutlined />{" "}
        {relation.to_table})
      </Title>
      {resolvedRelations?.length
        ? resolvedRelations.map((r, idx) => (
            <div key={idx}>
              <RelationSelection
                depth={depth + 1}
                value={value?.[relation?.alias]?.include || {}}
                relation={r}
                models={models}
                checked={!!value?.[relation?.alias]?.include?.[r.alias]}
                onChange={(v) => {
                  const val = v.include;
                  const checked = !!val[r.alias];

                  if (checked) {
                    onChange({
                      include: {
                        ...value,
                        [relation?.alias]: {
                          include: {
                            ...value?.[relation?.alias]?.include,
                            [r.alias]: val?.[r.alias],
                          },
                        },
                      },
                    });
                  } else {
                    const { [r.alias]: _, ...rest } = val || {};

                    onChange({
                      include: {
                        ...value,
                        [relation?.alias]: {
                          include: {
                            ...rest,
                          },
                        },
                      },
                    });
                  }

                  return;
                }}
              />
            </div>
          ))
        : null}
    </div>
  );
}
