import { BoardsActions } from "../reducers/boardsReducer";
import axios from "../../apiClient";
import { deriveSafeResponse, toBoardsApiUrl } from "../../utils/generic";
export const setBoardsLoading = (loading) => (dispatch) =>
  dispatch({ type: BoardsActions.loading, payload: loading });

export const getBoards =
  ({ page, view, filters } = {}) =>
  async (dispatch, getState) => {
    let res;
    try {
      const { page: statePage, view: stateView } = getState().boards;
      dispatch(setBoardsLoading(true));
      res = await axios.get(toBoardsApiUrl("/boards"), {
        params: {
          page: page || statePage || 1,
          view: view || stateView || 6,
          ...filters,
        },
      });
      const { results, ...rest } = res.data;
      dispatch({
        type: BoardsActions.getBoars,
        payload: {
          ...rest,
          boards: results || [],
        },
      });
    } catch (error) {
    } finally {
      dispatch(setBoardsLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const getBoard = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(setBoardsLoading(true));
    res = await axios.get(toBoardsApiUrl(`/boards/${id}`));

    dispatch({
      type: BoardsActions.getBoard,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setBoardsLoading(false));
    return deriveSafeResponse(res);
  }
};
