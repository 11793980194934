import React, { useEffect, useMemo, useState } from "react";
import axios from "../../apiClient";
import { getToArrayOrToEmptyArray, toApiUrl } from "../../utils/generic";
export default function useCountries() {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(toApiUrl("/static/countries"))
      .then((res) => {
        if (Array.isArray(res.data.results)) {
          setCountries(res.data.results);
        }
        setLoading(false);
      })
      .catch((err) => {
        setCountries([]);
        setLoading(false);
      });
  }, []);

  const countriesOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(countries).map((country) => ({
      value: country.id,
      label: `${country.name} (${country.code})`,
    }));
  }, [countries]);

  return { countries, loading, countriesOptions };
}
