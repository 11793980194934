import ValidationService from "../../services/ValidationService";
const persistedTheme = localStorage.getItem("themeMode");
const isDrawerOpen = localStorage.getItem("drawerOpen") === "open";
if (
  ValidationService.isNullOrUndefinedOrEmpty(persistedTheme) ||
  !ValidationService.isOneOf({
    value: persistedTheme,
    options: ["dark", "light"],
  })
) {
  localStorage.setItem("themeMode", "light");
}

const initialState = {
  drawerOpen: isDrawerOpen,
  themeMode: localStorage.getItem("themeMode"),
};

export const ThemeActions = {
  TOGGLE_DRAWER: "TOGGLE_DRAWER",
  TOGGLE_THEME: "TOGGLE_THEME",
};

export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ThemeActions.TOGGLE_DRAWER:
      localStorage.setItem("drawerOpen", state.drawerOpen ? "closed" : "open");
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    case ThemeActions.TOGGLE_THEME: {
      const themeMode = ValidationService.isNullOrUndefinedOrEmpty(
        action.payload
      )
        ? state.themeMode === "light"
          ? "dark"
          : "light"
        : action.payload;
      localStorage.setItem("themeMode", themeMode);
      return {
        ...state,
        themeMode,
      };
    }
    default:
      return state;
  }
};
