import { Block, Check, Language, Link } from "@mui/icons-material";
import { Avatar, Chip, Tooltip } from "@mui/material";
import ActionsMenu from "../dataGrid/ActionsMenu";
import { toServerImage } from "../../utils/generic";

export const WebsitesColumns = [
  {
    name: "domain",
    label: "Domain",
    renderComponent: ({ value }) => (
      <Chip icon={<Language />} label={value} size="small" />
    ),
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "updated_at",
    label: "Updated At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const WebsiteSectionsColumns = [
  {
    name: "image",
    label: "image",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
  },
  {
    name: "url",
    label: "URL",
    renderComponent: ({ value }) => (
      <Chip icon={<Link />} label={value} size="small" />
    ),
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const WebsiteSectionPages = {
  home: "home",
  products: "products",
  product: "product",
  checkout: "checkout",
  cart: "cart",
  order_success: "order_success",
  order_error: "order_error",
  not_found: "not_found",
  profile: "profile",
  favorites: "favorites",
  orders: "orders",
  contact: "contact",
  about: "about",
  hero: "hero",
};

export const WebsiteSectionPagesOptions = Object.values(
  WebsiteSectionPages
).map((x) => ({ label: x, value: x }));
