import { Button } from "@mui/material";
import React, { useState } from "react";

const LargeJsonDisplay = ({ json }) => {
  const [visibleData, setVisibleData] = useState(json.slice(0, 500));
  const [showAll, setShowAll] = useState(false);

  const loadMore = () => {
    const currentLength = visibleData.length;
    const nextChunk = json.slice(currentLength, currentLength + 500);
    setVisibleData((prevData) => [...prevData, ...nextChunk]);
  };

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <pre style={{ maxHeight: "500px", overflowY: "auto" }}>
        {showAll ? json : visibleData}
      </pre>
      {!showAll && (
        <Button variant="contained" onClick={loadMore}>
          Load More
        </Button>
      )}
      {/* <button onClick={toggleShowAll}>
        {showAll ? "Show Less" : "Show All"}
      </button> */}
    </div>
  );
};

export default LargeJsonDisplay;
