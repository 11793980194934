import React, { useMemo } from "react";
import useProfileForm from "../profile/useProfileForm";
import { BusinessCenter, Edit, Save } from "@mui/icons-material";
import { Avatar, Button, Container, Grid } from "@mui/material";
import Input from "../forms/Input";
import SelectInput from "../ui/SelectInput";
import Title from "../ui/Title";
import {
  getToArrayOrToEmptyArray,
  toServerImage,
  uploadFile,
} from "../../utils/generic";
import FileImport from "../forms/FileImport";
import BackdropLoader from "../ui/BackdropLoader";
import AnalyticsCard from "../ui/AnalyticsCard";
import useCountries from "../countries/useCountries";
import { register, updateProfile } from "../../store/actions/auth";
import { connect } from "react-redux";
import { ROLES } from "../../data/roles";
import CustomSwitch from "../ui/Switch";
import ValidationService from "../../services/ValidationService";
import useAuth from "../../store/hooks/useAuth";
import useProducts from "../../store/hooks/useProducts";

function UserForm({ loading, user, onSubmit, updateProfile, register, roles }) {
  const {
    form,
    handleChange,
    isValid,
    isTouched,
    getFormValues,
    handleBaseChange,
  } = useProfileForm(user);
  const { user: currentUser } = useAuth();
  const { websites } = useProducts();
  const { countriesOptions, loading: isLoading } = useCountries();

  const websiteOptions = useMemo(() => {
    return [{ value: "", label: "No website" }].concat(
      getToArrayOrToEmptyArray(websites).map((x) => ({
        label: x.domain,
        value: x.id,
      }))
    );
  }, [websites]);
  if (!user) {
    return null;
  }

  const isNew = !user?.id;

  const canEditAccess =
    currentUser?.role?.name === ROLES.SUPER_ADMIN &&
    user?.id !== currentUser?.id;

  return (
    <Container maxWidth="md">
      <BackdropLoader open={loading || isLoading} />
      <AnalyticsCard title="User Details" divider icon={<Edit />}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} display={"flex"} justifyContent={"center"}>
            {form.profile_image.value ? (
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={toServerImage(form.profile_image.value)}
              />
            ) : null}
          </Grid>
          <Grid item md={12} xs={12} display={"flex"} justifyContent={"center"}>
            <FileImport
              label="Upload Profile Image"
              onFileUpload={async (files) => {
                const file = await uploadFile(files);
                if (file) {
                  handleBaseChange("profile_image", file?.filename);
                }
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              {...form.email}
              name="email"
              label="Email"
              onChange={handleChange}
              value={form.email.value}
              disabled={!isNew}
            />
          </Grid>
          {isNew ? (
            <Grid item md={12} xs={12}>
              <Input
                {...form.password}
                name="password"
                label="Password"
                type="password"
                onChange={handleChange}
                value={form.password.value}
              />
            </Grid>
          ) : null}
          <Grid item md={12} xs={12}>
            <SelectInput
              {...form.role_id}
              name="role_id"
              label="Role"
              onChange={handleChange}
              options={roles || []}
              value={form.role_id.value}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <SelectInput
              {...form.website_id}
              name="website_id"
              label="Website"
              onChange={handleChange}
              options={websiteOptions || []}
              value={form.website_id.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.name}
              name="name"
              label="Name"
              onChange={handleChange}
              value={form.name.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.last_name}
              name="last_name"
              label="Last Name"
              onChange={handleChange}
              value={form.last_name.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.phone}
              name="phone"
              label="Phone"
              onChange={handleChange}
              value={form.phone.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.vat}
              name="vat"
              label="Vat Number"
              onChange={handleChange}
              value={form.vat.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectInput
              {...form.country_id}
              name="country_id"
              label="Country"
              onChange={handleChange}
              options={countriesOptions}
              value={form.country_id.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.city}
              name="city"
              label="City/Town"
              onChange={handleChange}
              value={form.city.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.address}
              name="address"
              label="Address"
              onChange={handleChange}
              value={form.address.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.postal_code}
              name="postal_code"
              label="Zip Code"
              onChange={handleChange}
              value={form.postal_code.value}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Title divider sx={{ mb: 2 }}>
              <BusinessCenter /> Business Details
            </Title>
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              {...form.business_name}
              name="business_name"
              label="Business Name"
              onChange={handleChange}
              value={form.business_name.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectInput
              {...form.business_country_id}
              name="business_country_id"
              label="Country"
              onChange={handleChange}
              options={countriesOptions}
              value={form.business_country_id.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.business_address}
              name="business_address"
              label="Address"
              onChange={handleChange}
              value={form.business_address.value}
            />
          </Grid>
          {canEditAccess ? (
            <Grid item>
              <CustomSwitch
                label="Verified"
                checked={form.verified.value}
                name="verified"
                onChange={handleChange}
              />
            </Grid>
          ) : null}
          {canEditAccess ? (
            <Grid item>
              <CustomSwitch
                label="Blocked"
                checked={form.blacklist.value}
                name="blacklist"
                onChange={handleChange}
              />
            </Grid>
          ) : null}
          <Grid item md={12} xs={12} display="flex" justifyContent="center">
            <Button
              disabled={!isValid || !isTouched || loading || isLoading}
              variant="contained"
              startIcon={<Save />}
              onClick={async () => {
                const { password, ...rest } = Object.entries(
                  getFormValues()
                ).reduce((acc, [key, value]) => {
                  if (ValidationService.isUndefined(value)) {
                    return acc;
                  }
                  acc[key] = ValidationService.isNullOrUndefinedOrEmpty(value)
                    ? null
                    : value;
                  return acc;
                }, {});
                const isNew = !rest.id;
                let res;
                if (isNew) {
                  res = await register(
                    { ...rest, password },
                    (roles || []).find((role) => role.value === rest.role_id)
                      ?.label
                  );
                } else {
                  res = await updateProfile(rest, user.id);
                }
                if (onSubmit && res) {
                  await onSubmit(res);
                }
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </AnalyticsCard>
    </Container>
  );
}

export default connect(null, { register, updateProfile })(UserForm);
