import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Payment } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import PaymentMethods from "../components/orders/PaymentMethods";

function PaymentMethodsPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={"Payment Methods"} icon={<Payment />} />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <PaymentMethods />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(PaymentMethodsPage, [ROLES.SUPER_ADMIN]));
