import { useEffect } from "react";
import { useState } from "react";
import axios from "../../apiClient";
import { getToArrayOrToEmptyArray, toApiUrl } from "../../utils/generic";
import { useMemo } from "react";
import { useCallback } from "react";

export const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const fetchSuppliers = useCallback(() => {
    axios
      .get(toApiUrl("/static/suppliers"))
      .then((res) => {
        const data = Array.isArray(res?.data?.results)
          ? res?.data?.results
          : [];
        setSuppliers((prev) => data);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const supplierOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(suppliers).map((supplier) => ({
      value: supplier.id,
      label: `${supplier.email || ""} ${
        supplier?.name
          ? `${supplier?.email && supplier?.name ? "-" : ""}  ${supplier.name}`
          : ""
      }`,
    }));
  }, [suppliers]);

  return { suppliers, supplierOptions, refetchSuppliers: fetchSuppliers };
};
