import { OrderActions } from "../reducers/ordersReducer";
import axios from "../../apiClient";
import { deriveSafeResponse, toApiUrl } from "../../utils/generic";
import { addNotification } from "./notifications";
export const setOrdersLoading = (loading) => (dispatch) =>
  dispatch({ type: OrderActions.OrdersLoading, payload: loading });

export const getOrder = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(setOrdersLoading(true));
    res = await axios.get(toApiUrl(`/orders/${id}`));
    dispatch({ type: OrderActions.GetOrder, payload: res.data });
  } catch (error) {
    dispatch({ type: OrderActions.GetOrder, payload: null });
  } finally {
    dispatch(setOrdersLoading(false));
    return deriveSafeResponse(res);
  }
};

export const getLatestOrders =
  (view = 50, filters = {}) =>
  async (dispatch) => {
    let res;
    try {
      dispatch(setOrdersLoading(true));
      res = await axios.get(toApiUrl(`/orders`), {
        params: { view, order_by: "created_at:desc", ...filters },
      });
      dispatch({ type: OrderActions.GetOrders, payload: res.data });
    } catch (error) {
      dispatch({ type: OrderActions.GetOrders, payload: null });
    } finally {
      dispatch(setOrdersLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const updateOrder = (id, form) => async (dispatch) => {
  let res;
  try {
    dispatch(setOrdersLoading(true));
    res = await axios.put(toApiUrl(`/orders/${id}`), form);
    await dispatch(getOrder(id));
    addNotification("Order updated", "success");
  } catch (error) {
    addNotification("Order failed to be updated", "error");
  } finally {
    dispatch(setOrdersLoading(false));
    return !!res;
  }
};
