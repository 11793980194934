import { io } from "socket.io-client";
import { Config } from "../config";

export const auth = () => ({ token: localStorage.getItem("access_token") });

export const socket = io(Config.WS_URL, {
  autoConnect: false,
  reconnection: true,
  reconnectionAttempts: 10,
  auth: auth(),
});
