import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../apiClient";
import { getToArrayOrToEmptyArray, toApiUrl } from "../../utils/generic";

export const useRoles = (filter = (role) => true) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(toApiUrl("/roles"))
      .then((res) => {
        setRoles(res?.data?.results || res.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setRoles([]);
      });
  }, []);

  const roleOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(roles)
      .filter(filter)
      .map((role) => ({ value: role.id, label: role.name }));
  }, [roles, filter]);

  return { roles, roleOptions, loading };
};

export default function useAuth() {
  return useSelector((state) => state.auth);
}
