import { useState } from "react";
import ValidationService from "../../services/ValidationService";
import useForm from "../../store/hooks/useForm";
import { addNotification } from "../../store/actions/notifications";
import axios from "../../apiClient";
import { deriveSafeResponse, toApiUrl } from "../../utils/generic";

export default function useProductsBulkUpdateForm({
  category_id,
  brand_id,
  labels,
}) {
  const [loading, setLoading] = useState(false);
  const [enabledConfig, setEnabledConfig] = useState({
    fixedPriceStep: false,
    pricePercentageStep: false,
    labels: false,
    discount: false,
    category_id: false,
    brand_id: false,
    enabled: false,
    excludeSelectedProducts: true,
    quantity: false,
    categories: false,
  });
  const formConfig = useForm({
    excludeSelectedProducts: {
      defaultValue: true,
    },
    fixedPriceStep: {
      defaultValue: 0,
      validators: (value) =>
        ValidationService.validateNumber({ value: parseFloat(value) }),
    },
    pricePercentageStep: {
      defaultValue: 0,

      validators: (value) =>
        ValidationService.validateNumber({
          value: parseFloat(value),
          min: -100,
          max: 100,
        }),
    },
    quantity: {
      defaultValue: 0,
      formatter: (value) => {
        if (isNaN(value)) {
          return 0;
        }
        try {
          return parseFloat(value);
        } catch (error) {
          return value;
        }
      },
      validators: (value) =>
        ValidationService.validateNumber({ value: parseInt(value), min: 0 }),
    },
    labels: {
      defaultValue: Array.isArray(labels) ? labels : [],
      validators: (value) =>
        ValidationService.isArrayOfType(value, "string") ||
        (Array.isArray(value) && value.length === 0),
    },
    enabled: {
      defaultValue: true,
    },
    discount: {
      defaultValue: 0,
      formatter: (value) => {
        if (!ValidationService.isNumber(parseFloat(value))) {
          return 0;
        }
        try {
          return parseFloat(parseFloat(value).toFixed(2));
        } catch (error) {
          return value;
        }
      },
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0, max: 100 }),
    },
    category_id: {
      defaultValue: category_id || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    categories: {
      defaultValue: [category_id || ""].filter(Boolean),
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        Array.isArray(value),
    },
    brand_id: {
      defaultValue: brand_id || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
  });

  const onSubmit = async (filters, selectedProducts, setSelectedProducts) => {
    let res;

    try {
      const formattedBody = Object.entries(formConfig.getFormValues()).reduce(
        (acc, [key, value]) => {
          if (enabledConfig[key]) {
            if (key === "excludeSelectedProducts" && value) {
              acc.excludedProductIds = (selectedProducts || []).map(
                (x) => x.id
              );
            }
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      if (!Object.keys(formattedBody)?.length) {
        addNotification("No data were prepared for update", "error");
      }

      setLoading(true);
      res = await axios.put(toApiUrl("/aggregator/products"), formattedBody, {
        params: {
          ...filters,
          ...(selectedProducts?.length && {
            product_ids: selectedProducts.filter(Boolean).map((x) => x.id),
          }),
        },
      });

      addNotification(res.data.message, "success");
      formConfig.resetForm({ category_id, brand_id, labels, categories: [] });
      setEnabledConfig({
        fixedPriceStep: false,
        pricePercentageStep: false,
        labels: false,
        discount: false,
        category_id: false,
        brand_id: false,
        excludeSelectedProducts: true,
        enabled: false,
        quantity: false,
        categories: false,
      });
      if (setSelectedProducts) {
        setSelectedProducts([]);
      }
    } catch (error) {
      addNotification("Operation failed", "error");
    } finally {
      setLoading(false);
      return deriveSafeResponse(res);
    }
  };

  const isValid =
    Object.entries(enabledConfig).some(
      ([key, value]) => key !== "excludeSelectedProducts" && value
    ) &&
    Object.entries(enabledConfig).every(
      ([key, value]) => !value || (value && formConfig.form?.[key]?.isValid)
    );
  return {
    formConfig,
    isValid,
    onSubmit,
    loading,
    setLoading,
    setEnabledConfig,
    enabledConfig,
  };
}
