import React, { useState } from "react";
import CustomButton from "../ui/Button";
import SimpleDialog from "../dialogs/SimpleDialog";
import {
  Block,
  CheckCircle,
  ChevronRight,
  CreditCard,
  Delete,
  Info,
  Mail,
  Settings,
} from "@mui/icons-material";
import Title from "../ui/Title";
import Message from "../ui/Message";
import CompleteOrderForm from "./CompleteOrderForm";
import { toApiUrl } from "../../utils/generic";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import BackdropLoader from "../ui/BackdropLoader";
import ListView from "../ui/ListView";
import { IconButton } from "@mui/material";
import ResendCompleteOrderEmailForm from "./ResendCompleteOrderEmailForm";
import ResendOrderConfirmationEmailForm from "./ResendOrderConfirmationEmailForm";
import CancelOrderForm from "./CancelOrderForm";
import DeleteOrderForm from "./DeleteOrderForm";
import { useNavigate } from "react-router-dom";

const orderStatusToActionsMap = {
  error: ["delete"],
  canceled: ["delete"],
  created: [
    "complete",
    "send-order-email",
    "send-complete-order-email",
    "cancel",
  ],
  paid: ["complete", "send-order-email", "cancel"],
  completed: ["send-order-email", "send-complete-order-email", "cancel"],
};

const selectedActionToInfoMap = {
  delete: {
    title: (
      <Title>
        <Delete sx={{ mr: 2 }} /> Delete Order
      </Title>
    ),
    description: `This action will permanently Delete the selected order. Any product associated with this order will have its quantity modified based on the selected options provided by you.`,
  },
  refund: {
    title: (
      <Title>
        <CreditCard sx={{ mr: 2 }} /> Refund Order
      </Title>
    ),
    description: `This action is available only for instant payment methods and it will refund the amount specified in the amount field  to the associated payment account with respect to its provder (paypal/stripe). `,
  },
  cancel: {
    title: (
      <Title>
        <Block sx={{ mr: 2 }} /> Cancel Order
      </Title>
    ),
    description: `This action will mark the selected order as "canceled". Any product associated with this order will have its quantity modified based on the current state of the order with respect to the selected options provided by you.`,
  },
  complete: {
    title: (
      <Title>
        <CheckCircle sx={{ mr: 2 }} /> Complete Order
      </Title>
    ),
    description: `This action will mark the selected order as "completed". Any product associated with this order will have its quantity modified based on the selected options provided by you. You can also assign recipients for receiving the "Order Sent" mail`,
  },
  "send-order-email": {
    title: (
      <Title>
        <Mail sx={{ mr: 2 }} /> Resend Order Confirmation Mail
      </Title>
    ),
    description: `This action will send the same email that's been sent by the system during checkout completion`,
  },
  "send-complete-order-email": {
    title: (
      <Title>
        <Mail sx={{ mr: 2 }} /> Resend Order Completed Mail
      </Title>
    ),
    description: `This action will notify the specified email addresses that the order has been sent. The content of the email is the same as the one being sent by the complete order action.`,
  },
};

export default function ManageOrderStateButton({ order, onSubmit }) {
  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const orderStatus = order?.order_status?.name;
  const paymentMethod = order?.payment_method?.name;

  const actions = orderStatusToActionsMap[orderStatus];

  if (!actions || !actions?.length) {
    return null;
  }

  const renderTitle = () =>
    selectedAction ? (
      selectedActionToInfoMap[selectedAction]?.title
    ) : (
      <Title>
        <Settings sx={{ mr: 2 }} /> Manage Order State{" "}
      </Title>
    );

  const renderSelectedActionInfo = () => {
    const description =
      selectedActionToInfoMap[selectedAction]?.description || null;
    if (!description) {
      return null;
    }
    return <Message message={description} />;
  };

  const getActionConfig = (action) => {
    switch (action) {
      case "complete":
        return {
          action,
          icon: <CheckCircle />,
          primary: "Complete Order",
        };
      case "delete":
        return {
          action,
          icon: <Delete />,
          primary: "Delete Order",
        };
      case "cancel":
        return {
          action,
          icon: <Block />,
          primary: "Cancel Order",
        };
      case "refund":
        return {
          action,
          icon: <CreditCard />,
          primary: "Refund Order",
          disabled: ["paypal", "stripe"].indexOf(paymentMethod) === -1,
        };
      case "send-order-email":
        return {
          action,
          icon: <Mail />,
          primary: "Resend Order Confirmation Mail",
        };
      case "send-complete-order-email":
        return {
          action,
          icon: <Mail />,
          primary: "Resend Order Completed Mail",
        };
      default:
        return null;
    }
  };

  const renderActions = (actions) => {
    if (!actions?.length) {
      return null;
    }

    return (
      <ListView
        button
        title={
          <Title divider sx={{ my: 2 }}>
            <Settings sx={{ mr: 2 }} /> Available Actions
          </Title>
        }
        items={actions
          .map(getActionConfig)
          .filter(Boolean)
          .map((config) => {
            return {
              ...config,
              renderActions: () => (
                <IconButton
                  disabled={config.disabled}
                  onClick={() => setSelectedAction(config.action)}
                >
                  <ChevronRight />
                </IconButton>
              ),
            };
          })}
      />
    );
  };

  const handleSubmit = async (form) => {
    try {
      setLoading(true);
      await axios.post(toApiUrl("/orders/order-state"), {
        id: order.id,
        command: selectedAction,
        payload: form,
      });
      setLoading(false);
      addNotification("Action Completed", "success");
      if (selectedAction === "delete") {
        navigate("/orders");
      } else if (onSubmit) {
        await onSubmit();
      }
      setSelectedAction(null);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      addNotification(
        error?.response?.data?.message || "Action Failed",
        "error"
      );
    }
  };

  const renderSelectedActionForm = () => {
    switch (selectedAction) {
      case "complete":
        return <CompleteOrderForm order={order} onSubmit={handleSubmit} />;
      case "send-complete-order-email":
        return (
          <ResendCompleteOrderEmailForm order={order} onSubmit={handleSubmit} />
        );
      case "send-order-email":
        return (
          <ResendOrderConfirmationEmailForm
            order={order}
            onSubmit={handleSubmit}
          />
        );
      case "cancel":
        return <CancelOrderForm order={order} onSubmit={handleSubmit} />;
      case "delete":
        return <DeleteOrderForm order={order} onSubmit={handleSubmit} />;
      default:
        return null;
    }
  };

  return (
    <>
      <BackdropLoader open={loading} />
      <CustomButton startIcon={<Settings />} onClick={() => setOpen(true)}>
        Manage Order State
      </CustomButton>
      <SimpleDialog
        title={renderTitle()}
        open={open}
        onCancel={() =>
          selectedAction ? setSelectedAction(null) : setOpen(false)
        }
        cancel
        disableBackdropClick
        disableConfirm={true}
        cancelButtonTitle={selectedAction ? "Back" : "Cancel"}
      >
        {!selectedAction ? (
          <div>{renderActions(actions)}</div>
        ) : (
          <>
            <div>{renderSelectedActionInfo()}</div>
            <div>{renderSelectedActionForm()}</div>
          </>
        )}
      </SimpleDialog>
    </>
  );
}
