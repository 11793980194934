import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Message from "./Message";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export default function SortableList({
  onReorder,
  items,
  dense,
  button,
  title,
  sx,
  fallbackMessage = <Message message="No items at the moment" />,
  secondarySx,
  children,
}) {
  const ListItemComponent = button ? ListItemButton : ListItem;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const data = reorder(
      [...items],
      result.source.index,
      result.destination.index
    );

    onReorder(data);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            dense={dense}
            sx={{
              bgcolor: "background.paper",
              p: 2,
              ...sx,
            }}
            subheader={
              title ? (
                <ListSubheader disableSticky component="div">
                  {title}
                </ListSubheader>
              ) : undefined
            }
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {!items?.length && fallbackMessage ? fallbackMessage : null}

            {(items || []).map((item, index) => (
              <Draggable
                key={`${index}`}
                draggableId={`${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <ListItemComponent
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={item?.onClick}
                    divider={item.divider}
                    key={index}
                  >
                    {item.icon ? (
                      <ListItemAvatar>{item.icon}</ListItemAvatar>
                    ) : null}
                    <ListItemText
                      secondaryTypographyProps={secondarySx}
                      secondary={item.secondary}
                    >
                      {item.primary}
                    </ListItemText>
                    {item.renderActions ? (
                      <ListItemSecondaryAction>
                        {item.renderActions()}
                      </ListItemSecondaryAction>
                    ) : null}
                  </ListItemComponent>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
