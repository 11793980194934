import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { dataReducer } from "./dataReducer";
import { languagesReducer } from "./languagesReducer";
import { themeReducer } from "./themeReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { paymentsReducer } from "./paymentsReducer";
import { aggregatorReducer } from "./aggregateReducer";
import { productReducer } from "./productReducer";
import { ordersReducer } from "./ordersReducer";
import { boardsReducer } from "./boardsReducer";

export const rootReducer = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  languages: languagesReducer,
  data: dataReducer,
  notifications: toastrReducer,
  payments: paymentsReducer,
  aggregate: aggregatorReducer,
  products: productReducer,
  orders: ordersReducer,
  boards: boardsReducer,
});
