import React from "react";
import useProductForm from "./useProductForm";
import ProductForm from "./ProductForm";
import CustomButton from "../ui/Button";
import { Numbers, Save, Visibility } from "@mui/icons-material";
import { Chip, Grid, Rating, Tooltip } from "@mui/material";
import GoBackButton from "../ui/GoBackButton";
import { addNotification } from "../../store/actions/notifications";
import OpenProductSupplierPage from "./OpenProductSupplierPage";
import { toFixedDecimal } from "../../utils/generic";

export default function EditProductForm({
  product,
  isDuplicateMode,
  onDuplicateSave,
  onSuccess,
}) {
  const { formConfig, setLoading, loading, onSubmit } = useProductForm(product);

  const handleCopyID = async () => {
    try {
      await navigator.clipboard.writeText(product.id);
      addNotification("Product ID Copied");
    } catch (error) {}
  };

  return (
    <Grid container spacing={2}>
      {!isDuplicateMode ? (
        <Grid item>
          <GoBackButton to="/products/account" />
        </Grid>
      ) : null}
      <Grid item>
        <CustomButton
          disabled={
            loading ||
            (!isDuplicateMode &&
              (!formConfig.isValid || !formConfig.isTouched)) ||
            (isDuplicateMode && !formConfig.isValid)
          }
          startIcon={<Save />}
          onClick={async () => {
            const res = await onSubmit();
            if (res && onDuplicateSave) {
              await onDuplicateSave(res);
            }
            if (res && onSuccess) {
              await onSuccess();
            }
          }}
        >
          Save
        </CustomButton>
      </Grid>
      {!isDuplicateMode && product ? (
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip
            title={`${toFixedDecimal(
              product?.reviews_aggregate?.avg?.rating || 0
            )}`}
          >
            <Rating
              readOnly
              precision={0.01}
              value={toFixedDecimal(
                product?.reviews_aggregate?.avg?.rating || 0
              )}
            />
          </Tooltip>
          ({toFixedDecimal(product?.reviews_aggregate?.avg?.rating || 0)} in{" "}
          {product?.reviews_aggregate?.count || 0} reviews)
        </Grid>
      ) : null}
      <Grid item md={12} xs={12}></Grid>
      {product?.id ? (
        <Grid item>
          <Chip label={product.id} icon={<Numbers />} onClick={handleCopyID} />
        </Grid>
      ) : null}
      {!isDuplicateMode ? (
        <Grid item>
          <OpenProductSupplierPage product={product} />
        </Grid>
      ) : null}
      {product?.id ? (
        <Grid item>
          <Tooltip title="Views">
            <Chip
              color="primary"
              variant="outlined"
              label={product.views || 0}
              icon={<Visibility />}
            />
          </Tooltip>
        </Grid>
      ) : null}
      <Grid item md={12} xs={12}>
        <ProductForm
          {...formConfig}
          setLoading={setLoading}
          isLoading={loading}
        />
      </Grid>
    </Grid>
  );
}
