import React from "react";
import Title from "../ui/Title";
import { Grid } from "@mui/material";
import CustomButton from "../ui/Button";

export default function ResendOrderConfirmationEmailForm({ order, onSubmit }) {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Title sx={{ my: 2 }} divider>
          Are your sure about this action?
        </Title>
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <CustomButton
          fullWidth
          onClick={async () => {
            await onSubmit({});
          }}
        >
          Submit
        </CustomButton>
      </Grid>
    </Grid>
  );
}
