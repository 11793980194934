import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import VerifyEmailPage from "../pages/VerifyEmailPage";
import { authenticate, logout } from "../store/actions/auth";
import useAuth from "../store/hooks/useAuth";
import BackdropLoader from "../components/ui/BackdropLoader";
import { refreshAccessToken } from "../apiClient";
import { socket } from "../sockets";
import { NotificationTypes } from "../components/notifications/constants";
import { addNotification } from "../store/actions/notifications";
import { getDashboardaggregatedData } from "../store/actions/aggregate";

function playMusic(musicUrl) {
  try {
    const audio = new Audio(musicUrl);
    audio.play().catch((err) => {
      console.log(err);
    });
  } catch (error) {}
}
function withAuth(Component, type = "HARD") {
  const WrappedComponent = Component;
  return (props) => {
    const { user, isAuthenticated, loading } = useAuth();
    const [socketConnected, setSocketConnected] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    useEffect(() => {
      if (isAuthenticated) {
        return;
      }
      const token = localStorage.getItem("access_token");
      switch (type) {
        case "HARD": {
          if (!token) {
            dispatch(logout());
            navigate("/login");
            return;
          }
          dispatch(authenticate(navigate));
          break;
        }
        case "SOFT": {
          if (token) {
            dispatch(authenticate(navigate));
          }
          break;
        }
        default:
          dispatch(logout());
          navigate("/login");
          return;
      }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
      const interval = setInterval(() => {
        const refresh_token = localStorage.getItem("refresh_token");
        if (!refresh_token) {
          return;
        }
        refreshAccessToken(refresh_token)
          .then((access_token) => {
            socket.auth.token = access_token;
            socket.disconnect().connect();
          })
          .catch((err) => {
            logout();
          });
      }, 5 * 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }, []);

    useEffect(() => {
      if (!isAuthenticated) {
        return;
      }
      socket.auth.token = localStorage.getItem("access_token");
      socket.connect();

      setSocketConnected((prev) => true);
      socket.on(NotificationTypes.notification, ({ data }) => {
        playMusic("/notification.wav");
        addNotification(
          data?.title || "New Notification",
          data?.status || "info"
        );
      });
      socket.on(NotificationTypes.notification_announcement, ({ data }) => {
        playMusic("/notification.wav");
        addNotification(
          data?.title || "New Notification",
          data?.status || "info"
        );
      });
      socket.on(NotificationTypes.user_register_notification, ({ data }) => {
        playMusic("/notification.wav");
        addNotification(
          data?.title || "New Notification",
          data?.status || "info"
        );
        dispatch(getDashboardaggregatedData());
      });
      socket.on(NotificationTypes.sales_notification, ({ data }) => {
        playMusic("/sales.mp3");
        addNotification(
          data?.title || "New Notification",
          data?.status || "info"
        );
      });
      socket.io.on("reconnect", () => {
        setSocketConnected((prev) => true);
        socket.emit("join", user?.id);
      });
      socket.io.on("reconnect_error", (err) => {
        setSocketConnected((prev) => false);
      });
      socket.io.on("error", (err) => {
        console.log(err);
      });
      socket.emit("join", user?.id);
      return () => {
        setSocketConnected((prev) => false);
        socket.disconnect();
        socket.removeAllListeners();
      };
    }, [isAuthenticated, user, dispatch]);

    if (loading) {
      return <BackdropLoader open={loading} />;
    }

    if (type === "HARD" && !token) {
      return null;
    }

    if (isAuthenticated && !user?.verified) {
      return <VerifyEmailPage />;
    }
    if (!isAuthenticated && type !== "SOFT") {
      return <BackdropLoader open={true} />;
    }
    return (
      <>
        <WrappedComponent
          {...props}
          user={user}
          socket={socket}
          socketConnected={socketConnected}
        />
      </>
    );
  };
}

export default withAuth;
