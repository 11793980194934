import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Message from "./Message";

export default function ListView({
  items,
  dense,
  button,
  title,
  sx,
  fallbackMessage = <Message message="No items at the moment" />,
  secondarySx,
  children,
  itemSX,
}) {
  const ListItemComponent = button ? ListItemButton : ListItem;

  return (
    <List
      dense={dense}
      sx={{
        bgcolor: "background.paper",
        p: 2,
        ...sx,
      }}
      subheader={
        title ? (
          <ListSubheader disableSticky component="div">
            {title}
          </ListSubheader>
        ) : undefined
      }
    >
      {!items?.length && fallbackMessage ? fallbackMessage : null}

      {(items || []).map((item, index) => (
        <ListItemComponent
          onClick={item?.onClick}
          divider={item.divider}
          key={index}
          sx={itemSX}
        >
          {item.icon ? <ListItemAvatar>{item.icon}</ListItemAvatar> : null}
          <ListItemText
            secondaryTypographyProps={secondarySx}
            secondary={item.secondary}
          >
            {item.primary}
          </ListItemText>
          {item.renderActions ? (
            <ListItemSecondaryAction>
              {item.renderActions()}
            </ListItemSecondaryAction>
          ) : null}
        </ListItemComponent>
      ))}
      {children}
    </List>
  );
}
