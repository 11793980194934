import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { InventoryColumns } from "./constants";
import { toApiUrl } from "../../utils/generic";
import { Delete, Edit, Info } from "@mui/icons-material";

import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";

import { useNavigate } from "react-router-dom";
import useInventoryForm from "./useInventoryForm";

import ProductInventoryView from "./ProductInventoryView";
import { useSuppliers } from "./useSuppliers";

export default function Inventory({ url = "/inventory" }) {
  const { supplierOptions } = useSuppliers();
  const [entity, setEntity] = useState(null);
  const navigate = useNavigate();
  const { formConfig, onSubmit, loading: isFormLoading } = useInventoryForm();
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm(obj);
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("inventory");

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/inventory/${row.id}`));
      addNotification("Action Completed", "success");
      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification("Action Failed", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const inventoryFiltersConfigMemoized = useMemo(() => {
    return [
      { name: "name", label: "Product Name", type: "text" },
      {
        name: "supplier_id",
        label: "Supplier",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(supplierOptions),
        formatter: (value) => (value === "All" ? undefined : value),
      },
    ];
  }, [supplierOptions]);

  return (
    <>
      <TableDataLoader
        filtersConfig={inventoryFiltersConfigMemoized}
        withFilters
        filters={{ order_by: "created_at:desc" }}
        endpoint={toApiUrl(url)}
        entityName="results"
        identifier="inventory"
        columns={InventoryColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "viewProduct",
            title: "Product Details",
            icon: <Info />,
            action: (row) => navigate(`/products/${row.product?.id}`),
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
      />
      <SimpleDialog
        maxWidth="lg"
        onCancel={closeDialog}
        onConfirm={async () => {
          const res = await onSubmit();
          if (res) {
            getData(dataSlice?.page, dataSlice?.view);
            closeDialog();
          }
        }}
        title={
          <Title>
            <Edit /> {formConfig.form.id.value ? "Edit" : "Enter"} Inventory
            Item
          </Title>
        }
        disableConfirm={
          !formConfig.isValid ||
          !formConfig.isTouched ||
          dataSlice?.loading ||
          isFormLoading
        }
        open={!!entity}
      >
        {/* <InventoryForm
          {...formConfig}
          isLoading={isFormLoading}
          onSubmit={onSubmit}
        /> */}
        <ProductInventoryView
          inventory={entity}
          onSubmit={async () => {
            closeDialog();
            await getData(dataSlice?.page, dataSlice?.view);
          }}
        />
      </SimpleDialog>
    </>
  );
}
