export const Config = {
  WS_URL:
    process.env.NODE_ENV === "development"
      ? "ws://localhost:8000/"
      : "https://admin.trendstore.gr/",
  API_BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1"
      : "https://admin.trendstore.gr/api/v1",
  BOARDS_BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8006/api/v1"
      : "https://boards.trendstore.gr/api/v1",
  TRACKING_BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8005/api/v1"
      : "https://tracking.trendstore.gr/api/v1",
  TRANSACTION_SERVICE_BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8004/api/v1"
      : "https://payments.trendstore.gr/api/v1",
  STATIC_FILES_UPLOAD_URL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8001/file-server/upload"
      : "https://files.trendstore.gr/file-server/upload",
  STATIC_FILES_SERVER_URL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8001/file-server/files"
      : "https://files.trendstore.gr/file-server/files",
  QR_DOMAIN:
    process.env.NODE_ENV === "development"
      ? `http://127.0.0.1:8000/api/v1`
      : `https://admin.trendstore.gr/api/v1`,
  SUPPLIERS_BASE_URL:
    process.env.NODE_ENV === "development"
      ? `http://127.0.0.1:5000/api/v1`
      : `https://suppliers.trendstore.gr/api/v1`,
};
