import { useState } from "react";
import ValidationService from "../../services/ValidationService";
import useForm from "../../store/hooks/useForm";
import { addNotification } from "../../store/actions/notifications";
import axios from "../../apiClient";
import { deriveSafeResponse, toApiUrl } from "../../utils/generic";

const useInventoryForm = (inventory) => {
  const [loading, setLoading] = useState(false);
  const formConfig = useForm({
    id: {
      defaultValue: inventory?.id,
    },
    product: {
      defaultValue: inventory?.product,
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
    },
  });
  const onSubmit = async () => {
    setLoading(true);
    let res;
    try {
      const { id, product } = formConfig.getFormValues();
      const url = id ? `/inventory/${id}` : "/inventory";
      const handler = id ? axios.put : axios.post;
      res = await handler(toApiUrl(url), { product_id: product.id });
      addNotification("Action completed", "success");
    } catch (error) {
      addNotification("Action Failed", "error");
    } finally {
      setLoading(false);
      return deriveSafeResponse(res);
    }
  };

  return { formConfig, loading, onSubmit, setLoading };
};

export default useInventoryForm;
