import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import {
  ProductSuperCategoriesColumns,
  productSuperCategoriesFiltersConfig,
} from "./constants";
import {
  getToArrayOrToEmptyArray,
  toApiUrl,
  toServerImage,
  uploadFile,
} from "../../utils/generic";
import {
  Add,
  ArrowRight,
  Delete,
  Edit,
  Label,
  Reorder,
} from "@mui/icons-material";
import CustomButton from "../ui/Button";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import FileImport from "../forms/FileImport";
import { Avatar, IconButton } from "@mui/material";
import Message from "../ui/Message";
import { useNavigate, useParams } from "react-router-dom";
import useProducts from "../../store/hooks/useProducts";
import SortableList from "../ui/SortableList";
import withSelectionWrapper from "../dataGrid/SelectionWrapper";
import SelectInput from "../forms/SelectInput";

function ProductSuperCategories({
  onSelectItems,
  selectedItems,
  identifier = "product_super_categories",
}) {
  const navigate = useNavigate();
  const { group_id } = useParams();
  const { websites, groups } = useProducts();
  const [entity, setEntity] = useState(null);
  const [reorderConfig, setReorderConfig] = useState(null);

  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    description: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 2, max: 1000 }),
    },
    groups: {
      defaultValue: [group_id || ""].filter(Boolean),
      validators: (value) => Array.isArray(value),
    },
    websites: {
      defaultValue: [],
      validators: (value) => Array.isArray(value),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
    image: {
      defaultValue: "",
      validators: (value) => true,
    },
  });
  const websiteOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(websites).map((website) => ({
      value: website.id,
      label: website.domain,
    }));
  }, [websites]);

  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      name: obj?.name || "",
      description: obj?.description || "",
      enabled: !!obj?.enabled,
      image: obj?.image || "",
      groups: Array.isArray(obj?.groups)
        ? obj.groups.map((x) =>
            ValidationService.isObject(x) ? x?.group_id : x
          )
        : [],
      websites: Array.isArray(obj?.websites)
        ? obj.websites.map((x) =>
            ValidationService.isObject(x) ? x?.website_id : x
          )
        : [],
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice(identifier);

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { name, id, description, enabled, image, groups, websites } =
      formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/product-super-categories"), {
          name,
          enabled,
          description,
          image,
          groups,
          websites,
        });
      } else {
        res = await axios.put(toApiUrl(`/product-super-categories/${id}`), {
          name,
          enabled,
          description,
          image,
          groups,
          websites,
        });
      }
      addNotification(
        `Product Super Category ${id ? "Updated" : "Created"}`,
        "success"
      );

      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification(
        `Product Super Category was not ${id ? "Updated" : "Created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/product-super-categories/${row.id}`));
      addNotification("Product Super Category Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view, { groups: group_id });
    } catch (error) {
      addNotification("Product Super Category was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const groupsOptions = useMemo(() => {
    return Array.isArray(groups)
      ? groups.map((x) => ({ label: x.name, value: x.id }))
      : [];
  }, [groups]);

  const websitesOptions = useMemo(() => {
    return Array.isArray(websites)
      ? websites.map((x) => ({ label: x.domain, value: x.id }))
      : [];
  }, [websites]);

  const { form, handleChange } = formConfig;

  const productSuperCategoriesFiltersConfigMemoized = useMemo(() => {
    if (group_id) {
      return productSuperCategoriesFiltersConfig.concat([
        {
          name: "websites",
          label: "Website",
          grid: { md: 12, xs: 12 },
          value: undefined,
          type: "select",
          options: [{ label: "All", value: "All" }].concat(
            (websites || []).map((x) => ({
              label: x.domain,
              value: x.id,
            }))
          ),
          formatter: (value) => (value === "All" ? undefined : value),
        },
      ]);
    }

    return [
      {
        name: "websites",
        label: "Website",
        grid: { md: 12, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (websites || []).map((x) => ({
            label: x.domain,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
      {
        isDisabled: (filters) => filters?.ngroup,
        name: "groups",
        label: "Group",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (groups || []).map((x) => ({
            label: x.name,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
      {
        name: "ngroup",
        label: "No Group",
        value: undefined,
        type: "checkbox",
        onChange: (checked) =>
          !checked
            ? {}
            : {
                group_id: undefined,
              },
      },
    ].concat(productSuperCategoriesFiltersConfig);
  }, [group_id, groups, websites]);

  return (
    <>
      <TableDataLoader
        defaultOrderBy="asc"
        defaultSortingColumn="name"
        withColumnSelection
        withFilters
        filtersConfig={productSuperCategoriesFiltersConfigMemoized}
        filters={{ groups: group_id, order_by: "name:asc" }}
        onSelect={onSelectItems}
        selectionId="id"
        def
        endpoint={toApiUrl("/product-super-categories")}
        entityName="results"
        identifier={identifier}
        columns={ProductSuperCategoriesColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "edit_categories",
            title: "Categories",
            icon: <Label />,
            action: (row) =>
              navigate(`/products/supercategories/${row.id}/categories`),
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {selectedItems?.length > 0 ? (
                <IconButton>
                  <Edit />
                </IconButton>
              ) : null}
              <IconButton
                onClick={() =>
                  openDialogForm({
                    name: "",
                    groups: [group_id].filter(Boolean),
                    websites: [],
                  })
                }
                sx={{ mr: 2 }}
              >
                <Add />
              </IconButton>
              <IconButton
                variant="outlined"
                disabled={!dataSlice?.data?.length}
                onClick={async () => {
                  try {
                    setLoading(true);
                    const { data } = await axios.get(
                      toApiUrl("/static/product-super-categories"),
                      { params: { groups: group_id } }
                    );
                    setReorderConfig({
                      entity: "product_super_categories",
                      items: (data?.results || []).map((x) => ({
                        ...x,
                        primary: <Avatar src={toServerImage(x.image)} />,
                        secondary: x.name,
                        icon: <Reorder />,
                        divider: true,
                      })),
                    });
                  } catch (error) {
                    setReorderConfig(null);
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <Reorder />
              </IconButton>
            </div>
          );
        }}
      />

      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Product Super Category
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        {!websiteOptions.length ? (
          <>
            <Message message="No websites were created yet. You have to register at least one website in order to start creating product super categories" />
            <CustomButton
              fullWidth
              endIcon={<ArrowRight />}
              onClick={() => navigate("/websites")}
            >
              Go To Websites
            </CustomButton>
          </>
        ) : (
          <>
            {" "}
            {form.image.value ? (
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={toServerImage(form.image.value)}
              />
            ) : null}
            <FileImport
              label="Upload Super Category Image"
              onFileUpload={async (files) => {
                setLoading(true);
                const file = await uploadFile(files);
                if (file) {
                  formConfig.handleBaseChange("image", file?.filename);
                }
                setLoading(false);
              }}
            />
            <CustomSwitch
              name="enabled"
              label="Status"
              checked={form.enabled.value}
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.name}
              name="name"
              label="Name"
              value={form.name.value}
              onChange={handleChange}
            />
            <SelectInput
              {...form.groups}
              value={form.groups.value || []}
              label="Groups"
              onChange={handleChange}
              options={groupsOptions}
              autoComplete
              multiple
              sx={{ my: 2 }}
            />
            <SelectInput
              {...form.websites}
              value={form.websites.value || []}
              label="Websites"
              onChange={handleChange}
              options={websitesOptions}
              autoComplete
              multiple
              sx={{ my: 2 }}
            />
            <Input
              multiline
              rows={5}
              sx={{ my: 2 }}
              {...form.description}
              name="description"
              label="Description"
              value={form.description.value}
              onChange={handleChange}
            />
          </>
        )}
      </SimpleDialog>
      <SimpleDialog
        title={
          <Title>
            <Reorder sx={{ mr: 2 }} /> Reorder Super Categories
          </Title>
        }
        open={!!reorderConfig}
        onCancel={() => setReorderConfig(null)}
        onConfirm={async () => {
          try {
            setLoading(true);
            await axios.put(toApiUrl("/aggregator/reorder"), {
              entity: reorderConfig.entity,
              ids: reorderConfig.items.map(({ id }) => id),
            });
            await getData(dataSlice?.page, dataSlice?.view, {
              groups: group_id,
            });
            setReorderConfig(null);
            addNotification("Action completed", "success");
          } catch (error) {
            addNotification("Action failed", "error");
          } finally {
            setLoading(false);
          }
        }}
      >
        {!!reorderConfig ? (
          <SortableList
            items={reorderConfig?.items}
            onReorder={(items) =>
              setReorderConfig((prev) => ({ ...prev, items }))
            }
          />
        ) : null}
      </SimpleDialog>
    </>
  );
}

export default withSelectionWrapper(ProductSuperCategories);
