import {
  ArticleOutlined,
  BrandingWatermark,
  Category,
  CreditCard,
  Dashboard,
  DataArray,
  Inventory,
  Label,
  Language,
  LocalShipping,
  Login,
  Mail,
  MemoryOutlined,
  Notifications,
  PeopleAlt,
  Person,
  Person2Outlined,
  PersonAdd,
  Redeem,
  ShoppingBag,
  ShoppingCart,
} from "@mui/icons-material";
import ValidationService from "../services/ValidationService";
import { ROLES } from "./roles";

export const NavigationData = [
  {
    title: "Dashboard",
    to: "/",
    icon: <Dashboard />,
    shouldRender: (role, isAuthenticated) => isAuthenticated,
  },
  {
    title: "Manage Products",
    icon: <ShoppingBag />,
    shouldRender: (role, isAuthenticated) => true,
    children: [
      {
        title: "Products",
        to: "/products/account",
        icon: <ShoppingBag />,
        shouldRender: (role, isAuthenticated) => true,
      },
      {
        title: "Groups",
        to: "/products/groups",
        icon: <Label />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Super Categories",
        to: "/products/supercategories",
        icon: <Label />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Categories",
        to: "/products/categories",
        icon: <Label />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Brands",
        to: "/products/brands",
        icon: <BrandingWatermark />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Attributes",
        to: "/products/attribute-categories",
        icon: <Category />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
    ],
  },
  {
    title: "Manage Orders",
    icon: <ShoppingCart />,
    shouldRender: (role, isAuthenticated) => true,
    children: [
      {
        title: "Orders",
        to: "/orders",
        icon: <ShoppingCart />,
        shouldRender: (role, isAuthenticated) => true,
      },
      {
        title: "Order Statuses",
        to: "/orders/statuses",
        icon: <Label />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Shipping Companies",
        to: "/orders/shipping-companies",
        icon: <LocalShipping />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Payment Methods",
        to: "/orders/payment-methods",
        icon: <CreditCard />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Coupons",
        to: "/orders/coupons",
        icon: <Redeem />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(role) !== -1,
      },
    ],
  },
  {
    title: "Manage Stock",
    icon: <Inventory />,
    shouldRender: (role, isAuthenticated) => true,
    children: [
      {
        title: "Suppliers",
        to: "/suppliers",
        icon: <Person2Outlined />,
        shouldRender: (role, isAuthenticated) => true,
      },
      {
        title: "Inventory",
        to: "/inventory",
        icon: <Inventory />,
        shouldRender: (role, isAuthenticated) => true,
      },
    ],
  },
  {
    title: "Administration",
    icon: <PeopleAlt />,
    shouldRender: (role, isAuthenticated) =>
      [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
    children: [
      {
        title: "Platform Users",
        to: "/users",
        icon: <PeopleAlt />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Account Users",
        to: "/users/account",
        icon: <PeopleAlt />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Newsletter",
        to: "/newsletter",
        icon: <Mail />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Cache",
        to: "/cache",
        icon: <MemoryOutlined />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(role) !== -1,
      },
      {
        title: "Database",
        to: "/database",
        icon: <DataArray />,
        shouldRender: (role, isAuthenticated) =>
          [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      },
      // {
      //   title: "Monitoring",
      //   to: "/monitoring",
      //   icon: <MonitorRounded />,
      //   shouldRender: (role, isAuthenticated) =>
      //     Boolean(isAuthenticated) && [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
      // },
    ],
  },
  {
    title: "Websites",
    to: "/websites",
    icon: <Language />,
    shouldRender: (role, isAuthenticated) =>
      isAuthenticated && [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
  },
  // {
  //   title: "Tasks",
  //   to: "/boards",
  //   icon: <BookmarkAddedOutlined />,
  //   shouldRender: (role, isAuthenticated) =>
  //     isAuthenticated && [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(role) !== -1,
  // },
  {
    title: "Blogs",
    to: "/blogs",
    icon: <ArticleOutlined />,
    shouldRender: (role, isAuthenticated) =>
      isAuthenticated && [ROLES.SUPER_ADMIN].indexOf(role) !== -1,
  },
  {
    title: "Notifications",
    to: "/notifications",
    icon: <Notifications />,
    shouldRender: (role, isAuthenticated) => isAuthenticated,
  },

  {
    title: "Login",
    to: "/login",
    icon: <Login />,
    shouldRender: (role, isAuthenticated) =>
      ValidationService.isNullOrUndefinedOrEmpty(
        localStorage.getItem("access_token")
      ) && !isAuthenticated,
  },
  {
    title: "Register",
    to: "/register",
    icon: <PersonAdd />,
    shouldRender: (role, isAuthenticated) =>
      ValidationService.isNullOrUndefinedOrEmpty(
        localStorage.getItem("access_token")
      ) && !isAuthenticated,
  },

  {
    title: "Profile",
    to: "/profile",
    icon: <Person />,
    shouldRender: (role, isAuthenticated) => Boolean(isAuthenticated),
  },
];
