import React from "react";
import { Link as NavLink } from "react-router-dom";
import { Link } from "@mui/material";
export default function NavigationLink({ to, children, ...rest }) {
  return (
    <Link component={NavLink} to={to} {...rest}>
      {children}
    </Link>
  );
}
