import { deriveSafeResponse } from "../../utils/generic";
import { DataActions } from "../reducers/dataReducer";
import axios from "../../apiClient";
import ValidationService from "../../services/ValidationService";

export const initializeDataSlice =
  ({ identifier, payload }) =>
  (dispatch) => {
    const initPayload = payload;
    if (initPayload?.withFilters && initPayload?.filtersConfig) {
      initPayload.searchFilters = initPayload.filtersConfig.reduce(
        (acc, filter) => {
          if (filter.type === "checkbox") {
            acc[filter.name] = !!filter.value;
          } else if (filter.type === "select") {
            if (filter.multiple) {
              acc[filter.name] = Array.isArray(filter.value)
                ? filter.value
                : filter?.value
                ? [filter.value]
                : [];
            } else {
              acc[filter.name] = filter.value;
            }
          } else {
            acc[filter.name] = filter.value;
          }
          return acc;
        },
        {}
      );
    }
    dispatch({
      type: DataActions.INIT_SLICE,
      identifier,
      payload: initPayload || {},
    });
  };

export const setSearchFilters =
  ({ identifier, filters }) =>
  (dispatch) => {
    try {
      if (ValidationService.isObject(filters)) {
        localStorage.setItem(
          `${identifier}_filters`,
          JSON.stringify(filters || {})
        );
      } else {
        localStorage.removeItem(`${identifier}_filters`);
      }
    } catch (error) {}

    dispatch({ type: DataActions.SET_FILTERS, identifier, payload: filters });
  };

export const clearSearchFilters =
  ({ identifier }) =>
  (dispatch, getState) => {
    const { filtersConfig, withFilters } = getState()?.data?.[identifier] || {};
    localStorage.removeItem(`${identifier}_filters`);
    if (!withFilters) {
      return;
    }

    const newSearchFilters = filtersConfig.reduce((acc, filter) => {
      if (filter.type === "checkbox") {
        acc[filter.name] = filter.formatter
          ? filter.formatter(!!filter.value, true)
          : !!filter.value;
      } else if (filter.type === "select") {
        if (filter.multiple) {
          acc[filter.name] = [];
        } else {
          acc[filter.name] = filter.formatter
            ? filter.formatter(filter.value, true)
            : filter.value;
        }
      } else {
        acc[filter.name] = filter.formatter ? filter.formatter("") : "";
      }
      return acc;
    }, {});

    dispatch(setSearchFilters({ identifier, filters: newSearchFilters }));
  };

export const setLoading =
  ({ loading, identifier }) =>
  (dispatch) => {
    dispatch({ type: DataActions.DATA_LOADING, identifier, payload: loading });
  };

export const fetchData =
  ({ page, view, filters, identifier } = {}) =>
  async (dispatch, getState) => {
    let res;

    try {
      dispatch(setLoading({ loading: true, identifier }));

      const state = getState().data;
      const {
        method = "GET",
        endpoint,
        entityName,
        pageKey,
        viewKey,
        totalKey,
        searchFilters,
      } = state[identifier] || {};
      const persistedFilters = localStorage.getItem(`${identifier}_filters`);
      if (persistedFilters) {
        try {
          const objPersistedFilters = JSON.parse(persistedFilters);
          Object.assign(searchFilters || {}, objPersistedFilters);
        } catch (error) {}
      }
      res = await axios({
        url: endpoint,
        method: method?.toUpperCase() || "GET",
        data: {
          page,
          view,
          ...filters,
          ...(searchFilters || {}),
        },
        params: {
          page,
          view,
          ...filters,
          ...(searchFilters || {}),
        },
      });
      dispatch({
        type: DataActions.FETCH_DATA,
        identifier,
        payload: {
          data: res.data[entityName],
          page: res.data[pageKey],
          view: res.data[viewKey],
          total: res.data[totalKey],
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DataActions.FETCH_DATA,
        identifier,
        payload: {
          data: [],
          page: page,
          view: view,
          total: 0,
        },
      });
    } finally {
      dispatch(setLoading({ loading: false, identifier }));
      return deriveSafeResponse(res);
    }
  };

export const removeDataSlice =
  ({ identifier }) =>
  (dispatch) => {
    dispatch({ type: DataActions.REMOVE_SLICE, payload: identifier });
  };
