/* eslint-disable react/prop-types */
import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
} from "@mui/material";
import useLanguages from "../../../store/hooks/useLanguages";
import { Numbers } from "@mui/icons-material";

export default function TableHeader({
  columns = [],
  order,
  orderBy,
  onSort,
  rowCount,
  onSelectAllClick,
  numSelected,
  selectionType = "MULTIPLE",
  disableSelection,
}) {
  const { getStaticTranslation } = useLanguages();
  return (
    <TableHead>
      <TableRow>
        {!disableSelection &&
        selectionType === "MULTIPLE" &&
        onSelectAllClick ? (
          <TableCell padding="checkbox">
            {selectionType === "MULTIPLE" && (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            )}
          </TableCell>
        ) : !disableSelection ? (
          <TableCell padding="checkbox">
            <Numbers />
            <b>{numSelected}</b>
          </TableCell>
        ) : null}

        {columns.map(
          (
            {
              name,
              label,
              align = "left",
              disablePadding = false,
              disableSorting,
            },
            idx
          ) => (
            <TableCell
              key={idx}
              align={align}
              padding={disablePadding ? "none" : "normal"}
              sortDirection={orderBy === name ? order : false}
            >
              {!disableSorting && name !== "actions" ? (
                <TableSortLabel
                  active={orderBy === name}
                  direction={order?.toLowerCase()}
                  onClick={() => (!disableSorting ? onSort(name) : null)}
                >
                  {getStaticTranslation(label)}
                </TableSortLabel>
              ) : (
                getStaticTranslation(label)
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}
