import { Grid, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  deriveSafeResponse,
  getToArrayOrToEmptyArray,
  toApiUrl,
} from "../../utils/generic";
import TableDataLoader from "../dataGrid/TableDataLoader";
import useDataSlice from "../dataGrid/useDataSlice";
import { UsersColumns } from "./constants";
import axios from "../../apiClient";
import { Add, Delete, Edit } from "@mui/icons-material";
import { ROLES } from "../../data/roles";
import { addNotification } from "../../store/actions/notifications";
import useAuth, { useRoles } from "../../store/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import BackdropLoader from "../ui/BackdropLoader";
import SimpleDialog from "../dialogs/SimpleDialog";
import UserForm from "./UserForm";
import Title from "../ui/Title";
import useProducts from "../../store/hooks/useProducts";

function UsersView({ filters = {}, url, identifier }) {
  const navigate = useNavigate();
  const { websites } = useProducts();
  const [dataSlice, getData, setLoading] = useDataSlice(identifier);
  const [selected, setSelected] = useState(null);
  const { user } = useAuth();
  const { roleOptions, loading } = useRoles((role) =>
    identifier === "account_users" ? true : role.name === ROLES.TENANT
  );

  const websiteOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(websites).map((x) => ({
      value: x.id,
      label: x.domain,
    }));
  }, [websites]);

  const handleAddNew = () => {
    setSelected({
      parent_id:
        identifier === "account_users"
          ? user?.parent_id || user?.id
          : undefined,
    });
  };

  const handleEdit = (row) => {
    navigate(`/users/edit/${row.id}`);
  };

  const handleSubmit = async () => {
    let res;
    try {
      setLoading(true);
      await getData(dataSlice.page, dataSlice.view, dataSlice.filters);
    } catch (error) {
    } finally {
      setLoading(false);
      return deriveSafeResponse(res);
    }
  };

  const handleDelete = async (row) => {
    const handler = async () => {
      return await axios.delete(toApiUrl(`/users/${row.id}`));
    };
    await handleAction(handler);
  };

  const handleAction = async (handler) => {
    let res;
    try {
      setLoading(true);
      res = await handler();
      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
      addNotification("Action completed", "success");
    } catch (error) {
      addNotification("Action failed", "error");
    } finally {
      setLoading(false);
      return deriveSafeResponse(res);
    }
  };

  const userFilterOptions = useMemo(() => {
    return [
      {
        name: "email",
        label: "Email",
        formatter: (value) => (!value ? undefined : value),
      },
      {
        name: "id",
        label: "ID",
        formatter: (value) => (!value ? undefined : value),
      },
      {
        name: "role_id",
        label: "Role",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(roleOptions),
        formatter: (value) => (value === "All" || !value ? undefined : value),
      },
      {
        name: "website_id",
        label: "Website",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(websiteOptions),
        formatter: (value) => (value === "All" || !value ? undefined : value),
      },
      {
        name: "verified",
        label: "Verified",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat([
          { label: "Verified", value: "true" },
          { label: "Unverified", value: "false" },
        ]),
        formatter: (value) => (value === "All" || !value ? undefined : value),
      },
      {
        name: "blacklist",
        label: "Access",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat([
          { label: "Access", value: "false" },
          { label: "Blocked", value: "true" },
        ]),
        formatter: (value) => (value === "All" || !value ? undefined : value),
      },
    ];
  }, [roleOptions, websiteOptions]);

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={12} xs={12} justifyContent="flex-end" display="flex">
        <IconButton variant="contained" size="sm" onClick={handleAddNew}>
          <Add />
        </IconButton>
      </Grid>
      <Grid item md={12} xs={12}>
        <TableDataLoader
          filters={{ order_by: "created_at:desc", ...filters }}
          withFilters
          filtersConfig={userFilterOptions}
          defaultSortingColumn={"created_at"}
          defaultOrderBy="desc"
          entityName="results"
          identifier={identifier}
          endpoint={toApiUrl(url)}
          columns={UsersColumns}
          actions={[
            {
              type: "Edit",
              icon: <Edit />,
              title: "Edit",
              name: "edit",

              action: handleEdit,
            },
            {
              type: "Delete",
              icon: <Delete />,
              title: "Delete",
              name: "delete",
              disabled: (row) =>
                row.role?.name === ROLES.SUPER_ADMIN || row.id === user?._id,
              action: handleDelete,
            },
          ]}
        />
      </Grid>
      <SimpleDialog
        open={Boolean(selected)}
        onCancel={() => setSelected(null)}
        title={
          <Title divider>
            <Edit sx={{ mr: 2 }} /> {selected?.id ? "Edit" : "Add"} User
          </Title>
        }
      >
        {Boolean(selected) ? (
          <UserForm
            roles={roleOptions}
            user={selected}
            onSubmit={handleSubmit}
          />
        ) : null}
      </SimpleDialog>
    </Grid>
  );
}

export default UsersView;
