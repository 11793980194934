import { Grid } from "@mui/material";

export default function Column({
  className,
  sx,
  style,
  md,
  xs,
  lg,
  xl,
  sm,
  children,
}) {
  return (
    <Grid
      item
      md={md}
      xs={xs}
      lg={lg}
      sm={sm}
      xl={xl}
      className={className}
      style={style}
      sx={sx}
    >
      {children}
    </Grid>
  );
}
