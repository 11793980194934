import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Label } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import {
  getProductSuperCategories,
  getWebsites,
} from "../store/actions/products";
import { useDispatch } from "react-redux";
import Groups from "../components/products/Groups";

function GroupsPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebsites());
    dispatch(getProductSuperCategories());
  }, [dispatch]);
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={"Groups"} icon={<Label />} />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <Groups />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(GroupsPage, [ROLES.SUPER_ADMIN]));
