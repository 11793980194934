import React, { useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import {
  WebsiteSectionsColumns,
  WebsiteSectionPages,
  WebsiteSectionPagesOptions,
} from "./constants";
import { toApiUrl, toServerImage, uploadFile } from "../../utils/generic";
import { Add, Delete, Edit } from "@mui/icons-material";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import FileImport from "../forms/FileImport";
import { Avatar, IconButton } from "@mui/material";
import SelectInput from "../forms/SelectInput";
import { useParams } from "react-router-dom";

export default function WebsiteSections() {
  const [entity, setEntity] = useState(null);
  const { id: website_id } = useParams();
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    title: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    description: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 0, max: 10000 }),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
    image: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 1000 }),
    },
    url: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({ value, min: 3, max: 1000 }),
      ],
    },
    pages: {
      defaultValue: [],
      validators: [
        (value) => ValidationService.isNotEmptyArray(value),
        (value) => value.every((v) => !!WebsiteSectionPages[v]),
      ],
    },
  });
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      title: obj?.title || "",
      enabled: !!obj?.enabled,
      image: obj?.image || "",
      description: obj?.description || "",
      pages: ValidationService.isNotEmptyArray(obj?.pages) ? obj.pages : [],
      url: obj?.url || "",
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("website_sections");

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { id, ...rest } = formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/sections"), {
          ...rest,
          website_id,
        });
      } else {
        res = await axios.put(toApiUrl(`/sections/${id}`), {
          ...rest,
          website_id,
        });
      }
      addNotification(
        `Website Section ${id ? "Updated" : "Created"}`,
        "success"
      );

      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification(
        `Website Section was not ${id ? "Updated" : "Created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/sections/${row.id}`));
      addNotification("Website Section Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification("Website Section was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const { form, handleChange } = formConfig;
  return (
    <>
      <TableDataLoader
        endpoint={toApiUrl("/sections")}
        filters={{ website_id }}
        entityName="results"
        identifier="website_sections"
        columns={WebsiteSectionsColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <IconButton
                sx={{ mr: 2 }}
                onClick={() => openDialogForm({ title: "" })}
              >
                <Add />
              </IconButton>
            </div>
          );
        }}
      />
      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Website Section
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        <>
          {form.image.value ? (
            <Avatar
              sx={{ width: 100, height: 100 }}
              src={toServerImage(form.image.value)}
            />
          ) : null}
          <FileImport
            label="Upload Website Section Image"
            onFileUpload={async (files) => {
              setLoading(true);
              const file = await uploadFile(files);
              if (file) {
                formConfig.handleBaseChange("image", file?.filename);
              }
              setLoading(false);
            }}
          />
          <CustomSwitch
            name="enabled"
            label="Status"
            checked={form.enabled.value}
            onChange={handleChange}
          />
          <Input
            sx={{ my: 2 }}
            {...form.url}
            name="url"
            label="URL"
            value={form.url.value}
            onChange={handleChange}
          />
          <SelectInput
            sx={{ my: 2 }}
            {...form.pages}
            name="pages"
            label="Pages"
            value={form.pages.value}
            multiple
            options={WebsiteSectionPagesOptions}
            onChange={handleChange}
          />
          <Input
            sx={{ my: 2 }}
            {...form.title}
            name="title"
            label="Title"
            value={form.title.value}
            onChange={handleChange}
          />
          <Input
            sx={{ my: 2 }}
            {...form.description}
            name="description"
            label="Description"
            value={form.description.value}
            onChange={handleChange}
            multiline
            rows={4}
          />
        </>
      </SimpleDialog>
    </>
  );
}
