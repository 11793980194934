import { useEffect, useState } from "react";
import axios from "../../apiClient";
import { toApiUrl } from "../../utils/generic";

const useOrdersStaticData = () => {
  const [staticData, setStaticData] = useState({
    paymentMethods: [],
    orderStatuses: [],
    countries: [],
    shippingCompanies: [],
    websites: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.allSettled(
      [
        async () => ({
          paymentMethods:
            (await axios.get(toApiUrl("/static/payment-methods")))?.data
              ?.results || [],
        }),
        async () => ({
          orderStatuses:
            (await axios.get(toApiUrl("/static/order-statuses")))?.data
              ?.results || [],
        }),
        async () => ({
          countries:
            (await axios.get(toApiUrl("/static/countries")))?.data?.results ||
            [],
        }),
        async () => ({
          shippingCompanies:
            (await axios.get(toApiUrl("/static/shipping-companies")))?.data
              ?.results || [],
        }),
        async () => ({
          websites:
            (await axios.get(toApiUrl("/static/websites")))?.data?.results ||
            [],
        }),
      ].map((promise) => promise())
    )
      .then((result) => {
        setStaticData(
          result.reduce(
            (acc, { value, status }) => {
              if (status === "rejected") {
                return acc;
              }

              return {
                ...acc,
                ...value,
              };
            },
            {
              paymentMethods: [],
              orderStatuses: [],
              countries: [],
              shippingCompanies: [],
              websites: [],
            }
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return { ...staticData, loading };
};

export default useOrdersStaticData;
