import React, { useMemo } from "react";
import useOrdersStaticData from "../../store/hooks/useOrdersStaticData";
import Container from "../grid/Container";
import Row from "../grid/Row";
import Input from "../forms/Input";
import SelectInput from "../ui/SelectInput";
import { getToArrayOrToEmptyArray } from "../../utils/generic";

export default function BasicOrderDetailsForm({
  order,
  onSubmit,
  form,
  handleChange,
}) {
  const { websites, paymentMethods, orderStatuses, countries } =
    useOrdersStaticData();

  const paymentMethodOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(paymentMethods).map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [paymentMethods]);

  const websiteOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(websites).map((x) => ({
      value: x.id,
      label: x.domain,
    }));
  }, [websites]);

  const orderStastusOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(orderStatuses).map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [orderStatuses]);

  const countriesOptions = useMemo(() => {
    return [{ label: "None", value: null }].concat(
      getToArrayOrToEmptyArray(countries).map((x) => ({
        value: x.id,
        label: x.name,
      }))
    );
  }, [countries]);

  return (
    <Container>
      <Row>
        <Input
          {...form.external_id}
          value={form.external_id.value}
          onChange={handleChange}
          name="external_id"
          label="External ID"
        />
      </Row>
      <Row>
        <Input
          {...form.tracking_number}
          value={form.tracking_number.value}
          onChange={handleChange}
          name="tracking_number"
          label="Tracking Number"
        />
      </Row>
      <Row>
        <Input
          {...form.business_name}
          value={form.business_name.value}
          onChange={handleChange}
          name="business_name"
          label="Business Name"
        />
      </Row>
      <Row>
        <Input
          {...form.business_address}
          value={form.business_address.value}
          onChange={handleChange}
          name="business_address"
          label="Business Address"
        />
      </Row>
      <Row>
        <SelectInput
          {...form.business_country_id}
          name="business_country_id"
          value={form.business_country_id.value}
          label="Business Country"
          options={countriesOptions}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <Input
          {...form.vat}
          value={form.vat.value}
          onChange={handleChange}
          name="vat"
          label="VAT"
        />
      </Row>
      <Row>
        <SelectInput
          {...form.payment_method_id}
          name="payment_method_id"
          value={form.payment_method_id.value}
          label="Payment Method"
          options={paymentMethodOptions}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <SelectInput
          {...form.order_status_id}
          name="order_status_id"
          value={form.order_status_id.value}
          label="Order Status"
          options={orderStastusOptions}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <SelectInput
          {...form.website_id}
          name="website_id"
          value={form.website_id.value}
          label="Website"
          options={websiteOptions}
          onChange={handleChange}
        />
      </Row>
    </Container>
  );
}
