import React from "react";
import { useParams } from "react-router-dom";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { toApiUrl, toFixedDecimal } from "../../utils/generic";
import { Delete } from "@mui/icons-material";
import { ProductReviewsColumns } from "./constants";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import { Box, Rating, Tooltip } from "@mui/material";

export default function ProductReviews({ product, refetch }) {
  const { id } = useParams();
  const [dataSlice, getData, setLoading] = useDataSlice("product_reviews");

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(
        toApiUrl(`/products/${row.product_id}/reviews/${row.id}`)
      );
      addNotification("Product Review Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
      await refetch(id);
    } catch (error) {
      addNotification("Product Review was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };
  return (
    <>
      <Box sx={{ alignItemns: "center", display: "flex" }}>
        <Tooltip
          title={`${toFixedDecimal(
            product?.reviews_aggregate?.avg?.rating || 0
          )}`}
        >
          <Rating
            readOnly
            precision={0.01}
            value={toFixedDecimal(product?.reviews_aggregate?.avg?.rating || 0)}
          />
        </Tooltip>
        ({toFixedDecimal(product?.reviews_aggregate?.avg?.rating || 0)} in{" "}
        {product?.reviews_aggregate?.count || 0} reviews)
      </Box>

      <TableDataLoader
        filters={{ order_by: "created_at:desc" }}
        endpoint={toApiUrl(`/products/${id}/reviews`)}
        entityName="results"
        identifier="product_reviews"
        columns={ProductReviewsColumns}
        actions={[
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        defaultSortingColumn={"created_at"}
        defaultOrderBy="desc"
      />
    </>
  );
}
