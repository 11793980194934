import { AggregateActions } from "../reducers/aggregateReducer";
import axios from "../../apiClient";
import { Config } from "../../config";

export const setAggregateLoading = (loading) => (dispatch) =>
  dispatch({ type: AggregateActions.AggregateLoading, payload: loading });

export const getDashboardaggregatedData = () => async (dispatch) => {
  try {
    dispatch(setAggregateLoading(true));
    const res = await axios.get(`${Config.API_BASE_URL}/aggregator/dashboard`);
    dispatch({
      type: AggregateActions.GetDashboardData,
      payload: res.data || {},
    });
  } catch (error) {
  } finally {
    dispatch(setAggregateLoading(false));
  }
};
