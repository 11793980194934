import { Lock, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Container, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import ValidationService from "../../services/ValidationService";
import { changePassword } from "../../store/actions/auth";
import useForm from "../../store/hooks/useForm";
import Input from "../forms/Input";
import AnalyticsCard from "../ui/AnalyticsCard";

function ChangePasswordForm({ loading, changePassword }) {
  const [togglePasswords, setTogglePasswords] = useState(false);
  const formConfig = useForm({
    oldPassword: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, data) => value !== data.password.value,
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return {
          [fieldName]: fieldValue,
          verifyPassword: form.verifyPassword.value,
          oldPassword: form.oldPassword.value,
        };
      },
    },
    verifyPassword: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, data) => {
          return value === data.password.value;
        },
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return { [fieldName]: fieldValue, password: form.password.value };
      },
    },
    password: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, form) => form.oldPassword.value !== value,
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return {
          [fieldName]: fieldValue,
          oldPassword: form.oldPassword.value,
          verifyPassword: form.verifyPassword.value,
        };
      },
    },
  });
  return (
    <Container maxWidth="md">
      <AnalyticsCard title={"Change Password"} icon={<Lock />} divider>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Input
              {...formConfig.form.oldPassword}
              label={"Type your current password"}
              name="oldPassword"
              type={togglePasswords ? "text" : "password"}
              onChange={formConfig.handleChange}
              value={formConfig.form.oldPassword.value}
              iconAction={() => setTogglePasswords((prev) => !prev)}
              endIcon={
                togglePasswords ? (
                  <IconButton color="primary">
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <Visibility />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              {...formConfig.form.password}
              label={"Type your new password"}
              name="password"
              type={togglePasswords ? "text" : "password"}
              onChange={formConfig.handleChange}
              value={formConfig.form.password.value}
              iconAction={() => setTogglePasswords((prev) => !prev)}
              endIcon={
                togglePasswords ? (
                  <IconButton color="primary">
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <Visibility />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              {...formConfig.form.verifyPassword}
              label={"Verify your new password"}
              name="verifyPassword"
              type={togglePasswords ? "text" : "password"}
              onChange={formConfig.handleChange}
              value={formConfig.form.verifyPassword.value}
              iconAction={() => setTogglePasswords((prev) => !prev)}
              endIcon={
                togglePasswords ? (
                  <IconButton color="primary">
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <Visibility />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Button
              startIcon={<Password />}
              disabled={loading || !formConfig.isValid || !formConfig.isTouched}
              variant="contained"
              onClick={async () => {
                const res = await changePassword(formConfig.getFormValues());
                if (res) {
                  formConfig.resetForm();
                }
              }}
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </AnalyticsCard>
    </Container>
  );
}

export default connect(({ auth }) => ({ loading: auth.loading }), {
  changePassword,
})(ChangePasswordForm);
