import { Add, Delete, Save, Settings } from "@mui/icons-material";
import { Button, Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import { addNotification } from "../../store/actions/notifications";
import Input from "../forms/Input";
import useProducts from "../../store/hooks/useProducts";
import SelectInput from "../forms/SelectInput";
import { getToArrayOrToEmptyArray } from "../../utils/generic";

const productStateOptions = [
  {
    value: "auto",
    title: "Auto",
    description: `Will set as visible all products that are in stock and have price > 0`,
  },
  {
    value: "disable",
    title: "Disable All",
    description: "Will set as hidden all selected products",
  },
];

export default function AdvancedActions({
  supplier,
  filters,
  disabled,
  onSubmit,
  selectedProducts,
  categories,
  totalProducts,
  onSaveOptions,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { product_categories = [] } = useProducts();

  const [options, setOptions] = useState({
    createGroups: supplier?.meta?.insertOptions?.createGroups || true,
    createBrands: supplier?.meta?.insertOptions?.createBrands || true,
    createAttributes: supplier?.meta?.insertOptions?.createAttributes || false,
    createProducts: supplier?.meta?.insertOptions?.createProducts || true,
    productStateAction:
      supplier?.meta?.insertOptions?.productStateAction || "auto",
    fixedPriceStep: supplier?.meta?.insertOptions?.fixedPriceStep || 0,
    percentagePriceStep:
      supplier?.meta?.insertOptions?.percentagePriceStep || 0,
    transferCategories: Array.isArray(
      supplier?.meta?.insertOptions?.transferCategories
    )
      ? supplier?.meta?.insertOptions?.transferCategories
      : [],
    affectPrices: supplier?.meta?.insertOptions?.affectPrices || true,
    affectStatus: supplier?.meta?.insertOptions?.affectStatus || false,
    affectQuantity: supplier?.meta?.insertOptions?.affectQuantity || true,
    affectImages: supplier?.meta?.insertOptions?.affectImages || false,
    affectProductSizeAndWeight:
      supplier?.meta?.insertOptions?.affectProductSizeAndWeight || false,
    affectLabels: supplier?.meta?.insertOptions?.affectLabels || false,
  });

  const fromCategories = useMemo(() => {
    return getToArrayOrToEmptyArray(categories).map((x) => ({
      label: x.name,
      value: x.id,
    }));
  }, [categories]);

  const toCategories = useMemo(() => {
    return getToArrayOrToEmptyArray(product_categories).map((x) => ({
      label: x.name,
      value: x.id,
    }));
  }, [product_categories]);

  const isInvalid =
    (options.transferCategories?.length &&
      options.transferCategories.some((x) => !x.from || !x.to)) ||
    isNaN(options.percentagePriceStep) ||
    parseInt(options.percentagePriceStep) < -100 ||
    parseInt(options.percentagePriceStep) > 100 ||
    isNaN(options.fixedPriceStep);

  return (
    <>
      <Tooltip title="Advanced Actions">
        <IconButton disabled={disabled} onClick={() => setOpen(true)}>
          <Settings />
        </IconButton>
      </Tooltip>
      <SimpleDialog
        open={open}
        onConfirm={async () => {
          try {
            setLoading(true);
            const res = await onSubmit(options);
            if (!res) {
              throw new Error();
            }
            setLoading(false);
            addNotification("Process started", "success");
            setOpen(false);
          } catch (error) {
            setLoading(false);
            addNotification("Action failed", "error");
          }
        }}
        disableConfirm={isInvalid || loading}
        title={
          <Title sx={{ justifyContent: "space-between" }}>
            <Title>
              <Settings sx={{ mr: 2 }} /> Advanced Actions
            </Title>
            <Title>
              <Button
                variant="contained"
                startIcon={<Save />}
                disabled={isInvalid || loading}
                onClick={async () => {
                  setLoading(true);
                  await onSaveOptions(options);
                  setLoading(false);
                }}
              >
                Save{" "}
              </Button>
            </Title>
          </Title>
        }
        onCancel={() => setOpen(false)}
      >
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Input
              helperText="* This operation will be applied before the fixed value step"
              label="Transform Prices By Percentage"
              name="percentagePriceStep"
              value={options.percentagePriceStep}
              type="number"
              isValid={
                !isNaN(options.percentagePriceStep) &&
                parseInt(options.percentagePriceStep) >= -100 &&
                parseInt(options.percentagePriceStep) <= 100
              }
              isTouched
              onChange={(e) =>
                setOptions((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value === e ? 0 : e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              label="Transform Prices By Fixed Value"
              name="fixedPriceStep"
              type="number"
              isValid={!isNaN(options.fixedPriceStep)}
              isTouched
              value={options.fixedPriceStep}
              onChange={(e) =>
                setOptions((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2} style={{ border: "1px dashed #259" }}>
              <Grid item md={12} xs={12}>
                <Title divider>
                  <Checkbox
                    checked={options.createProducts}
                    name="createProducts"
                    onChange={(e, checked) =>
                      setOptions((prev) => ({
                        ...prev,
                        [e.target.name]: checked,
                        ...(checked && { createGroups: true }),
                      }))
                    }
                  />
                  Affect Products ({totalProducts || 0})
                </Title>
              </Grid>
              <Grid item md={12} xs={12}>
                <Title>
                  <small>
                    * The following actions apply only to already existing
                    products
                  </small>
                </Title>
              </Grid>
              <Grid item>
                <Title divider>
                  <Checkbox
                    disabled={!options.createProducts}
                    checked={options.affectPrices}
                    name="affectPrices"
                    onChange={(e, checked) =>
                      setOptions((prev) => ({
                        ...prev,
                        [e.target.name]: checked,
                      }))
                    }
                  />
                  Affect Product Prices
                </Title>
              </Grid>
              <Grid item>
                <Title divider>
                  <Checkbox
                    disabled={!options.createProducts}
                    checked={options.affectStatus}
                    name="affectStatus"
                    onChange={(e, checked) =>
                      setOptions((prev) => ({
                        ...prev,
                        [e.target.name]: checked,
                      }))
                    }
                  />
                  Affect Product Visibility
                </Title>
              </Grid>
              <Grid item>
                <Title divider>
                  <Checkbox
                    disabled={!options.createProducts}
                    checked={options.affectQuantity}
                    name="affectQuantity"
                    onChange={(e, checked) =>
                      setOptions((prev) => ({
                        ...prev,
                        [e.target.name]: checked,
                      }))
                    }
                  />
                  Affect Product Quantity
                </Title>
              </Grid>
              <Grid item>
                <Title divider>
                  <Checkbox
                    disabled={!options.createProducts}
                    checked={options.affectProductSizeAndWeight}
                    name="affectProductSizeAndWeight"
                    onChange={(e, checked) =>
                      setOptions((prev) => ({
                        ...prev,
                        [e.target.name]: checked,
                      }))
                    }
                  />
                  Affect Product Length,Width,Height,Weight
                </Title>
              </Grid>
              <Grid item>
                <Title divider>
                  <Checkbox
                    disabled={!options.createProducts}
                    checked={options.affectLabels}
                    name="affectLabels"
                    onChange={(e, checked) =>
                      setOptions((prev) => ({
                        ...prev,
                        [e.target.name]: checked,
                      }))
                    }
                  />
                  Affect Product Labels
                </Title>
              </Grid>
              <Grid item>
                <Title divider>
                  <Checkbox
                    disabled={!options.createProducts}
                    checked={options.affectImages}
                    name="affectImages"
                    onChange={(e, checked) =>
                      setOptions((prev) => ({
                        ...prev,
                        [e.target.name]: checked,
                      }))
                    }
                  />
                  Affect Product images
                </Title>
              </Grid>
            </Grid>{" "}
          </Grid>
          <Grid item md={12} xs={12}>
            <Title divider>
              <Checkbox
                checked={options.createGroups}
                name="createGroups"
                disabled={options.createProducts}
                onChange={(e, checked) =>
                  setOptions((prev) => ({ ...prev, [e.target.name]: checked }))
                }
              />
              Affect Groups/Super Categories/Categories
            </Title>
          </Grid>
          <Grid item md={12} xs={12}>
            <Title divider>
              <Checkbox
                checked={options.createBrands}
                name="createBrands"
                onChange={(e, checked) =>
                  setOptions((prev) => ({ ...prev, [e.target.name]: checked }))
                }
              />
              Affect Brands
            </Title>
          </Grid>
          {/* <Grid item md={12} xs={12}>
            <Title divider>
              <Checkbox
                checked={options.createAttributes}
                name="createAttributes"
                onChange={(e, checked) =>
                  setOptions((prev) => ({ ...prev, [e.target.name]: checked }))
                }
              />
              Affect Attribute Categories and Attributes
            </Title>
          </Grid> */}
          {/* <Grid item md={12} xs={12}>
            <Title divider sx={{ my: 1 }}>
              Transfer Categories
              <IconButton
                onClick={() =>
                  setOptions((prev) => ({
                    ...prev,
                    transferCategories: [
                      ...prev.transferCategories,
                      { from: "", to: "" },
                    ],
                  }))
                }
              >
                <Add />
              </IconButton>
            </Title>
            <small>*This action is not related to the filters applied*</small>
            {options.transferCategories.map((c, idx) => (
              <Grid key={idx} container spacing={2} sx={{ my: 1 }}>
                <Grid item md={5} xs={12}>
                  <SelectInput
                    isTouched
                    isValid={!!c.from}
                    label="From Category"
                    value={c.from}
                    options={fromCategories}
                    onChange={(e) => {
                      if (
                        options.transferCategories.some(
                          (x) => x.from === e.target.value
                        )
                      ) {
                        addNotification(
                          "This category is already selected",
                          "warning"
                        );
                        return;
                      }
                      setOptions((prev) => ({
                        ...prev,
                        transferCategories: prev.transferCategories.map(
                          (x, i) =>
                            i === idx ? { from: e.target.value, to: c.to } : x
                        ),
                      }));
                    }}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <SelectInput
                    isTouched
                    isValid={!!c.to}
                    label="To Category"
                    value={c.to}
                    options={toCategories}
                    onChange={(e) =>
                      setOptions((prev) => ({
                        ...prev,
                        transferCategories: prev.transferCategories.map(
                          (x, i) =>
                            i === idx ? { to: e.target.value, from: c.from } : x
                        ),
                      }))
                    }
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <IconButton
                    onClick={() =>
                      setOptions((prev) => ({
                        ...prev,
                        transferCategories: prev.transferCategories.filter(
                          (_, i) => i !== idx
                        ),
                      }))
                    }
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid> */}
        </Grid>
      </SimpleDialog>
    </>
  );
}
