import { Avatar, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Input from "../forms/Input";
import {
  AddLink,
  CopyAllOutlined,
  Delete,
  LinkOffOutlined,
  Numbers,
  Search,
  Visibility,
} from "@mui/icons-material";
import { debounce } from "lodash";
import axios from "../../apiClient";
import { toApiUrl, toServerImage } from "../../utils/generic";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import BackdropLoader from "../ui/BackdropLoader";
import { addNotification } from "../../store/actions/notifications";
import { getProduct } from "../../store/actions/products";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Paginator from "../ui/Paginator";
import SimpleDialog from "../dialogs/SimpleDialog";
import AutogenerateVariantsForm from "./AutogenerateVariantsForm";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

const fetchProducts = async (page, view, filters = {}) => {
  try {
    const { data } = await axios.get(toApiUrl("/products"), {
      params: {
        page: page || 1,
        view: view || 25,
        ...filters,
      },
    });
    return { ...data, results: data.results || [] };
  } catch (error) {
    return { results: [], page: 1, view: 25, total: 0 };
  }
};

export default function ProductVariants({ product }) {
  const [openVariantsCreation, setOpenVariantsCreation] = useState(false);
  const [products, setProducts] = useState(null);
  const [search, setSearch] = useState("");
  const [paginationData, setPaginationData] = useState({
    page: 1,
    view: 25,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deleteEntity, setDeleteEntity] = useState(null);
  const [disassociateEntity, setDisassociateEntity] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addVariant = useCallback(
    async (variant_id) => {
      try {
        setIsLoading(true);
        await axios.post(toApiUrl("/product-variants"), {
          product_id: product.id,
          variant_id,
        });
        dispatch(getProduct(product.id));
        addNotification("Action completed", "success");
      } catch (error) {
        addNotification("Action failed", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, product.id]
  );

  const deleteVariant = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(toApiUrl(`/product-variants/${id}`));
        dispatch(getProduct(product.id));
        addNotification("Action completed", "success");
        setDisassociateEntity(null);
      } catch (error) {
        addNotification("Action failed", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, product.id]
  );

  const deleteProduct = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(toApiUrl(`/products/${id}`));
        dispatch(getProduct(product.id));
        addNotification("Action completed", "success");
        setDeleteEntity(null);
      } catch (error) {
        addNotification("Action failed", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, product.id]
  );

  const onChange = debounce(async (e) => {
    if (!e.target.value) {
      setProducts(null);
      setSearch(() => undefined);
      setPaginationData((prev) => ({ ...prev, page: 1, total: 0 }));
      return;
    }
    e.persist();
    setIsLoading(true);
    const { results, page, view, total } = await fetchProducts(
      paginationData.page,
      paginationData.view,
      {
        name: e.target.value || undefined,
        nid: product.id,
        unique_variants: true,
        include: "variants",
        order_by: "name:asc",
      }
    );
    setSearch(() => e.target.value || undefined);
    setProducts((prev) => results);
    setPaginationData((prev) => ({ page, view, total }));
    setIsLoading(false);
  }, 500);

  const results = useMemo(() => {
    if (!products?.length) {
      return [];
    }

    return products.map((p) => ({
      primary: <Avatar src={toServerImage(p.image)} />,
      secondary: p.name,
      renderActions: () => (
        <IconButton
          disabled={
            !!p?.variants?.length ||
            product?.variants?.some((x) => x.variant_id === p.id)
          }
          onClick={async () => await addVariant(p.id)}
        >
          <AddLink />
        </IconButton>
      ),
    }));
  }, [addVariant, product?.variants, products]);

  const productVariantsOptions = useMemo(() => {
    if (!product?.variants?.length) {
      return [];
    }

    return (product?.variants || []).map(({ variant, id }) => ({
      primary: <Avatar src={toServerImage(variant.image)} />,
      secondary: `${variant.name} (${
        variant?.product_attributes?.length
          ? variant.product_attributes
              .map((x) => x.attribute.name)
              .filter(Boolean)
              .join(", ")
          : "No Attributes"
      })`,
      renderActions: () => (
        <>
          <Tooltip title="Go to product">
            <IconButton onClick={() => navigate(`/products/${variant.id}`)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Disassociate products">
            <IconButton onClick={() => setDisassociateEntity(id)}>
              <LinkOffOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete product">
            <IconButton onClick={() => setDeleteEntity(variant.id)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    }));
  }, [navigate, product?.variants]);

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={isLoading} />
      <SimpleDialog
        open={openVariantsCreation}
        onCancel={() => setOpenVariantsCreation(false)}
        disableBackdropClick={isLoading}
        disableConfirm={isLoading}
        maxWidth="lg"
      >
        {openVariantsCreation ? (
          <AutogenerateVariantsForm
            onSubmit={() => {
              dispatch(getProduct(product.id));
              addNotification("Action completed", "success");
              setOpenVariantsCreation(false);
            }}
            product={product}
          />
        ) : null}
      </SimpleDialog>
      <Grid item md={6} xs={12}>
        <ListView
          items={productVariantsOptions}
          title={
            <Title sx={{ mb: 2, justifyContent: "space-between" }} divider>
              <Title>
                <Numbers sx={{ mr: 2 }} /> Product Variations (
                {productVariantsOptions?.length || 0})
              </Title>
              <Tooltip title="Auto generate variants">
                <IconButton
                  onClick={() => setOpenVariantsCreation(true)}
                  disabled={!product?.product_attributes?.length}
                >
                  <CopyAllOutlined />
                </IconButton>
              </Tooltip>
            </Title>
          }
          sx={{
            maxHeight: "700px",
            overflowY: "scroll",
            border: "1px solid #ccc",
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ListView
          title={
            <Input
              sx={{ mb: 2 }}
              fullWitdth
              label="Search by product name"
              autoComplete="off"
              isValid
              isTouched
              startIcon={<Search />}
              name="name"
              defaultValue={""}
              onChange={onChange}
              type="search"
              onClear={(e) => onChange(e)}
            />
          }
          sx={{
            maxHeight: "700px",
            overflowY: "scroll",
            border: "1px solid #ccc",
          }}
          items={results}
        />
        {products ? (
          <Paginator
            page={paginationData.page}
            view={paginationData.view}
            total={paginationData.total}
            onChange={(page, view) => {
              setIsLoading(true);
              fetchProducts(page, view, {
                name: search,
                nid: product.id,
                order_by: "name:asc",
              })
                .then(({ results, page, view, total }) => {
                  setProducts((prev) => results);
                  setPaginationData((prev) => ({ page, view, total }));
                })
                .catch((err) => {})
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
        ) : null}
      </Grid>
      <ConfirmationDialog
        open={!!deleteEntity}
        onCancel={() => setDeleteEntity(null)}
        onConfirm={async () => {
          await deleteProduct(deleteEntity);
        }}
        disableBackdropClick={isLoading}
        disableConfirm={isLoading}
      />
      <ConfirmationDialog
        open={!!disassociateEntity}
        onCancel={() => setDisassociateEntity(null)}
        onConfirm={async () => {
          await deleteVariant(disassociateEntity);
        }}
        disableBackdropClick={isLoading}
        disableConfirm={isLoading}
      />
    </Grid>
  );
}
