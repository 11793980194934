import React, { useState } from "react";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import {
  BusinessCenter,
  Edit,
  Info,
  Language,
  LocalShippingSharp,
  Payment,
} from "@mui/icons-material";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { IconButton } from "@mui/material";
import SimpleDialog from "../dialogs/SimpleDialog";
import { connect } from "react-redux";
import { updateOrder } from "../../store/actions/orders";
import BasicOrderDetailsForm from "./BasicOrderDetailsForm";
import { getTotalOrderPrice } from "../../utils/generic";

function BasicOrderDetails({ order, withEdit = true, updateOrder }) {
  const [open, setOpen] = useState(false);
  const formConfig = useForm({
    payment_method_id: {
      defaultValue: order?.payment_method_id,
      validators: ValidationService.isString,
    },
    website_id: {
      defaultValue: order?.website_id,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    order_status_id: {
      defaultValue: order?.order_status_id,
      validators: ValidationService.isString,
    },
    tracking_number: {
      defaultValue: order?.tracking_number,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 4, max: 255 }),
    },
    business_name: {
      defaultValue: order?.business_name,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    business_address: {
      defaultValue: order?.business_address,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    business_country_id: {
      defaultValue: order?.business_country_id,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    vat: {
      defaultValue: order?.vat,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    external_id: {
      defaultValue: order?.external_id,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
  });

  return (
    <>
      <ListView
        title={
          <Title divider sx={{ justifyContent: "space-between" }}>
            <Title>
              <Info sx={{ mr: 2 }} /> Basic Order Details
            </Title>
            {withEdit ? (
              <IconButton onClick={() => setOpen(true)}>
                <Edit />
              </IconButton>
            ) : null}
          </Title>
        }
        items={[
          {
            primary: `${order.id}`,
            secondary: "Order ID",
            icon: <Info />,
          },
          {
            primary: `${order.external_id || "-"}`,
            secondary: "External ID",
            icon: <Info />,
          },
          {
            primary: `${order.payment_method?.name || "-"}`,
            secondary: "Payment Method",
            icon: <Payment />,
          },
          {
            primary: `${order.order_status?.name || "-"}`,
            secondary: "Order Status",
            icon: <Payment />,
          },
          {
            primary: `${getTotalOrderPrice(order, true)} € ${
              order?.cash_on_delivery ? `+ ${order.cash_on_delivery} € COD` : ""
            }`,
            secondary: "Order Total (with Shipping)",
            icon: <Payment />,
          },
          {
            primary: `${order.tracking_number || "-"}`,
            secondary: "Tracking Number",
            icon: <LocalShippingSharp />,
          },
          {
            primary: `${order.business_name || "-"}`,
            secondary: "Business Name",
            icon: <BusinessCenter />,
          },
          {
            primary: `${order.business_address || "-"},${
              order?.business_country?.name || "-"
            }`,
            secondary: "Business Address",
            icon: <BusinessCenter />,
          },
          {
            primary: `${order.vat || "-"}`,
            secondary: "VAT",
            icon: <BusinessCenter />,
          },
          {
            primary: `${order.website?.domain || "-"}`,
            secondary: "Website",
            icon: <Language />,
          },
        ]}
      />
      <SimpleDialog
        open={open}
        disableConfirm={!formConfig.isValid || !formConfig.isTouched}
        onCancel={() => setOpen(false)}
        onConfirm={async () => {
          const body = Object.entries(formConfig.getFormValues()).reduce(
            (acc, [key, value]) => {
              if (ValidationService.isNullOrUndefinedOrEmpty(value)) {
                acc[key] = null;
                return acc;
              }
              acc[key] = value;
              return acc;
            },
            {}
          );
          const res = await updateOrder(order.id, body);
          if (res) {
            setOpen(false);
            // formConfig.resetForm();
          }
        }}
        title={
          <Title>
            <Edit sx={{ mr: 2 }} /> Basic Order Details
          </Title>
        }
      >
        {open ? <BasicOrderDetailsForm order={order} {...formConfig} /> : null}
      </SimpleDialog>
    </>
  );
}

export default connect(null, { updateOrder })(BasicOrderDetails);
