import { Block, Check, Info, Warning } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ActionsMenu from "../dataGrid/ActionsMenu";

export const NotificationTypes = {
  notification: "notification",
  sales_notification: "sales_notification",
  user_register_notification: "user_register_notification",
  notification_announcement: "notification_announcement",
};

export const notificationStatusToProps = {
  success: { icon: <Check />, title: "Success" },
  info: { icon: <Info />, title: "Info" },
  error: { icon: <Block />, title: "Error" },
  warning: { icon: <Warning />, title: "Warning" },
};

export const NotificationColumns = [
  {
    name: "status",
    label: "Status",
    renderComponent: ({ value, row }) => {
      const config =
        notificationStatusToProps[row?.data?.status] ||
        notificationStatusToProps.info;
      const color = notificationStatusToProps?.[row?.data?.status]
        ? row?.status
        : "info";

      return (
        <Tooltip title={config?.title || ""} color={color}>
          {config?.icon}
        </Tooltip>
      );
    },
  },
  { name: "notification_type", label: "Type" },
  {
    name: "title",
    label: "Title",
    renderComponent: ({ row }) => row?.data?.title || "-",
  },
  {
    name: "created_at",
    label: "Created",
    renderComponent: ({ value }) => new Date(value).toLocaleString("el-GR"),
  },
  { name: "actions", label: "Actions", renderComponent: ActionsMenu },
];
