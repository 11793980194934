import ValidationService from "../../services/ValidationService";
import { useDispatch } from "react-redux";
import { updateOrder } from "../../store/actions/orders";
import useForm from "../../store/hooks/useForm";

function useOrderBillingShippingForm({ order, type = "billing" }) {
  const dispatch = useDispatch();
  const key = type === "shipping" ? "shipping_" : "";
  const formConfig = useForm({
    id: {
      defaultValue: order?.id,
      validators: ValidationService.isString,
    },
    [`${key}name`]: {
      defaultValue: order?.[`${key}name`] || order?.name,
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    [`${key}last_name`]: {
      defaultValue: order?.[`${key}last_name`] || order?.last_name,
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    [`${key}email`]: {
      defaultValue: order?.[`${key}email`] || order?.email,
      validators: (value) => ValidationService.validateEmail(value),
    },
    [`${key}country_id`]: {
      defaultValue: order?.[`${key}country_id`] || order?.country_id,
      validators: ValidationService.isString,
    },
    [`${key}city`]: {
      defaultValue: order?.[`${key}city`] || order?.city,
      validators: (value) =>
        ValidationService.validateString({ value, min: 0, max: 255 }),
    },
    [`${key}address`]: {
      defaultValue: order?.[`${key}address`] || order?.address,
      validators: (value) =>
        ValidationService.validateString({ value, min: 0, max: 255 }),
    },
    [`${key}postal_code`]: {
      defaultValue: order?.[`${key}postal_code`] || order?.postal_code,
      validators: (value) =>
        ValidationService.validateString({ value, min: 0, max: 255 }),
    },
    [`${key}phone`]: {
      defaultValue: order?.[`${key}phone`] || order?.phone,
      validators: (value) =>
        ValidationService.validateString({ value, min: 0, max: 255 }),
    },
    shipping_company_id: {
      defaultValue: order?.shipping_company_id || null,
      validators: ValidationService.isString,
    },
    shipping_cost: {
      defaultValue: ValidationService.isNumber(parseFloat(order.shipping_cost))
        ? parseFloat(order.shipping_cost)
        : 0,
      formatter: (value) => {
        if (!ValidationService.isNumber(parseFloat(value))) {
          return 0;
        }
        try {
          return parseFloat(value);
        } catch (error) {
          return value;
        }
      },
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0 }),
    },
    cash_on_delivery: {
      defaultValue: ValidationService.isNumber(
        parseFloat(order.cash_on_delivery)
      )
        ? parseFloat(order.cash_on_delivery)
        : 0,
      formatter: (value) => {
        if (!ValidationService.isNumber(parseFloat(value))) {
          return 0;
        }
        try {
          return parseFloat(value);
        } catch (error) {
          return value;
        }
      },
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0 }),
    },
  });
  const onSubmit = async () => {
    if (!formConfig.isValid) {
      return;
    }

    const { id, ...form } = formConfig.getFormValues();

    return await dispatch(updateOrder(id, form));
  };
  return [formConfig, onSubmit];
}

export default useOrderBillingShippingForm;
