import { Person } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";
import ChangePasswordForm from "../components/auth/ChangePasswordForm";
import { ProfileTabs } from "../components/profile/constants";
import ProfileBasicInfo from "../components/profile/ProfileBasicInfo";
import ProfileForm from "../components/profile/ProfileForm";
import TabContainer from "../components/tabs/TabContainer";
import Title from "../components/ui/Title";
import withAuth from "../hoc/withAuth";
import useAuth from "../store/hooks/useAuth";
import GoBackButton from "../components/ui/GoBackButton";

function ProfilePage() {
  const { user } = useAuth();
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Title divider sx={{ mb: 2 }}>
          <GoBackButton to="/" />
          <Person sx={{ mr: 2, ml: 3 }} />
          Profile
        </Title>
      </Grid>
      <Grid item md={12} xs={12}>
        <TabContainer
          tabs={ProfileTabs}
          sx={{ p: 2, alignItems: "flex-start" }}
        >
          <ProfileBasicInfo user={user} />
          <ProfileForm />
          <ChangePasswordForm />
        </TabContainer>
      </Grid>
    </Grid>
  );
}

export default withAuth(ProfilePage);
