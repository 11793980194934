import React, { useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { WebsitesColumns } from "./constants";
import { toApiUrl } from "../../utils/generic";
import {
  Add,
  Delete,
  Edit,
  List,
  Settings,
  StackedLineChart,
} from "@mui/icons-material";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

export default function Websites() {
  const [entity, setEntity] = useState(null);
  const navigate = useNavigate();
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    title: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1 }),
    },
    description: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1 }),
    },
    meta: {
      defaultValue: {},
    },
    domain: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
  });
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      domain: obj?.domain || "",
      enabled: !!obj?.enabled,
      title: obj?.title || "",
      description: obj?.description || "",
      meta: obj?.meta || {},
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("websites");

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { domain, id, enabled, title, description, meta } =
      formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/websites"), {
          domain,
          enabled,
          title,
          description,
          meta,
        });
      } else {
        res = await axios.put(toApiUrl(`/websites/${id}`), {
          domain,
          enabled,
          title,
          description,
          meta,
        });
      }
      addNotification(`Website ${id ? "Updated" : "Created"}`, "success");

      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification(`Website was not ${id ? "Updated" : "Created"}`, "error");
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/websites/${row.id}`));
      addNotification("Website Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification("Website was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const onAddSections = (row) => {
    navigate(`/websites/${row.id}/sections`);
  };

  const onAnalytics = (row) => {
    navigate(`/websites/${row.id}/analytics`);
  };

  const { form, handleChange, handleBaseChange } = formConfig;
  return (
    <>
      <TableDataLoader
        withFilters
        filtersConfig={[
          { name: "domain", value: "", label: "Domain", type: "text" },
          {
            name: "enabled",
            value: undefined,
            label: "Status",
            type: "select",
            options: [
              { value: "All", label: "All" },
              { value: true, label: "Active" },
              { label: "Disabled", value: false },
            ],
            formatter: (value) => (value === "All" ? undefined : value),
          },
        ]}
        endpoint={toApiUrl("/websites")}
        entityName="results"
        identifier="websites"
        columns={WebsitesColumns}
        actions={[
          {
            name: "analytics",
            title: "Analytics",
            icon: <StackedLineChart />,
            action: onAnalytics,
          },
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "sections",
            title: "Sections",
            icon: <List />,
            action: onAddSections,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <IconButton
              onClick={() => openDialogForm({ domain: "", enabled: false })}
            >
              <Add />
            </IconButton>
          );
        }}
      />
      <SimpleDialog
        maxWidth="xl"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Website
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        <CustomSwitch
          name="enabled"
          label="Status"
          checked={form.enabled.value}
          onChange={handleChange}
        />
        <Input
          sx={{ my: 2 }}
          {...form.domain}
          name="domain"
          label="Domain"
          value={form.domain.value}
          onChange={handleChange}
        />
        <Title divider>
          <Settings sx={{ mr: 2 }} /> Main Page
        </Title>
        <Input
          sx={{ my: 2 }}
          {...form.title}
          name="title"
          label="Title"
          value={form.title.value}
          onChange={handleChange}
        />
        <Input
          sx={{ my: 2 }}
          {...form.description}
          name="description"
          label="Description"
          value={form.description.value}
          onChange={handleChange}
          multiline
          rows={4}
        />
        <Input
          isValid
          isTouched
          sx={{ my: 2 }}
          name="keywords"
          label="Keywords"
          value={form.meta.value?.keywords || ""}
          onChange={(e) =>
            handleBaseChange("meta", {
              ...(form.meta.value || {}),
              keywords: e.target.value,
            })
          }
          multiline
          rows={2}
          helperText="e.g keyword1,keyword2,keyword3"
        />
        <Title divider>
          <Settings sx={{ mr: 2 }} /> Advanced
        </Title>
        <CustomSwitch
          name="coupons"
          label="Enable Coupons"
          checked={!!form.meta.value?.coupons}
          onChange={(e) =>
            handleBaseChange("meta", {
              ...(form.meta.value || {}),
              coupons: e.target.checked,
            })
          }
        />
        <CustomSwitch
          name="allowNotAvailableProducts"
          label="Display Products with zero (0) quantity"
          checked={!!form.meta.value?.allowNotAvailableProducts}
          onChange={(e) =>
            handleBaseChange("meta", {
              ...(form.meta.value || {}),
              allowNotAvailableProducts: e.target.checked,
            })
          }
        />
        <CustomSwitch
          name="blog"
          label="Website Blog"
          checked={!!form.meta.value?.blog}
          onChange={(e) =>
            handleBaseChange("meta", {
              ...(form.meta.value || {}),
              blog: e.target.checked,
            })
          }
        />
        <Input
          isValid
          isTouched
          sx={{ my: 2 }}
          name="xmlAccessKey"
          label="XML Access Key"
          value={form.meta.value?.xmlAccessKey || ""}
          onChange={(e) =>
            handleBaseChange("meta", {
              ...(form.meta.value || {}),
              xmlAccessKey: e.target.value,
            })
          }
          multiline
          rows={2}
        />
      </SimpleDialog>
    </>
  );
}
