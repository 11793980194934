import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import ValidationService from "../../services/ValidationService";
import useForm from "../../store/hooks/useForm";
import NavigationLink from "../ui/NavigationLink";

export default function LoginForm({ onSubmit }) {
  const formConfig = useForm({
    email: {
      defaultValue: "",
      validators: ValidationService.validateEmail,
    },
    password: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({
          min: 8,
          max: 16,
          value,
          noWhiteSpace: true,
        }),
    },
  });

  const { handleChange, form, isTouched, isValid, getFormValues } = formConfig;

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        alt="Panel"
        src={"/logo.png"}
        width="100%"
        style={{ maxWidth: "300px" }}
      />

      <Box
        component="form"
        noValidate
        onSubmit={(e) => {
          onSubmit(e, getFormValues());
        }}
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={handleChange}
          value={form.email.value}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
          value={form.password.value}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={!isValid || !isTouched}
        >
          Sign In
        </Button>
        <Grid container>
          {/* <Grid item xs>
            <NavigationLink to="/request-reset-password" variant="body2">
              Forgot password?
            </NavigationLink>
          </Grid> */}
          {/* <Grid item>
            <NavigationLink to="/register" variant="body2">
              Don't have an account? Sign Up
            </NavigationLink>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
}
