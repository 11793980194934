import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Label } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import OrderStatuses from "../components/orders/OrderStatuses";

function OrderStatusesPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={"Order Statuses"} icon={<Label />} />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <OrderStatuses />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(OrderStatusesPage, [ROLES.SUPER_ADMIN]));
