import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import CustomSwitch from "../ui/Switch";
import { Button } from "@mui/material";
import Title from "../ui/Title";
import { Warning } from "@mui/icons-material";
import useLanguages from "../../store/hooks/useLanguages";

export default function ConfirmationDialog({
  onCancel,
  onConfirm,
  disableConfirm,
  open,
  title,
  disableBackdropClick,
  children,
  ...rest
}) {
  const [confirm, setConfirm] = useState(false);
  const { getStaticTranslation } = useLanguages();
  const renderActions = () => {
    return (
      <>
        <Button
          onClick={() => {
            setConfirm(false);
            if (onCancel) onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disableConfirm || !confirm}
          onClick={() => {
            onConfirm();
            setConfirm(false);
          }}
        >
          Confirm
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (!open) {
      setConfirm(false);
    }
  }, [open]);

  return (
    <Modal
      title={
        title || (
          <Title>
            <Warning color="warning" />{" "}
            {getStaticTranslation("Confirm this action")}
          </Title>
        )
      }
      open={open}
      renderActions={renderActions}
      disableBackdropClick={disableBackdropClick}
      onClose={() => {
        if (onCancel) onCancel();
      }}
    >
      <CustomSwitch
        label={
          confirm ? getStaticTranslation("YES") : getStaticTranslation("NO")
        }
        checked={confirm}
        onChange={(e) => setConfirm(e.target.checked)}
        sx={{ my: 1 }}
      />
      {children}
    </Modal>
  );
}
