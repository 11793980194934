import React, { useState } from "react";
import Container from "../grid/Container";
import Row from "../grid/Row";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import {
  Edit,
  Info,
  LocalShipping,
  Mail,
  Person,
  Phone,
  PinDrop,
} from "@mui/icons-material";
import useOrderBillingShippingForm from "./useOrderBillingShippingForm";
import SimpleDialog from "../dialogs/SimpleDialog";
import OrderBillingShippingForm from "./OrderBillingShippingForm";
import { IconButton } from "@mui/material";

export default function ShippingDetails({ order, withEdit }) {
  const [open, setOpen] = useState(false);
  const [formConfig, onSubmit] = useOrderBillingShippingForm({
    order,
    type: "shipping",
  });
  return (
    <Container>
      <Row>
        <ListView
          //   sx={{ bgcolor: "#f5f5f5" }}
          title={
            <Title divider sx={{ justifyContent: "space-between" }}>
              <Title>
                <Info sx={{ mr: 2 }} /> Shipping Details
              </Title>
              {withEdit ? (
                <IconButton onClick={() => setOpen(true)}>
                  <Edit />
                </IconButton>
              ) : null}
            </Title>
          }
          items={[
            {
              primary: `${order.shipping_name || order.name} ${
                order.shipping_last_name || order.last_name
              }`,
              secondary: "Full Name",
              icon: <Person />,
            },
            {
              primary: `${order.shipping_email || order.email}`,
              secondary: "Email",
              icon: <Mail />,
            },
            {
              primary: `${order.shipping_address || order.address}, ${
                order.shipping_city || order.city || "-"
              }, ${order.shipping_postal_code || order.postal_code}, ${
                order?.shipping_country?.name || order?.country?.name
              }`,
              secondary: "Address",
              icon: <PinDrop />,
            },
            {
              primary: order?.phone || "-",
              secondary: "Phone",
              icon: <Phone />,
            },
            {
              primary: order?.shipping_company?.name || "-",
              secondary: "Shipping Company",
              icon: <LocalShipping />,
            },
            {
              primary: `${order?.shipping_cost || 0} €`,
              secondary: "Shipping Cost",
              icon: <LocalShipping />,
            },
          ].concat(
            order?.cash_on_delivery
              ? {
                  primary: `${order?.cash_on_delivery || 0} €`,
                  secondary: "Cash On Delivery",
                  icon: <LocalShipping />,
                }
              : []
          )}
        />
      </Row>
      <SimpleDialog
        open={open}
        onCancel={() => setOpen(false)}
        disableConfirm={!formConfig.isValid || !formConfig.isTouched}
        onConfirm={async () => {
          const res = await onSubmit();
          if (res) {
            setOpen(false);
          }
        }}
        title={
          <Title>
            <Edit sx={{ mr: 2 }} /> Shipping Details
          </Title>
        }
      >
        <OrderBillingShippingForm {...formConfig} type="shipping" />
      </SimpleDialog>
    </Container>
  );
}
