import React from "react";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { Avatar, Grid, IconButton } from "@mui/material";
import { toServerThumbnailImage } from "../../utils/generic";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import { Add, Remove, ShoppingBag } from "@mui/icons-material";

import CustomButton from "../ui/Button";

export default function DeleteOrderForm({ order, onSubmit }) {
  const orderStatus = order?.order_status?.name;

  const shouldAffectQuantity =
    ["completed", "paid"].indexOf(orderStatus) !== -1;
  const formConfig = useForm({
    products: {
      defaultValue: order?.products?.map((p) => ({
        id: p.id,
        quantity: shouldAffectQuantity ? p.quantity : 0,
        product_id: p.product_id,
        name: p.product.name,
        image: toServerThumbnailImage(p.product.image),
        order_quantity: p.quantity,
      })),
      validators: (value) =>
        (value || []).every((x) =>
          ValidationService.validateNumber({
            value: x.quantity,
            min: 0,
            max: x.order_quantity,
          })
        ),
    },
  });

  const { form, handleBaseChange, isValid } = formConfig;
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <ListView
          title={
            <Title divider>
              <ShoppingBag sx={{ mr: 2 }} /> Add to products' quantity (this
              doesn't affect stock)
            </Title>
          }
          items={form.products.value.map((x, i) => ({
            icon: <Avatar src={x.image} />,
            primary: x.name,
            secondary:
              x.quantity > x.order_quantity || x.quantity < 0 ? (
                <span style={{ color: "red" }}>Invalid quantity</span>
              ) : null,
            renderActions: () => (
              <>
                <IconButton
                  onClick={() =>
                    handleBaseChange(
                      "products",
                      form.products.value.map((p, idx) =>
                        i !== idx ? p : { ...p, quantity: p.quantity - 1 }
                      )
                    )
                  }
                >
                  <Remove />
                </IconButton>
                {x.quantity}
                <IconButton
                  onClick={() =>
                    handleBaseChange(
                      "products",
                      form.products.value.map((p, idx) =>
                        i !== idx ? p : { ...p, quantity: p.quantity + 1 }
                      )
                    )
                  }
                >
                  <Add />
                </IconButton>
              </>
            ),
          }))}
        />
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <CustomButton
          disabled={!isValid}
          fullWidth
          onClick={async () => {
            await onSubmit(formConfig.getFormValues());
          }}
        >
          Submit
        </CustomButton>
      </Grid>
    </Grid>
  );
}
