import React from "react";
import useInventoryForm from "./useInventoryForm";
import InventoryForm from "./InventoryForm";
import { Avatar, Chip, Grid, IconButton } from "@mui/material";
import {
  Save,
  Person2Outlined,
  Edit,
  Check,
  Delete,
} from "@mui/icons-material";

import Title from "../ui/Title";
import { useSuppliers } from "./useSuppliers";
import ListView from "../ui/ListView";
import { useMemo } from "react";
import { getToArrayOrToEmptyArray, toServerImage } from "../../utils/generic";
import useSupplierForm from "./useSupplierForm";
import SupplierForm from "./SupplierForm";
import { useState } from "react";
import CustomButton from "../ui/Button";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export default function ProductInventoryView({ inventory, onSubmit }) {
  const { supplierOptions } = useSuppliers();
  const [deleteEntity, setDeleteEntity] = useState(null);
  const {
    formConfig: supplierFormConfig,
    onSubmit: onSubmitSupplier,
    loading: supplierLoading,
    onDelete,
  } = useSupplierForm({
    inventory_id: inventory?.id,
    product_id: inventory?.product_id,
  });

  const [isEdit, setIsEdit] = useState(false);
  const {
    formConfig,
    loading,
    onSubmit: onSubmitForm,
  } = useInventoryForm(inventory);
  const handleSubmit = async () => {
    const res = await onSubmitForm();
    if (res) {
      onSubmit(res);
    }
  };

  const supplierListViewOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(inventory?.suppliers).map(
      ({ supplier, id, quantity, barcode }) => {
        return {
          primary: (
            <Chip
              label={`${supplier.name || supplier.email}`}
              icon={
                supplierFormConfig?.form?.id?.value === id ? (
                  <Check color="success" />
                ) : null
              }
            />
          ),
          icon: <Avatar src={toServerImage(supplier.image)} />,
          secondary: quantity,
          renderActions: () => {
            return (
              <>
                {" "}
                <IconButton
                  disabled={supplierLoading}
                  onClick={() => {
                    setIsEdit(true);
                    supplierFormConfig.resetForm({
                      product_id: inventory?.product_id,
                      id,
                      supplier_id: supplier?.id,
                      inventory_id: inventory?.id,
                      quantity,
                      barcode,
                    });
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  disabled={supplierLoading}
                  onClick={async () => {
                    setDeleteEntity({
                      product_id: inventory?.product_id,
                      inventory_id: inventory?.id,
                      id,
                    });
                  }}
                >
                  <Delete />
                </IconButton>
              </>
            );
          },
        };
      }
    );
  }, [
    inventory?.id,
    inventory?.product_id,
    inventory?.suppliers,
    supplierFormConfig,
    supplierLoading,
  ]);

  const formattedSupplierOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(supplierOptions).filter(
      (item) =>
        !getToArrayOrToEmptyArray(inventory?.suppliers).some(
          (x) => x.supplier_id === item.value
        )
    );
  }, [inventory?.suppliers, supplierOptions]);

  return (
    <>
      <InventoryForm
        {...formConfig}
        isLoading={loading}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <ListView
              items={supplierListViewOptions}
              title={
                <Title>
                  <Person2Outlined sx={{ mr: 2 }} /> Suppliers
                </Title>
              }
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <SupplierForm
              {...supplierFormConfig}
              supplierOptions={formattedSupplierOptions}
              isEdit={isEdit}
            />
            <Grid container spacing={2} display="flex" justifyContent="center">
              <Grid item>
                <CustomButton
                  disabled={
                    !supplierFormConfig.isValid ||
                    !supplierFormConfig.isTouched ||
                    supplierLoading
                  }
                  startIcon={<Save />}
                  onClick={async () => {
                    const res = await onSubmitSupplier();
                    if (res) {
                      setIsEdit(false);
                      onSubmit(res);
                    }
                  }}
                >
                  Save
                </CustomButton>
              </Grid>
              <Grid item>
                {supplierFormConfig?.form?.id?.value ? (
                  <CustomButton
                    variant="outlined"
                    onClick={() => {
                      supplierFormConfig.resetForm({
                        barcode: "",
                        quantity: 0,
                        inventory_id: inventory?.id,
                        product_id: inventory?.product_id,
                      });
                      setIsEdit(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ConfirmationDialog
          open={!!deleteEntity}
          onCancel={() => setDeleteEntity(null)}
          onConfirm={async () => {
            try {
              const res = await onDelete({
                product_id: deleteEntity.product_id,
                inventory_id: deleteEntity.inventory_id,
                id: deleteEntity.id,
              });
              if (res) {
                setIsEdit(false);
                onSubmit(res);
              }
              setDeleteEntity(null);
            } catch (error) {
              setDeleteEntity(null);
            }
          }}
        />
      </InventoryForm>
    </>
  );
}

//  <Grid container sx={{ my: 3, p: 2 }} spacing={3}>
//    <Grid item md={6} xs={12}>
//      <Title divider>
//        <QrCode sx={{ mr: 2 }} />
//        <Button
//          sx={{ my: 2 }}
//          startIcon={<Download />}
//          onClick={() => handleDownloadClick(`qr-${inventory?.id}`)}
//        >
//          Download
//        </Button>
//      </Title>
//      <div id={`qr-${inventory?.id}`} style={{ padding: "20px" }}>
//        <QRCode value={`${Config.QR_DOMAIN}/inventory/${inventory?.id}`} />
//      </div>
//    </Grid>
//    <Grid item md={6} xs={12}>
//      <Title divider>
//        <Inventory sx={{ mr: 2 }} />
//        <Button
//          sx={{ my: 2 }}
//          startIcon={<Download />}
//          onClick={() => handleDownloadClick(inventory?.id)}
//        >
//          Download
//        </Button>
//      </Title>
//      <div id={inventory?.id}>
//        <Barcode value={inventory?.id} width={1} />
//      </div>
//    </Grid>
//  </Grid>;
