import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { useState } from "react";
import axios from "../../apiClient";
import { deriveSafeResponse, toApiUrl } from "../../utils/generic";
import { addNotification } from "../../store/actions/notifications";

export default function useSupplierForm(supplier) {
  const [loading, setLoading] = useState(false);
  const formConfig = useForm({
    id: {
      defaultValue: supplier?.id,
    },
    supplier_id: {
      defaultValue: supplier?.supplier_id,
      validators: ValidationService.isString,
    },
    product_id: {
      defaultValue: supplier?.product_id,
      validators: ValidationService.isString,
    },
    inventory_id: {
      defaultValue: supplier?.inventory_id,
      validators: ValidationService.isString,
    },
    barcode: {
      defaultValue: supplier?.barcode,
    },
    quantity: {
      defaultValue: !isNaN(parseInt(supplier?.quantity, 10))
        ? parseInt(supplier?.quantity)
        : 0,
      formatter: (value) => {
        if (isNaN(value)) {
          return 0;
        }
        try {
          return isNaN(parseInt(value)) ? 0 : parseInt(value);
        } catch (error) {
          return value;
        }
      },
      validators: (value) => ValidationService.isNumber(value),
    },
  });

  const onDelete = async ({ inventory_id, id, product_id }) => {
    let res;
    try {
      setLoading(true);

      res = await axios.delete(
        toApiUrl(`/inventory/${inventory_id}/suppliers/${id}/${product_id}`)
      );

      addNotification("Action Completed", "success");
      formConfig.resetForm({
        inventory_id,
        product_id: product_id,
        barcode: "",
      });
    } catch (error) {
      addNotification("Action Failed", "error");
    } finally {
      setLoading(false);
      return deriveSafeResponse(res);
    }
  };

  const onSubmit = async () => {
    let res;
    try {
      const { id, inventory_id, ...rest } = formConfig.getFormValues();
      setLoading(true);
      if (!id) {
        res = await axios.post(
          toApiUrl(`/inventory/${inventory_id}/suppliers`),
          rest
        );
      } else {
        res = await axios.put(
          toApiUrl(`/inventory/${inventory_id}/suppliers/${id}`),
          rest
        );
      }
      addNotification("Action Completed", "success");
      formConfig.resetForm({
        inventory_id,
        product_id: rest.product_id,
        barcode: "",
      });
    } catch (error) {
      addNotification("Action Failed", "error");
    } finally {
      setLoading(false);
      return deriveSafeResponse(res);
    }
  };
  return { formConfig, loading, onSubmit, onDelete };
}
