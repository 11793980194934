import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Redeem } from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import { ROLES } from "../data/roles";
import withRoles from "../hoc/withRoles";
import { useDispatch } from "react-redux";
import { getWebsites } from "../store/actions/products";
import useProducts from "../store/hooks/useProducts";
import BackdropLoader from "../components/ui/BackdropLoader";
import Coupons from "../components/coupons/Coupons";

function CouponsPage({ url }) {
  const dispatch = useDispatch();
  const { loading } = useProducts();

  useEffect(() => {
    dispatch(getWebsites());
  }, [dispatch]);
  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={"Coupons"} icon={<Redeem />} />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <Coupons />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(CouponsPage, [ROLES.SUPER_ADMIN, ROLES.ADMIN])
);
