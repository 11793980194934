import { useCallback, useEffect, useState } from "react";
import axios from "../../apiClient";
import { toApiUrl } from "../../utils/generic";

export const useFetchWebsites = () => {
  const [websites, setWebsites] = useState([]);
  const [loading, setLoading] = useState(false);

  const refetch = useCallback(() => {
    setLoading(true);
    axios
      .get(toApiUrl("/static/websites"))
      .then((res) => {
        setWebsites(res.data?.results || []);
      })
      .catch((err) => {
        setWebsites([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { websites, loading, refetch };
};
