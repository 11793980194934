import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function useLanguages() {
  const stateSlice = useSelector((state) => state.languages);

  const getTranslatedObjectProperty = useCallback(
    ({ obj, key, fallback = "" }) => {
      return obj?.[`${key}_${stateSlice.language}`] || fallback;
    },
    [stateSlice.language]
  );

  const getStaticTranslation = useCallback(
    (value) =>
      stateSlice?.translations?.[stateSlice?.language]?.[value] || value,
    [stateSlice?.language, stateSlice?.translations]
  );

  return {
    ...stateSlice,
    getTranslatedObjectProperty,
    getStaticTranslation,
  };
}
