const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  loading: false,
  init: true,
};

export const AuthActions = {
  AUTH_LOADING: "AUTHLOADING",
  AUTHENTICATE: "AUTHENTICATE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  SSO_INIT: "SSO_INIT",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActions.SSO_INIT:
      return {
        ...state,
        init: action.payload,
      };
    case AuthActions.AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AuthActions.AUTHENTICATE:
    case AuthActions.LOGIN:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
};
