import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { CouponsColumns, couponsFiltersConfig } from "./constants";
import {
  getToArrayOrToEmptyArray,
  setInputDateString,
  toApiUrl,
  toServerImage,
  uploadFile,
} from "../../utils/generic";
import { Add, ArrowRight, Delete, Edit } from "@mui/icons-material";
import CustomButton from "../ui/Button";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import FileImport from "../forms/FileImport";
import { Avatar, IconButton } from "@mui/material";
import Message from "../ui/Message";
import { useNavigate } from "react-router-dom";
import useProducts from "../../store/hooks/useProducts";
import SelectInput from "../forms/SelectInput";

export default function Coupons() {
  const navigate = useNavigate();

  const { websites } = useProducts();
  const [entity, setEntity] = useState(null);

  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    max_usage: {
      defaultValue: 0,
      formatter: (value) => (isNaN(value) ? 0 : parseInt(value)),
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0 }),
    },
    discount: {
      defaultValue: 0,
      formatter: (value) =>
        isNaN(value) ? 0 : parseFloat(parseFloat(value).toFixed(2)),
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0, max: 100 }),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
    enabled_until: {
      defaultValue: new Date(),
      validators: (value) => ValidationService.isDate(value),
    },
    image: {
      defaultValue: "",
      validators: (value) => true,
    },
    website_id: {
      defaultValue: "",
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
    },
  });
  const websiteOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(websites).map((website) => ({
      value: website.id,
      label: website.domain,
    }));
  }, [websites]);

  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      name: obj?.name || "",
      enabled_until: setInputDateString(obj?.enabled_until),
      max_usage: obj?.max_usage || 0,
      discount: obj?.discount || 0,
      enabled: !!obj?.enabled,
      image: obj?.image || "",
      website_id: obj?.website_id || "",
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("coupons");

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const {
      name,
      id,
      discount,
      enabled,
      image,
      enabled_until,
      max_usage,
      website_id,
    } = formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/coupons"), {
          name,
          id,
          discount,
          enabled,
          image,
          enabled_until,
          max_usage,
          website_id,
        });
      } else {
        res = await axios.put(toApiUrl(`/coupons/${id}`), {
          name,
          discount,
          enabled,
          image,
          enabled_until,
          max_usage,
          website_id,
        });
      }
      addNotification(`Coupon ${id ? "Updated" : "Created"}`, "success");

      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification(`Coupon was not ${id ? "Updated" : "Created"}`, "error");
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/coupons/${row.id}`));
      addNotification("Coupon Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification("Coupon was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const { form, handleChange } = formConfig;

  const couponFiltersConfigMemoized = useMemo(() => {
    return [
      {
        name: "website_id",
        label: "Website",
        grid: { md: 12, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (websites || []).map((x) => ({
            label: x.domain,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
    ].concat(couponsFiltersConfig);
  }, [websites]);

  return (
    <>
      <TableDataLoader
        withFilters
        filtersConfig={couponFiltersConfigMemoized}
        endpoint={toApiUrl("/coupons")}
        entityName="results"
        identifier={"coupons"}
        columns={CouponsColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <IconButton
                onClick={() => openDialogForm({ name: "" })}
                sx={{ mr: 2 }}
              >
                <Add />
              </IconButton>
            </div>
          );
        }}
      />

      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Coupon
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        {!websiteOptions.length ? (
          <>
            <Message message="No websites were created yet. You have to register at least one website in order to start creating coupons" />
            <CustomButton
              fullWidth
              endIcon={<ArrowRight />}
              onClick={() => navigate("/websites")}
            >
              Go To Websites
            </CustomButton>
          </>
        ) : (
          <>
            {" "}
            {form.image.value ? (
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={toServerImage(form.image.value)}
              />
            ) : null}
            <FileImport
              label="Upload Coupon Image"
              onFileUpload={async (files) => {
                setLoading(true);
                const file = await uploadFile(files);
                if (file) {
                  formConfig.handleBaseChange("image", file?.filename);
                }
                setLoading(false);
              }}
            />
            <CustomSwitch
              name="enabled"
              label="Status"
              checked={form.enabled.value}
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.name}
              name="name"
              label="Name"
              value={form.name.value}
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.enabled_until}
              type="date"
              name="enabled_until"
              label="Expires At"
              focused
              value={form.enabled_until.value}
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.discount}
              name="discount"
              label="Discount"
              value={form.discount.value}
              helperText="0-100%"
              type="number"
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.max_usage}
              name="max_usage"
              label="Max Usage"
              value={form.max_usage.value}
              type="number"
              onChange={handleChange}
            />
            <SelectInput
              sx={{ my: 2 }}
              {...form.website_id}
              label="Website"
              name="website_id"
              options={websiteOptions}
              onChange={handleChange}
            />
          </>
        )}
      </SimpleDialog>
    </>
  );
}
