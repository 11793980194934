import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { ProductsColumns, productFiltersConfig } from "./constants";
import { toApiUrl, toServerImage } from "../../utils/generic";
import {
  Add,
  CheckBox,
  ControlPointDuplicate,
  ControlPointDuplicateOutlined,
  Delete,
  Edit,
  IndeterminateCheckBox,
  Visibility,
} from "@mui/icons-material";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import useProductForm from "./useProductForm";
import ProductForm from "./ProductForm";
import { useNavigate } from "react-router-dom";
import useProducts from "../../store/hooks/useProducts";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import useProductsBulkUpdateForm from "./useProductsBulkUpdateForm";
import ProductBulkUpdateForm from "./ProductBulkUpdateForm";
import withSelectionWrapper from "../dataGrid/SelectionWrapper";
import ListView from "../ui/ListView";
import { useFetchWebsites } from "../../store/hooks/useWebsites";
import DuplicateProductForm from "./DuplicateProductForm";

function Products({ url = "/products/account", onSelectItems, selectedItems }) {
  const [entity, setEntity] = useState(null);
  const [entityToDuplicate, setEntityToDuplicate] = useState(null);
  const { product_supercategories, product_brands } = useProducts();
  const [bulkUpdateDialog, setBulkUpdateDialog] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const { websites } = useFetchWebsites();
  const [
    openDuplicatedProductInNewTabDialogID,
    setOpeDuplicatedProductInNewTabDialogID,
  ] = useState(false);
  const navigate = useNavigate();
  const {
    formConfig,
    setLoading: setFormLoading,
    onSubmit,
    loading: isFormLoading,
  } = useProductForm();

  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm();
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("products");

  const onEdit = (row) => {
    navigate(`/products/${row.id}`);
  };

  const addProductAttributes = async (product) => {
    try {
      await axios.patch(
        toApiUrl(`/products/${product.id}/product-attributes`),
        (product.product_attributes || []).map((x) => x.attribute_id)
      );
    } catch (error) {}
  };

  const onDuplicate = async (newProduct) => {
    let res;
    try {
      if (!newProduct) {
        throw new Error();
      }
      const { id } = newProduct;
      const { product_attributes } = entityToDuplicate;
      setLoading(true);

      await addProductAttributes({
        ...newProduct,
        product_attributes: product_attributes || [],
      });
      addNotification("Product Duplicated", "success");
      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
      onSelectItems((selectedItems || []).filter((x) => x.id !== id));
      setEntityToDuplicate(null);
    } catch (error) {
      addNotification("Product was not Duplicated", "error");
    } finally {
      if (!!newProduct?.id) {
        setOpeDuplicatedProductInNewTabDialogID(newProduct?.id);
      }
      setLoading(false);
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/products/${row.id}`));
      addNotification("Product Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
      onSelectItems((selectedItems || []).filter((x) => x.id !== row.id));
    } catch (error) {
      addNotification("Product was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const productFiltersConfigMemoized = useMemo(() => {
    if (!product_supercategories?.length) {
      return [];
    }

    return [
      {
        grid: {
          md: 6,
          xs: 12,
        },
        name: "nbrand",
        label: "No Brand",
        value: undefined,
        type: "checkbox",
        onChange: (checked) =>
          !checked
            ? {}
            : {
                brand_id: undefined,
              },
      },
      {
        grid: {
          md: 6,
          xs: 12,
        },
        name: "ncategory",
        label: "No Category",
        value: undefined,
        type: "checkbox",
        onChange: (checked) =>
          !checked
            ? {}
            : {
                category_id: undefined,
                supercategory_id: undefined,
                website: undefined,
              },
      },
      {
        grid: {
          md: 3,
          xs: 12,
        },
        isDisabled: (filters) => filters?.ncategory,
        name: "website",
        label: "Website",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (websites || []).map((x) => ({
            label: x.domain,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
        onChange: (value) =>
          !value || value === "All"
            ? {}
            : {
                category_id: undefined,
                supercategory_id: undefined,
              },
      },
      {
        grid: {
          md: 3,
          xs: 12,
        },
        isDisabled: (filters) => filters?.ncategory,
        name: "supercategory_id",
        label: "Supercategory",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          dataSlice?.searchFilters?.website
            ? product_supercategories
                ?.filter((x) =>
                  x?.websites?.some(
                    (p) => p.website_id === dataSlice?.searchFilters?.website
                  )
                )
                .map((x) => ({ label: x.name, value: x.id }))
            : product_supercategories.map((x) => ({
                label: x.name,
                value: x.id,
              }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
        onChange: () => ({
          category_id: undefined,
        }),
      },

      {
        grid: {
          md: 3,
          xs: 12,
        },
        isDisabled: (filters) => filters?.ncategory,
        name: "category_id",
        label: "Category",
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (
            product_supercategories.find(
              (x) => x.id === dataSlice?.searchFilters?.supercategory_id
            )?.categories || []
          ).map((x) => ({
            label: x?.category?.name,
            value: x.category?.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },

      {
        grid: {
          md: 3,
          xs: 12,
        },
        name: "brand_id",
        label: "Brand",
        isDisabled: (filters) => filters?.nbrand,
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (product_brands || []).map((x) => ({
            label: x.name,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
    ].concat(productFiltersConfig);
  }, [
    dataSlice?.searchFilters,
    product_supercategories,
    product_brands,
    websites,
  ]);

  const {
    formConfig: bulkFormConfig,
    enabledConfig,
    setEnabledConfig,
    loading: isBulkUpdateLoading,
    isValid: isBulkValid,
    onSubmit: onBulkSumit,
  } = useProductsBulkUpdateForm({
    brand_id: dataSlice?.searchFilters?.brand_id,
    category_id: dataSlice?.searchFilters?.category_id,
    labels: [dataSlice?.searchFilters?.label].filter(Boolean),
  });

  const openBulkUpdateDialog = () => {
    bulkFormConfig.resetForm({
      brand_id: dataSlice?.searchFilters?.brand_id,
      category_id: dataSlice?.searchFilters?.category_id,
      labels: [dataSlice?.searchFilters?.label].filter(Boolean),
      categories: [],
    });
    setBulkUpdateDialog(true);
  };

  const closeBulkUpdateDialog = () => {
    bulkFormConfig.resetForm({
      brand_id: dataSlice?.searchFilters?.brand_id,
      category_id: dataSlice?.searchFilters?.category_id,
      labels: [dataSlice?.searchFilters?.label].filter(Boolean),
      categories: [],
    });
    setBulkUpdateDialog(false);
  };

  return (
    <>
      <TableDataLoader
        withFilters
        filtersConfig={productFiltersConfigMemoized}
        filters={{ order_by: "created_at:desc" }}
        endpoint={toApiUrl(url)}
        entityName="results"
        identifier="products"
        columns={ProductsColumns}
        withColumnSelection
        withSelection
        disableSelection={false}
        selectedItems={selectedItems}
        selectionId="id"
        onSelect={onSelectItems}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: onEdit,
          },
          {
            name: "duplicate",
            title: "Duplicate",
            icon: <ControlPointDuplicate />,
            action: (row) => {
              const { id, external_id, sku, ...newEntity } = row;
              setEntityToDuplicate(newEntity);
            },
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <>
              {selectedItems?.length ? (
                <>
                  <Tooltip
                    title={`View All ${selectedItems?.length} Items`}
                    disabled={!selectedItems?.length}
                  >
                    <IconButton
                      color="success"
                      onClick={() => setShowSelected(true)}
                    >
                      <CheckBox />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`Unselect All ${selectedItems?.length} Items`}
                    disabled={!selectedItems?.length}
                  >
                    <IconButton color="error" onClick={() => onSelectItems([])}>
                      <IndeterminateCheckBox />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
              <Tooltip title="Columns"></Tooltip>
              <Tooltip title="New Product">
                <IconButton onClick={() => openDialogForm({ name: "" })}>
                  <Add />
                </IconButton>
              </Tooltip>
              <Tooltip title="Bulk Actions" disabled={!dataSlice?.total}>
                <IconButton onClick={() => openBulkUpdateDialog()}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </>
          );
        }}
        defaultSortingColumn={"created_at"}
        defaultOrderBy="desc"
      />
      <SimpleDialog
        maxWidth="lg"
        onCancel={closeDialog}
        onConfirm={async () => {
          const res = await onSubmit();
          if (res) {
            getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
            closeDialog();
          }
        }}
        title={
          <Title>
            <Edit /> {formConfig.form.id.value ? "Edit" : "Add New"} Product
          </Title>
        }
        disableConfirm={
          !formConfig.isValid ||
          !formConfig.isTouched ||
          dataSlice?.loading ||
          isFormLoading
        }
        open={!!entity}
      >
        <ProductForm
          {...formConfig}
          setLoading={setFormLoading}
          isLoading={isFormLoading}
        />
      </SimpleDialog>
      <SimpleDialog
        maxWidth="lg"
        onCancel={closeBulkUpdateDialog}
        onConfirm={async () => {
          const res = await onBulkSumit(
            dataSlice?.searchFilters,
            selectedItems || [],
            onSelectItems
          );
          if (res) {
            getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
            closeBulkUpdateDialog();
          }
        }}
        title={
          <Title>
            <Edit /> Bulk Update Products (
            {selectedItems?.length || dataSlice?.total})
          </Title>
        }
        disableConfirm={
          dataSlice?.loading || isBulkUpdateLoading || !isBulkValid
        }
        open={bulkUpdateDialog}
      >
        <ProductBulkUpdateForm
          {...bulkFormConfig}
          enabledConfig={enabledConfig}
          setEnabledConfig={setEnabledConfig}
          isLoading={isBulkUpdateLoading}
          selectedItems={selectedItems}
        />
      </SimpleDialog>
      <SimpleDialog
        maxWidth="lg"
        onCancel={() => setShowSelected(false)}
        title={
          <Title>
            <Visibility /> Selected Products ({selectedItems?.length || 0})
          </Title>
        }
        open={showSelected}
      >
        {!selectedItems?.length || !showSelected ? null : (
          <ListView
            items={selectedItems?.map((item) => ({
              primary: <Avatar src={toServerImage(item?.image || "")} />,
              secondary: item.name,
              renderActions: () => {
                return (
                  <>
                    <IconButton
                      onClick={() => {
                        const filteredItems = selectedItems.filter(
                          (x) => x.id !== item.id
                        );
                        if (!filteredItems?.length) {
                          setShowSelected(false);
                        }
                        onSelectItems(filteredItems);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </>
                );
              },
            }))}
          />
        )}
      </SimpleDialog>
      <SimpleDialog
        maxWidth="lg"
        onCancel={() => setEntityToDuplicate(null)}
        title={
          <Title>
            <ControlPointDuplicateOutlined /> Duplicate Product
          </Title>
        }
        open={!!entityToDuplicate}
      >
        <DuplicateProductForm
          product={entityToDuplicate}
          onDuplicateSave={onDuplicate}
          onAttributeChange={(product_attributes) => {
            setEntityToDuplicate({ ...entityToDuplicate, product_attributes });
          }}
        />
      </SimpleDialog>
      <SimpleDialog
        maxWidth="xs"
        onCancel={() => setOpeDuplicatedProductInNewTabDialogID(false)}
        onConfirm={() => {
          window.open(`/products/${openDuplicatedProductInNewTabDialogID}`);
          setOpeDuplicatedProductInNewTabDialogID(false);
        }}
        confirmButtonTitle="Go to Product"
        title={"Next Action?"}
        open={!!openDuplicatedProductInNewTabDialogID}
      ></SimpleDialog>
    </>
  );
}

export default withSelectionWrapper(Products);
