import { Avatar, Chip, Tooltip } from "@mui/material";
import { toServerImage } from "../../utils/generic";
import { Block, Check, ShoppingBag } from "@mui/icons-material";
import ActionsMenu from "../dataGrid/ActionsMenu";

export const FavoriteColumns = [
  {
    name: "image",
    label: "image",
    renderComponent: ({ row: { product } }) => (
      <Avatar src={toServerImage(product?.image)} />
    ),
    disableSorting: true,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ row: { product } }) => (
      <Chip icon={<ShoppingBag />} label={product?.name} size="small" />
    ),
    disableSorting: true,
  },
  {
    name: "sku",
    label: "SKU",
    disableSorting: true,
    renderComponent: ({ row: { product } }) => product?.sku,
  },

  {
    name: "price",
    label: "Price",
    renderComponent: ({ row: { product } }) => `${product?.price || 0} €`,
    disableSorting: true,
  },
  {
    name: "quantity",
    label: "Quantity",
    renderComponent: ({ row: { product } }) => product?.quantity,
    disableSorting: true,
  },
  {
    name: "enabled",
    label: "Status",
    disableSorting: true,
    renderComponent: ({ row: { product } }) => (
      <Tooltip title={product?.enabled ? "Enabled" : "Disabled"}>
        {product?.enabled ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
    disableSorting: true,
  },
  {
    name: "actions",
    label: "Actions",
    disableSorting: true,
    renderComponent: ActionsMenu,
  },
];
