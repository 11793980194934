import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import { BlogPostsColumns, blogPostsFiltersConfig } from "./constants";
import {
  getToArrayOrToEmptyArray,
  toApiUrl,
  toServerImage,
  uploadFile,
} from "../../utils/generic";
import { Add, ArrowRight, Delete, Edit, Label } from "@mui/icons-material";
import CustomButton from "../ui/Button";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import FileImport from "../forms/FileImport";
import { Avatar, Chip, IconButton } from "@mui/material";
import Message from "../ui/Message";
import { useNavigate, useParams } from "react-router-dom";
import useProducts from "../../store/hooks/useProducts";
import withSelectionWrapper from "../dataGrid/SelectionWrapper";
import SelectInput from "../forms/SelectInput";
import MDEditor from "@uiw/react-md-editor";
import { Grid } from "@mui/material";
import TabContainer from "../tabs/TabContainer";
import FileLibraryDialog from "../fileLibrary/FileLibraryDialog";

function BlogPosts({ onSelectItems, selectedItems, identifier = "blog" }) {
  const navigate = useNavigate();
  const { group_id } = useParams();
  const { websites } = useProducts();
  const [entity, setEntity] = useState(null);
  const [tag, setTag] = useState("");

  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    title: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    content: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 2 }),
    },

    websites: {
      defaultValue: [],
      validators: (value) => Array.isArray(value),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
    image: {
      defaultValue: "",
      validators: (value) => true,
    },
    keywords: {
      defualtValue: "",
    },
    tags: {
      defaultValue: [],
      validators: (value) => Array.isArray(value),
    },
  });
  const websiteOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(websites).map((website) => ({
      value: website.id,
      label: website.domain,
    }));
  }, [websites]);

  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      title: obj?.title || "",
      content: obj?.content || "",
      enabled: !!obj?.enabled,
      image: obj?.image || "",
      keywords: obj?.keywords || "",
      websites: Array.isArray(obj?.websites)
        ? obj.websites.map((x) =>
            ValidationService.isObject(x) ? x?.website_id : x
          )
        : [],
      tags: Array.isArray(obj?.tags)
        ? obj.tags.filter((x) => typeof x === "string")
        : [],
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice(identifier);

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { title, id, tags, content, enabled, image, keywords, websites } =
      formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/blog"), {
          title,
          content,
          enabled,
          keywords,
          image,
          websites,
          tags: Array.isArray(tags) ? tags : [],
        });
      } else {
        res = await axios.put(toApiUrl(`/blog/${id}`), {
          title,
          content,
          enabled,
          keywords,
          image,
          websites,
          tags: Array.isArray(tags) ? tags : [],
        });
      }
      addNotification(`Blog Post ${id ? "Updated" : "Created"}`, "success");

      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification(
        `Blog Post was not ${id ? "Updated" : "Created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/blog/${row.id}`));
      addNotification("Blog Post Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view, { groups: group_id });
    } catch (error) {
      addNotification("Blog Post was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };
  const websitesOptions = useMemo(() => {
    return Array.isArray(websites)
      ? websites.map((x) => ({ label: x.domain, value: x.id }))
      : [];
  }, [websites]);

  const blogPostsFiltersConfigMemoized = useMemo(() => {
    return [
      {
        name: "website ",
        label: "Website",
        grid: { md: 12, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (websites || []).map((x) => ({
            label: x.domain,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
    ].concat(blogPostsFiltersConfig);
  }, [websites]);
  const { form, handleChange } = formConfig;

  return (
    <>
      <TableDataLoader
        defaultOrderBy="desc"
        defaultSortingColumn="created_at"
        withColumnSelection
        withFilters
        filtersConfig={blogPostsFiltersConfigMemoized}
        filters={{ order_by: "created_at:desc" }}
        onSelect={onSelectItems}
        selectionId="id"
        def
        endpoint={toApiUrl("/blog/account")}
        entityName="results"
        identifier={identifier}
        columns={BlogPostsColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },

          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {selectedItems?.length > 0 ? (
                <IconButton>
                  <Edit />
                </IconButton>
              ) : null}
              <IconButton
                onClick={() =>
                  openDialogForm({
                    title: "",
                    websites: [],
                    content: "",
                  })
                }
                sx={{ mr: 2 }}
              >
                <Add />
              </IconButton>
              <FileLibraryDialog />
            </div>
          );
        }}
      />

      <SimpleDialog
        maxWidth="xl"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Blog Post
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        {!websiteOptions.length ? (
          <>
            <Message message="No websites were created yet. You have to register at least one website in order to start creating blog posts" />
            <CustomButton
              fullWidth
              endIcon={<ArrowRight />}
              onClick={() => navigate("/websites")}
            >
              Go To Websites
            </CustomButton>
          </>
        ) : (
          <TabContainer
            tabs={[{ label: "Basic Info" }, { label: "Content" }]}
            disableUnmount
          >
            <>
              {form.image.value ? (
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={toServerImage(form.image.value)}
                />
              ) : null}
              <FileImport
                label="Upload Blog Image"
                onFileUpload={async (files) => {
                  setLoading(true);
                  const file = await uploadFile(files);
                  if (file) {
                    formConfig.handleBaseChange("image", file?.filename);
                  }
                  setLoading(false);
                }}
              />
              <CustomSwitch
                name="enabled"
                label="Status"
                checked={form.enabled.value}
                onChange={handleChange}
              />
              <Input
                sx={{ my: 2 }}
                {...form.title}
                name="title"
                label="Title"
                value={form.title.value}
                onChange={handleChange}
              />
              <Input
                sx={{ my: 2 }}
                {...form.keywords}
                name="keywords"
                label="Keywords"
                value={form.keywords.value}
                onChange={handleChange}
                helperText="Seperate keywords with comma"
              />
              <SelectInput
                {...form.websites}
                value={form.websites.value || []}
                label="Websites"
                onChange={handleChange}
                options={websitesOptions}
                autoComplete
                multiple
                sx={{ my: 2 }}
              />
              <Grid item md={12} xs={12}>
                <Input
                  {...form.tags}
                  name="labels"
                  onChange={(e) => setTag(e.target.value)}
                  label="Tags"
                  value={tag}
                  endIcon={
                    <IconButton
                      onClick={() => {
                        let value = tag.trim();

                        formConfig.handleBaseChange(
                          "tags",
                          form.tags.value
                            .filter(
                              (x) => x.toLowerCase() !== value.toLowerCase()
                            )
                            .concat(value)
                        );
                        setTag("");
                      }}
                      disabled={tag?.trim()?.length < 2}
                    >
                      <Add />
                    </IconButton>
                  }
                />
                <Grid container spacing={2} sx={{ mt: 2, mb: 5 }}>
                  {(form.tags.value || []).map((label) => (
                    <Grid item key={label}>
                      <Chip
                        icon={<Label />}
                        label={label}
                        onDelete={() => {
                          formConfig.handleBaseChange(
                            "tags",
                            form.tags.value.filter((x) => x !== label)
                          );
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
            <>
              <MDEditor
                style={{ minHeight: "100vh" }}
                data-color-mode="light"
                value={form.content.value}
                onChange={(value) =>
                  formConfig.handleBaseChange("content", value)
                }
              />
            </>
          </TabContainer>
        )}
      </SimpleDialog>
    </>
  );
}

export default withSelectionWrapper(BlogPosts);
