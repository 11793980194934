import React, { useEffect, useState } from "react";
import withAuth from "../hoc/withAuth";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProduct,
  getProductAttributes,
  getProductSuperCategories,
} from "../store/actions/products";
import { connect, useDispatch } from "react-redux";
import useProducts from "../store/hooks/useProducts";
import BackdropLoader from "../components/ui/BackdropLoader";
import { Grid } from "@mui/material";
import EditProductForm from "../components/products/EditProductForm";
import { ProductActions } from "../store/reducers/productReducer";
import TabContainer from "../components/tabs/TabContainer";
import {
  Info,
  Inventory,
  Numbers,
  ReviewsOutlined,
  ShoppingCart,
} from "@mui/icons-material";
// import EditProductAttributes from "../components/products/EditProductAttributes";
import ProductVariants from "../components/products/ProductVariants";
import axios from "axios";
import { toApiUrl } from "../utils/generic";
import ProductInventoryView from "../components/inventory/ProductInventoryView";
import Orders from "../components/orders/Orders";
import ChildrenProductVariants from "../components/products/ChildrenProductVariants";
import ProductReviews from "../components/products/ProductReviews";

const fetchRelatedInventory = async (product_id) => {
  try {
    const { data } = await axios.get(toApiUrl(`/inventory`), {
      params: {
        product_id,
      },
    });

    return data.results[0];
  } catch (error) {
    return null;
  }
};

function ProductPage({ getProduct, getProductSuperCategories }) {
  const { id } = useParams();

  const navigate = useNavigate();
  const { loading, product } = useProducts();
  const [inventory, setInventory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await getProduct(id);
      if (!res) {
        navigate("/404");
      }

      await getProductSuperCategories();
      setInventory(await fetchRelatedInventory(id));
      setIsLoading(false);
    };
    fetchData();
    return () => dispatch({ type: ProductActions.GetProduct, payload: null });
  }, [dispatch, getProduct, getProductSuperCategories, id, navigate]);

  return (
    <>
      <BackdropLoader open={loading || isLoading} />
      <Grid container spacing={2}>
        {product ? (
          <Grid item md={12} xs={12}>
            <TabContainer
              sx={{ p: 2 }}
              tabs={[
                { label: "Basic Info", icon: <Info /> },
                // {
                //   label: "Product Attributes",
                //   icon: <Label />,
                //   disabled: !product_attribute_categories?.length,
                // },
                {
                  label: "Product Variants",
                  icon: <Numbers />,
                },
                {
                  label: "Inventory",
                  icon: <Inventory />,
                },
                {
                  label: "Related Orders",
                  icon: <ShoppingCart />,
                },
                {
                  label: "Reviews",
                  icon: <ReviewsOutlined />,
                },
              ]}
            >
              <EditProductForm
                product={product}
                onSuccess={async () => {
                  try {
                    setIsLoading(true);
                    const res = await getProduct(id);
                    if (!res) {
                      navigate("/404");
                    }

                    await getProductSuperCategories();
                    setInventory(await fetchRelatedInventory(id));
                  } catch (error) {
                  } finally {
                    setIsLoading(false);
                  }
                }}
              />
              {/* <EditProductAttributes product={product} /> */}
              {product?.parent_product ? (
                <ChildrenProductVariants product={product} />
              ) : (
                <ProductVariants product={product} />
              )}
              <ProductInventoryView
                key={inventory?.id}
                inventory={{ ...inventory, product }}
                onSubmit={async () => {
                  setIsLoading(true);
                  setInventory(await fetchRelatedInventory(product?.id));
                  await getProduct(product?.id);
                  setIsLoading(false);
                }}
              />
              {!!product ? (
                <Orders
                  key={product?.id}
                  identifier={`product_orders_${product?.id}`}
                  filters={{ product_ids: product?.id }}
                />
              ) : null}
              <ProductReviews
                product={product}
                key={product?.id}
                refetch={getProduct}
              />
            </TabContainer>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default withAuth(
  connect(null, {
    getProduct,
    getProductAttributes,
    getProductSuperCategories,
  })(ProductPage)
);
