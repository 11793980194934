import { Grid } from "@mui/material";

const Container = ({ spacing = 2, sx, className, style, children }) => {
  return (
    <Grid
      spacing={spacing}
      sx={sx}
      className={className}
      container
      style={style}
    >
      {children}
    </Grid>
  );
};

export default Container;
