import { deriveSafeResponse, toApiUrl } from "../../utils/generic";
import { AuthActions } from "../reducers/authReducer";
import axios, { refreshAccessToken } from "../../apiClient";
import jwtDecode from "jwt-decode";
import ValidationService from "../../services/ValidationService";
import { ROLES } from "../../data/roles";
import { addNotification } from "./notifications";

const getUserFromToken = (token = localStorage.getItem("access_token")) => {
  if (ValidationService.isNullOrUndefinedOrEmpty(token)) return null;
  return jwtDecode(token);
};

const notifyUnVerifiedUser = () => (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    // if (!user?.verified) {
    //   addNotification("Please verify your account", "warning");
    // }
  } catch (error) {}
};

export const setAuthLoading = (loading) => (dispatch) =>
  dispatch({ type: AuthActions.AUTH_LOADING, payload: loading });

export const authenticate = (navigate) => async (dispatch) => {
  let res;
  try {
    const user = getUserFromToken();
    if (!user) {
      throw new Error();
    }
    dispatch({
      type: AuthActions.AUTHENTICATE,
      payload: {
        access_token: localStorage.getItem("access_token"),
        refresh_token: localStorage.getItem("refresh_token"),
        user,
        isAuthenticated: true,
      },
    });
    // dispatch(notifyUnVerifiedUser());
    res = user;
  } catch (error) {
    dispatch(logout());
    if (navigate) {
      navigate("/login");
    }
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");

  dispatch({ type: AuthActions.LOGOUT });
};

export const login = (form) => async (dispatch) => {
  let res;
  try {
    dispatch(setAuthLoading(true));
    res = await axios.post(toApiUrl("/auth/login"), form);
    const { access_token, refresh_token } = res.data;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);

    const user = getUserFromToken();
    dispatch({
      type: AuthActions.LOGIN,
      payload: { access_token, refresh_token, user, isAuthenticated: true },
    });
    addNotification("Welcome", "success");
    dispatch(notifyUnVerifiedUser());
  } catch (error) {
    addNotification("Login failed", "error");
    dispatch(logout());
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const register = (form, type) => async (dispatch) => {
  let res;
  try {
    dispatch(setAuthLoading(true));
    res = await axios.post(toApiUrl(`/auth/register/${type}`), form);
    addNotification("Registration completed", "success");
  } catch (error) {
    addNotification("Registration failed", "error");
    // dispatch(logout());
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const updateProfile =
  (form, user_id = null) =>
  async (dispatch, getState) => {
    let res;
    try {
      dispatch(setAuthLoading(true));
      const {
        user: { id },
      } = getState().auth;
      res = await axios.put(
        toApiUrl(`/users/${user_id || form.id || id}`),
        Object.entries(form).reduce((acc, [key, value]) => {
          acc[key] = ValidationService.isNullOrUndefinedOrEmpty(value)
            ? null
            : value;
          return acc;
        }, {})
      );
      if (user_id === id || form.id === id) {
        await refreshAccessToken(localStorage.getItem("refresh_token"));
        await dispatch(authenticate());
      }
      addNotification("User Info Successfully updated", "success");
    } catch (error) {
      console.log(error);
      addNotification("User Info were not updated", "error");
    } finally {
      dispatch(setAuthLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const changePassword = (form) => async (dispatch, getState) => {
  let res;
  try {
    const {
      user: { id },
    } = getState().auth;
    dispatch(setAuthLoading(true));
    res = await axios.put(toApiUrl(`/users/${id}/password`), form);
    addNotification("Password Successfully changed", "success");
  } catch (error) {
    addNotification("Password was not changed", "error");
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const updatePassword = (id, form) => async (dispatch, getState) => {
  let res;
  try {
    dispatch(setAuthLoading(true));
    res = await axios.put(toApiUrl(`/users/${id}/force-update-password`), form);
    addNotification("Password Successfully changed", "success");
  } catch (error) {
    addNotification("Password was not changed", "error");
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const resendVerifyAccount = () => async (dispatch) => {
  let res;
  try {
    dispatch(setAuthLoading(true));
    res = await axios.post(toApiUrl("/auth/verify-account"));
    addNotification(
      "Verification mail was successfully sent. Check your inbox!",
      "success"
    );
  } catch (error) {
    if (error?.response?.data?.message === "ALREADY_VERIFIED") {
      await dispatch(authenticate());
      addNotification("Your account has already been verified", "info");
    } else {
      addNotification("Verification was not sent. Try again later", "error");
    }
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const requestPasswordReset = (form) => async (dispatch) => {
  let res;
  try {
    dispatch(setAuthLoading(true));
    res = await axios.post(toApiUrl("/auth/forgot-password"), form);
    addNotification(
      "Password reset request sent successfully. Check your inbox!",
      "success"
    );
  } catch (error) {
    addNotification(
      "Password reset request was not sent. Try again later",
      "error"
    );
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const verifyResetPasswordRequest =
  (token) => async (dispatch, getState) => {
    let res;
    try {
      const { isAuthenticated } = getState().auth;
      dispatch(setAuthLoading(true));

      if (isAuthenticated) {
        throw new Error("You cannot access this content");
      }
      res = await axios.get(toApiUrl(`/auth/forgot-password/${token}`));
    } catch (error) {
    } finally {
      dispatch(setAuthLoading(false));
      return deriveSafeResponse(res);
    }
  };

export const resetPassword = (form) => async (dispatch, getState) => {
  let res;
  try {
    dispatch(setAuthLoading(true));
    res = await axios.post(toApiUrl("/auth/reset-password"), form);
    addNotification("Password successfully changed", "success");
  } catch (error) {
    addNotification("Please try again.", "error");
  } finally {
    dispatch(setAuthLoading(false));
    return deriveSafeResponse(res);
  }
};

export const SSOAuthenticate = (token, user) => (dispatch) => {
  dispatch({
    type: AuthActions.AUTHENTICATE,
    payload: { token, user, isAuthenticated: true },
  });
  // dispatch(addNotification("Welcome"));
  // dispatch(notifyUnVerifiedUser());
};

export const SSOInitialized = (value) => (dispatch) => {
  dispatch({ type: AuthActions.SSO_INIT, payload: value });
};

export const isAdmin = (state) => state?.auth?.user?.role === ROLES.ADMIN;
export const isProjectCreator = (state) =>
  state?.auth?.user?.role === ROLES.PROJECT_CREATOR;
export const isInvestor = (state) => state?.auth?.user?.role === ROLES.INVESTOR;
