import React, { useEffect, useState, useCallback, useMemo } from "react";
import withAuth from "../hoc/withAuth";
import { Avatar, Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import {
  Block,
  CalendarMonth,
  Check,
  FileCopy,
  FilterList,
  Person,
  ProductionQuantityLimits,
  Refresh,
} from "@mui/icons-material";
import GoBackButton from "../components/ui/GoBackButton";
import { ROLES } from "../data/roles";
import withRoles from "../hoc/withRoles";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../apiClient";
import { toApiUrl, toServerImage, toSupplierApiUrl } from "../utils/generic";
import BackdropLoader from "../components/ui/BackdropLoader";
import CustomButton from "../components/ui/Button";
import ScanXMLButton from "../components/inventory/ScanXMLButton";
import ValidationService from "../services/ValidationService";
import RestTable from "../components/tables/rest/RestTable";
import Title from "../components/ui/Title";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import CustomDrawer from "../components/ui/CustomDrawer";
import ListView from "../components/ui/ListView";
import AdvancedActions from "../components/inventory/AdvancedActions";
import { useDispatch } from "react-redux";
import {
  getProductBrands,
  getProductCategories,
} from "../store/actions/products";
import TabContainer from "../components/tabs/TabContainer";
import XMLViewer from "../components/suppliers/XMLViewer";

function ManageSupplierPage({ url }) {
  const { id } = useParams();

  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState(null);
  const [superCategories, setSuperCategories] = useState(null);
  const [productFilters, setProductFilters] = useState({});
  const [categories, setCategories] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsPaginationParams, setProductsPaginationParams] = useState({
    page: 1,
    view: 50,
    limit: 0,
    count: 0,
  });
  const [brands, setBrands] = useState(null);
  const [attributeCategories, setAttributeCategories] = useState(null);
  const [entityLoading, setEntityLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchEntity = useCallback((entity, supplier, params) => {
    setEntityLoading(true);
    axios
      .get(toSupplierApiUrl(`/suppliers/${supplier}/${entity}`), {
        params,
      })
      .then((res) => {
        switch (entity) {
          case "groups":
            setGroups(res.data);
            break;
          case "superCategories":
            setSuperCategories(res.data);
            break;
          case "categories":
            setCategories(res.data);
            break;
          case "attributes":
            setAttributeCategories(res.data);
            break;
          case "brands":
            setBrands(res.data);
            break;
          default:
            break;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setEntityLoading(false);
      });
  }, []);

  const fetchProducts = useCallback((supplier, filters) => {
    return async () => {
      setEntityLoading(true);
      try {
        const {
          superCategories = [],
          groups = [],
          categories = [],
          ...rest
        } = filters || {};

        const formattedFilters = Object.entries(filters || {}).reduce(
          (acc, [k, v]) => {
            if (ValidationService.isNullOrUndefinedOrEmpty(v)) {
              return acc;
            }
            if (Array.isArray(v) && !v.length) {
              return acc;
            } else if (Array.isArray(v)) {
              acc[k] = v.map((x) => x.id);
            } else {
              acc[k] = v;
            }

            return acc;
          },
          {}
        );
        // const formattedFilters = !groups?.length
        //   ? rest
        //   : {
        //       ...rest,
        //       groups: groups.map((group) => {
        //         const groupSuperCategories = (group.superCategories || [])
        //           .filter((x) => superCategories.some((p) => p.id === x.id))
        //           .map((p) => p.id);
        //         const groupCategories =
        //         return {
        //           "groups.id": group.id,
        //           ...(groupSuperCategories.length && {
        //             "groups.superCategories": groupSuperCategories,
        //           }),
        //         };
        //       }),
        //     };

        const { data } = await axios.post(
          toSupplierApiUrl(`/suppliers/${supplier}`),
          formattedFilters
        );

        const { results, total: count, ...productsPaginationParams } = data;
        setProducts(results || []);
        setProductsPaginationParams({ ...productsPaginationParams, count });
      } catch (error) {
        console.log(error);
      } finally {
        setEntityLoading(false);
      }
    };
  }, []);

  const fetchSupplier = useCallback(
    (id, withProducts = true) =>
      async () => {
        setLoading(true);

        try {
          const { data } = await axios.get(toApiUrl(`/suppliers/${id}`));
          fetchEntity("groups", data?.name, {});
          fetchEntity("brands", data?.name, {});
          fetchEntity("attributes", data?.name, {});
          const { data: metaData } = await axios.get(
            toSupplierApiUrl(`/suppliers/${data?.name}/metadata`)
          );
          data.metaData = metaData;
          setSupplier(data);
          if (withProducts) {
            await fetchProducts(data?.name, {})();
          }
        } catch (error) {
          navigate(-1);
        } finally {
          setLoading(false);
        }
      },
    [fetchEntity, fetchProducts, navigate]
  );

  const handleUpsert = async (options) => {
    try {
      setLoading(true);
      await axios.post(
        toSupplierApiUrl(`/suppliers/${supplier?.name}/upsert`),
        {
          options,
          ...Object.entries(productFilters || {}).reduce((acc, [k, v]) => {
            if (ValidationService.isNullOrUndefinedOrEmpty(v)) {
              return acc;
            }
            if (Array.isArray(v) && !v.length) {
              return acc;
            } else if (Array.isArray(v)) {
              acc[k] = v.map((x) => x.id);
            } else {
              acc[k] = v;
            }

            return acc;
          }, {}),
        }
      );
      setLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      return false;
    }
  };

  const handleUpdateSupplierOptions = async (options) => {
    let res;
    try {
      setLoading(true);
      res = await axios.put(toApiUrl(`/suppliers/${id}`), {
        meta: { ...(supplier?.meta && supplier?.meta), insertOptions: options },
      });
      await fetchSupplier(id, false)();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSupplier(id)();
    dispatch(getProductCategories());
    dispatch(getProductBrands());
  }, [dispatch, fetchSupplier, id]);

  const supplierCategories = useMemo(() => {
    return (groups || []).flatMap((x) =>
      (x?.superCategories || []).flatMap((p) => p.categories || [])
    );
  }, [groups]);

  if (loading) {
    return <BackdropLoader open={loading} />;
  }

  if (!supplier) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={entityLoading} />

      <Grid item md={12} xs={12}>
        <Title divider sx={{ justifyContent: "space-between" }}>
          <Title>
            <Person />
            {supplier?.name}
          </Title>

          <Title>
            <CalendarMonth /> Last Update:
            {supplier?.metaData?.updatedAt
              ? new Date(supplier?.metaData?.updatedAt).toLocaleString(
                  "en-US",
                  {
                    timeZone: "Europe/Athens",
                  }
                )
              : "-"}
          </Title>
        </Title>
      </Grid>
      <Grid item md={12} xs={12}>
        <TabContainer
          tabs={[
            { label: "Products By XML", icon: <ProductionQuantityLimits /> },
            { label: "XML", icon: <FileCopy /> },
          ]}
          sx={{ p: 2 }}
        >
          <Grid container spacing={3}>
            <Grid item>
              <GoBackButton to={-1} />
            </Grid>

            <Grid item>
              <ScanXMLButton supplier={supplier} />
            </Grid>
            <Grid item>
              <Tooltip title="Refresh Data">
                <IconButton
                  disabled={!supplier || loading}
                  onClick={async () => {
                    await fetchSupplier(id, false)();
                    await fetchProducts(supplier.name, productFilters)();
                  }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Show Filters">
                <IconButton onClick={() => setShowFilters((prev) => !prev)}>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <AdvancedActions
                categories={supplierCategories || []}
                disabled={!products?.length}
                supplier={supplier}
                onSubmit={handleUpsert}
                totalProducts={productsPaginationParams?.count}
                onSaveOptions={handleUpdateSupplierOptions}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <RestTable
                {...productsPaginationParams}
                disableSelection
                rows={products || []}
                onSelect={(...args) => {}}
                id={"id"}
                onPageChange={async (page) =>
                  await fetchProducts(supplier?.name, {
                    ...productFilters,
                    page,
                    view: productsPaginationParams?.view,
                  })()
                }
                onViewChange={async (view) =>
                  await fetchProducts(supplier?.name, {
                    ...productFilters,
                    view,
                    page: productsPaginationParams?.page,
                  })()
                }
                columns={[
                  {
                    disableSorting: true,
                    name: "image",
                    label: "Product",
                    renderComponent: ({ value, row }) => (
                      <IconButton onClick={() => setSelectedProduct(row)}>
                        <Avatar src={toServerImage(value)} />
                      </IconButton>
                    ),
                  },
                  {
                    label: "ID",
                    name: "id",
                  },
                  { label: "Stock", name: "stock" },
                  {
                    label: "Retail Price",
                    name: "retail_price",
                    renderComponent: ({ value }) => <>{value || "-"} &euro;</>,
                  },
                  {
                    label: "Suggested Price",
                    name: "website_price",
                    renderComponent: ({ value }) => <>{value} &euro;</>,
                  },
                  {
                    label: "Name",
                    name: "name",
                  },
                  {
                    disableSorting: true,
                    label: "Status",
                    name: "enabled",
                    renderComponent: ({ value }) =>
                      value ? (
                        <Check color="success" />
                      ) : (
                        <Block color="error" />
                      ),
                  },
                  {
                    label: "Groups",
                    name: "groups",
                    renderComponent: ({ value }) =>
                      (value || []).map((x) => x.name || "-").join(","),
                  },
                  {
                    label: "SuperCategories",
                    name: "superCategories",
                    renderComponent: ({ row }) =>
                      (row?.groups || [])
                        .flatMap((x) =>
                          (x.superCategories || []).map((p) => p.name)
                        )
                        .join(","),
                  },
                  {
                    label: "Categories",
                    name: "categories",
                    renderComponent: ({ row }) =>
                      (row?.groups || [])
                        .flatMap((x) =>
                          (x.superCategories || []).flatMap((p) =>
                            (p.categories || []).map((c) => c.name)
                          )
                        )
                        .join(","),
                  },

                  { label: "Brand", name: "brand" },
                ]}
              />
            </Grid>
            <SimpleDialog
              onCancel={() => setSelectedProduct(null)}
              open={!!selectedProduct}
            >
              {selectedProduct ? (
                <Grid cotnainer spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Title divider>{selectedProduct.name}</Title>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <img
                      width="100%"
                      src={toServerImage(selectedProduct.image)}
                      alt={selectedProduct.name}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </SimpleDialog>
            <CustomDrawer
              variant="temporary"
              open={showFilters}
              onClose={() => setShowFilters(false)}
              drawerWidth={600}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <CustomButton
                    color="primary"
                    variant="outlined"
                    onClick={async () =>
                      await fetchProducts(supplier.name, productFilters)()
                    }
                  >
                    Apply
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton
                    color="error"
                    variant="outlined"
                    onClick={async () => {
                      setProductFilters({});
                      await fetchProducts(supplier.name, {})();
                    }}
                  >
                    Clear
                  </CustomButton>
                </Grid>
                {(groups || []).map((group) => {
                  const isGroupSelected = (productFilters?.groups || [])?.some(
                    (x) => x.id === group.id
                  );

                  return (
                    <Grid item md={12} xs={12} key={group.id}>
                      <ListView
                        fallbackMessage={null}
                        title={
                          <Title>
                            <Checkbox
                              checked={isGroupSelected}
                              onChange={(e, checked) => {
                                setProductFilters((prev) => ({
                                  ...prev,
                                  groups: checked
                                    ? [...(prev.groups || []), group]
                                    : prev.groups.filter(
                                        (p) => p.id !== group.id
                                      ),
                                }));
                              }}
                            />
                            Group: {group.name}
                          </Title>
                        }
                        items={
                          !isGroupSelected
                            ? []
                            : (group.superCategories || []).map(
                                (superCategory) => {
                                  const isSuperCategoryChecked = (
                                    productFilters?.superCategories || []
                                  )?.some((x) => x.id === superCategory.id);
                                  return {
                                    primary: (
                                      <ListView
                                        fallbackMessage={null}
                                        itemSX={{ ml: 4 }}
                                        key={superCategory.id}
                                        title={
                                          <Title>
                                            <Checkbox
                                              checked={isSuperCategoryChecked}
                                              onChange={(e, checked) => {
                                                setProductFilters((prev) => ({
                                                  ...prev,
                                                  superCategories: checked
                                                    ? [
                                                        ...(prev.superCategories ||
                                                          []),
                                                        superCategory,
                                                      ]
                                                    : prev.superCategories.filter(
                                                        (p) =>
                                                          p.id !==
                                                          superCategory.id
                                                      ),
                                                }));
                                              }}
                                            />
                                            Super Category: {superCategory.name}
                                          </Title>
                                        }
                                        items={
                                          !isSuperCategoryChecked
                                            ? []
                                            : (
                                                superCategory.categories || []
                                              ).map((category) => ({
                                                primary: (
                                                  <Title>
                                                    <Checkbox
                                                      checked={(
                                                        productFilters?.categories ||
                                                        []
                                                      )?.some(
                                                        (x) =>
                                                          x.id === category.id
                                                      )}
                                                      onChange={(
                                                        e,
                                                        checked
                                                      ) => {
                                                        setProductFilters(
                                                          (prev) => ({
                                                            ...prev,
                                                            categories: checked
                                                              ? [
                                                                  ...(prev.categories ||
                                                                    []),
                                                                  category,
                                                                ]
                                                              : prev.categories.filter(
                                                                  (p) =>
                                                                    p.id !==
                                                                    category.id
                                                                ),
                                                          })
                                                        );
                                                      }}
                                                    />
                                                    Category: {category.name}
                                                  </Title>
                                                ),
                                              }))
                                        }
                                      />
                                    ),
                                  };
                                }
                              )
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </CustomDrawer>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <XMLViewer
                url={supplier?.website}
                supplier={supplier}
                onSave={async () => await fetchSupplier(id, false)()}
              />
            </Grid>
          </Grid>
        </TabContainer>
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(ManageSupplierPage, [ROLES.SUPER_ADMIN, ROLES.ADMIN])
);
