import {
  AccountCircleOutlined,
  Block,
  Check,
  CheckCircle,
  PeopleAlt,
} from "@mui/icons-material";
import { Avatar, Chip } from "@mui/material";
import { toServerImage } from "../../utils/generic";
import ActionsMenu from "../dataGrid/ActionsMenu";

export const AdministrationTabs = [
  { name: "Users", label: "Users", icon: <AccountCircleOutlined /> },
];

export const UsersColumns = [
  {
    name: "user",
    label: "User",
    renderComponent: ({ row }) => (
      <Avatar src={toServerImage(row.profile_image)} />
    ),
    disableSorting: true,
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "full_name",
    label: "Full Name",
    formatter: (_, row) => `${row.name} ${row.last_name}`,
    disableSorting: true,
  },
  {
    name: "role",
    label: "Role",
    renderComponent: ({ value }) => (
      <Chip
        icon={<PeopleAlt />}
        label={value?.name?.toUpperCase()}
        size="small"
      />
    ),
    disableSorting: true,
  },
  {
    name: "verified",
    label: "Verified",
    renderComponent: ({ value }) =>
      value ? <Check color="success" /> : <Block color="error" />,
    disableSorting: true,
  },
  {
    name: "blacklist",
    label: "Access",
    renderComponent: ({ value }) =>
      !value ? <Check color="success" /> : <Block color="error" />,
    disableSorting: true,
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
    disableSorting: true,
  },
];

export const PendingRequestsColumns = [
  {
    name: "user",
    label: "User",
    renderComponent: ({ row }) => <Avatar src={toServerImage(row.image)} />,
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "full_name",
    label: "Full Name",
    formatter: (_, row) => `${row.firstName} ${row.lastName}`,
  },
  {
    name: "role",
    label: "Role",
    renderComponent: ({ value }) => (
      <Chip label={value?.toUpperCase()} size="small" />
    ),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const PaymentConnectionsColumns = [
  {
    name: "connection_id",
    label: "ID",
  },
  {
    name: "api_key",
    label: "API KEY",
  },
  {
    name: "status",
    label: "Activated",
    renderComponent: ({ value }) =>
      value ? <CheckCircle color="success" /> : <Block color="error" />,
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const ProjectCategoriesColumns = [
  { name: "id", label: "ID" },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => <Chip label={value} />,
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];
