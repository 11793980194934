import { Config } from "../../config";

export const DatabaseStoreApiReference = {
  host: Config.DATABASE_STORE_URL,
  endpoints: [
    {
      name: "Get Collections",
      method: {
        name: "GET",
        color: "success",
      },
      endpoint: (realm) => `/collections`,
      description: (realm) => "Create a new collection",
      body: (realm) => "No Data",
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 200,
          value: JSON.stringify(["collection1", "collection2"], null, 2),
        },

        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Create Collection",
      method: {
        name: "POST",
        color: "info",
      },
      endpoint: (realm) => `/{collection}/schema/create`,
      description: (realm) => "Request user registration",
      body: (realm) => "No Data",
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 201,
          value: JSON.stringify(
            {
              message: "...",
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Drop Collection",
      method: {
        name: "DELETE",
        color: "error",
      },
      endpoint: (realm) => `/{collection}/schema/drop`,
      description: (realm) => "Drop an existing collection",
      body: (realm) => "No Data",
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 200,
          value: JSON.stringify(
            {
              message: "...",
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Get Collection Data",
      method: {
        name: "POST",
        color: "info",
      },
      endpoint: (realm) => `/{collection}`,
      description: (realm) => "Request a list of the current collection's data",
      body: (realm) =>
        JSON.stringify(
          {
            filter: {
              id: "...",
              username: "...",
            },
            limit: 10,
            skip: 0,
            order_by: { last_name: -1, first_name: 1 },
          },
          null,
          2
        ),
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 200,
          value: JSON.stringify(
            {
              collectionName: [{}, {}, {}],
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Get Collection Entity",
      method: {
        name: "POST",
        color: "info",
      },
      endpoint: (realm) => `/{collection}/one`,
      description: (realm) =>
        "Request the first matching entity of this collection based on filters",
      body: (realm) =>
        JSON.stringify(
          {
            filter: {
              id: "...",
              username: "...",
            },
          },
          null,
          2
        ),
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 200,
          value: JSON.stringify(
            {
              first_name: "...",
              last_name: "...",
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Add New Entity To The Collection",
      method: {
        name: "POST",
        color: "info",
      },
      endpoint: (realm) => `/{collection}/create/one`,
      description: (realm) => "Create a new entry for the selected collection",
      body: (realm) =>
        JSON.stringify(
          {
            first_name: "...",
            last_name: "...",
            email: "...",
          },
          null,
          2
        ),
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 201,
          value: JSON.stringify(
            {
              message: "...",
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Add New Entities To The Collection",
      method: {
        name: "POST",
        color: "info",
      },
      endpoint: (realm) => `/{collection}/create/many`,
      description: (realm) =>
        "Create multiple new entries for the selected collection",
      body: (realm) =>
        JSON.stringify(
          {
            data: [
              {
                first_name: "...",
                last_name: "...",
                email: "...",
              },
              {
                first_name: "...",
                last_name: "...",
                email: "...",
              },
              {
                first_name: "...",
                last_name: "...",
                email: "...",
              },
            ],
          },
          null,
          2
        ),
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 201,
          value: JSON.stringify(
            {
              message: "...",
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Update  Collection's Entities",
      method: {
        name: "PUT",
        color: "warning",
      },
      endpoint: (realm) => `/{collection}/update`,
      description: (realm) =>
        "Modify collection's entities matching the given filter",
      body: (realm) =>
        JSON.stringify(
          {
            filter: {
              _id: "...",
            },
            update: {
              last_name: "...",
            },
          },
          null,
          2
        ),
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 200,
          value: JSON.stringify(
            {
              acknowledged: true,
              modifiedCount: 0,
              upsertedId: null,
              upsertedCount: 0,
              matchedCount: 0,
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
    {
      name: "Delete Collection Entities",
      method: {
        name: "DELETE",
        color: "error",
      },
      endpoint: (realm) => `/{collection}/delete`,
      description: (realm) =>
        "Delete collection's entities matching the given filter",
      body: (realm) =>
        JSON.stringify(
          {
            filter: {
              _id: "...",
            },
          },
          null,
          2
        ),
      params: (realm) => "No Data",
      query: (realm) => "No Data",
      headers: (realm) =>
        JSON.stringify(
          {
            Authorization: "Bearer {{refresh_token | access_token}}",
          },
          null,
          2
        ),
      responses: (realm) => [
        {
          status: 200,
          value: JSON.stringify(
            {
              acknowledged: true,
              deletedCount: 0,
            },
            null,
            2
          ),
        },
        {
          status: 500,
          value: JSON.stringify({ message: "..." }, null, 2),
        },
      ],
    },
  ],
};

export const exportTypeOptions = [
  { value: "json", label: "JSON" },
  // { value: "excel", label: "EXCEL" },
  // { value: "csv", label: "CSV" },
];
