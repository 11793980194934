import { useSelector } from "react-redux";
import axios from "../../apiClient";
import { useCallback, useEffect, useState } from "react";
import { toApiUrl } from "../../utils/generic";

export const useFetchProducts = (filters) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refetch = useCallback(() => {
    setLoading(true);
    axios
      .get(toApiUrl("/products/account"), { params: filters || {} })
      .then((res) => {
        setData(res?.data?.results || []);
      })
      .catch((err) => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [refetch]);
  return { data, loading, refetch };
};

export default function useProducts() {
  return useSelector((state) => state.products);
}
