import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { connect } from "react-redux";
import {
  Badge,
  Collapse,
  IconButton,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  AccountCircle,
  ExitToApp,
  Notifications,
  Menu as MenuIcon,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import useAuth from "../../store/hooks/useAuth";
import { NavigationData } from "../../data/navigation";
import useTheme from "../../store/hooks/useTheme";
import { toggleDrawer } from "../../store/actions/theme";
import ThemeSwitch from "../ui/ThemeSwitch";
import { Container } from "@mui/system";
import { logout } from "../../store/actions/auth";
import { useIsCheckout } from "../../store/hooks/usePayments";
const drawerWidth = 300;

function Navbar({ toggleDrawer, logout, children }) {
  const [toggleNavItem, setToggleNavItem] = React.useState(null);
  const { isAuthenticated, user, init } = useAuth();
  const { drawerOpen } = useTheme();
  const { pathname: path } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl) && isAuthenticated;
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl) && isAuthenticated;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu =
    isAuthenticated && init && false ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <Notifications />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    ) : null;
  const menuId = "primary-search-account-menu";

  const renderMenu =
    isAuthenticated && init && false ? (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Menu>
    ) : null;

  const renderNavItems = () => {
    return NavigationData.filter((navItem) =>
      navItem.shouldRender(user?.role?.name, isAuthenticated && init)
    ).map((navItem) =>
      !navItem?.children ? (
        <ListItem key={navItem.title} disablePadding>
          <ListItemButton
            selected={navItem.to === path}
            onClick={() =>
              navItem?.href
                ? window.location.replace(navItem.href)
                : navigate(navItem.to)
            }
          >
            <ListItemIcon>{navItem.icon}</ListItemIcon>
            <ListItemText primary={navItem.title} />
          </ListItemButton>
        </ListItem>
      ) : (
        <div key={navItem.title}>
          <ListItemButton
            onClick={() => {
              try {
                setToggleNavItem((prev) =>
                  prev === navItem.title ? null : navItem.title
                );
              } catch (error) {}
            }}
          >
            <ListItemIcon>{navItem.icon}</ListItemIcon>
            <ListItemText primary={navItem.title} />
            {navItem.title === toggleNavItem ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={toggleNavItem === navItem.title}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {(navItem.children || [])
                .filter((item) =>
                  item?.shouldRender(user?.role?.name, isAuthenticated && init)
                )
                .map((item) => (
                  <ListItem key={item.title} sx={{ pl: 2 }}>
                    <ListItemButton
                      selected={item.to === path}
                      onClick={() =>
                        item?.href
                          ? window.location.replace(item.href)
                          : navigate(item.to)
                      }
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Collapse>
        </div>
      )
    );
  };

  const isCheckout = useIsCheckout();

  if (isCheckout) {
    return children;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          // background: themeMode === "dark" ? "#123" : "#fff",
          background: "#012345",
          color: "#fff",
        }}
      >
        <Toolbar>
          {isAuthenticated ? (
            <IconButton
              size="large"
              edge="start"
              aria-label="open drawer"
              sx={{ mr: 2, color: "white" }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Link
            component={RouterLink}
            to="/"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/logo.png"
              alt="Marketplace LOGO"
              style={{
                maxWidth: "100%",

                maxHeight: "4rem",
              }}
            />{" "}
            {isAuthenticated ? (
              <span style={{ color: "white" }}> Administration</span>
            ) : (
              ""
            )}
          </Link>
          <Box sx={{ flexGrow: 1 }} />

          {isAuthenticated && init && false ? (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <Notifications />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        onClose={toggleDrawer}
        variant="persistent"
        open={drawerOpen && init && isAuthenticated}
        sx={{
          width: drawerOpen && init && isAuthenticated ? drawerWidth : 0,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerOpen ? drawerWidth : 0,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>{renderNavItems()}</List>
          {isAuthenticated && init ? (
            <>
              <Divider />
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      if (["/"].indexOf(path) === -1) navigate("/");
                      logout();
                    }}
                  >
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          ) : null}
          <ListItem>
            <ThemeSwitch />
          </ListItem>
        </Box>
      </Drawer>
      {["/login", "/register"].indexOf(path) === -1 ? (
        <Container maxWidth="xl">
          <Box sx={{ p: 3, my: 12, flexGrow: 1 }}>{children}</Box>
        </Container>
      ) : (
        children
      )}
    </Box>
  );
}

export default connect(null, {
  toggleDrawer,
  logout,
})(Navbar);
