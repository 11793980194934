import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useLanguages from "../../store/hooks/useLanguages";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TabContainer(props) {
  const { getStaticTranslation } = useLanguages();
  const [value, setValue] = React.useState(props.defaultTab || 0);
  const { disableUnmount, children } = props;
  const handleChange = async (event, newValue) => {
    setValue(newValue);
    if (typeof props.onTabChange === "function") {
      if (props.isAsyncChange) await props.onTabChange(event, newValue);
      else props.onTabChange(event, newValue);
    }
  };

  const childrenComponents = Array.isArray(children) ? children : [children];
  const isTabSelected = (idx) => {
    if (props.controlled) {
      return idx === props.currentTabIndex;
    }
    return idx === value;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        ...props?.sx,
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Tabs
            orientation={props.orientation || "horizontal"}
            variant={props.variant ?? "scrollable"}
            value={!props.controlled ? value : props.currentTabIndex || 0}
            onChange={handleChange}
            sx={{
              ...props?.containerSx,
            }}
          >
            {props.tabs.map((tab) => (
              <Tab
                key={tab.label}
                label={getStaticTranslation(tab.label)}
                {...a11yProps(0)}
                icon={tab.icon}
                iconPosition={tab.iconPosition ?? "start"}
                disabled={tab.disabled}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item md={12} xs={12}>
          {disableUnmount ? (
            childrenComponents.map((child, idx) => (
              <Box
                key={idx}
                sx={{
                  // width: "100%",
                  ...props?.boxSx,
                  ...(!isTabSelected(idx) && { display: "none" }),
                }}
              >
                {child}
              </Box>
            ))
          ) : (
            <Box sx={{ width: "100%", ...props?.boxSx }}>
              {
                childrenComponents?.[
                  props?.controlled ? props.currentTabIndex : value
                ]
              }
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
