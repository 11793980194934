import { Lock, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Container, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ValidationService from "../../services/ValidationService";
import { resetPassword } from "../../store/actions/auth";
import useForm from "../../store/hooks/useForm";
import Input from "../forms/Input";
import AnalyticsCard from "../ui/AnalyticsCard";

function ResetPasswordForm({ loading, resetPassword, email, token }) {
  const [togglePasswords, setTogglePasswords] = useState(false);
  const navigate = useNavigate();
  const formConfig = useForm({
    token: {
      defaultValue: token,
      validators: ValidationService.isString,
    },
    email: {
      defaultValue: email,
      validators: ValidationService.validateEmail,
    },
    password: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, data) => value === data.password2.value,
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return {
          [fieldName]: fieldValue,
          password2: form.password2.value,
        };
      },
    },
    password2: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, data) => {
          return value === data.password.value;
        },
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return { [fieldName]: fieldValue, password: form.password.value };
      },
    },
  });
  return (
    <Container maxWidth="md">
      <AnalyticsCard title={"Change Password"} icon={<Lock />} divider>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Input
              {...formConfig.form.password}
              label={"Type your current password"}
              name="password"
              type={togglePasswords ? "text" : "password"}
              onChange={formConfig.handleChange}
              value={formConfig.form.password.value}
              iconAction={() => setTogglePasswords((prev) => !prev)}
              endIcon={
                togglePasswords ? (
                  <IconButton color="primary">
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <Visibility />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              {...formConfig.form.password2}
              label={"Verify your new password"}
              name="password2"
              type={togglePasswords ? "text" : "password"}
              onChange={formConfig.handleChange}
              value={formConfig.form.password2.value}
              iconAction={() => setTogglePasswords((prev) => !prev)}
              endIcon={
                togglePasswords ? (
                  <IconButton color="primary">
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <Visibility />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Button
              startIcon={<Password />}
              disabled={loading || !formConfig.isValid || !formConfig.isTouched}
              variant="contained"
              onClick={async () => {
                const res = await resetPassword(formConfig.getFormValues());
                if (res) {
                  formConfig.resetForm();
                  navigate("/");
                }
              }}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
      </AnalyticsCard>
    </Container>
  );
}

export default connect(({ auth }) => ({ loading: auth.loading }), {
  resetPassword,
})(ResetPasswordForm);
