import {
  Block,
  BrandingWatermark,
  Check,
  Label,
  Language,
  People,
  ShoppingBag,
} from "@mui/icons-material";
import { Avatar, Chip, Rating, Tooltip } from "@mui/material";
import ActionsMenu from "../dataGrid/ActionsMenu";
import {
  getUserFullName,
  toFixedDecimal,
  toServerImage,
} from "../../utils/generic";
import { Link } from "react-router-dom";

export const ProductAttributeCategoriesColumns = [
  {
    name: "name",
    label: "Category",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "updated_at",
    label: "Updated At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const ProductAttributesColumns = [
  {
    name: "name",
    label: "Attribute",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
  },
  {
    name: "category",
    label: "Category",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value?.name || ""} size="small" />
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "updated_at",
    label: "Updated At",
    formatter: (value) => new Date(value).toLocaleString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const ProductSuperCategoriesColumns = [
  {
    name: "image",
    label: "Super Category",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
    selected: false,
    disableSorting: true,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
    selected: true,
  },
  {
    name: "categories",
    label: "Categories",
    renderComponent: ({ value }) => value?.length || 0,
    disableSorting: true,
    selected: true,
  },
  {
    name: "groups",
    label: "Groups",
    renderComponent: ({ value }) => value?.length || 0,
    disableSorting: true,
    selected: true,
  },
  {
    name: "websites",
    label: "Websites",
    renderComponent: ({ value }) => value?.length || 0,
    disableSorting: true,
    selected: true,
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
    disableSorting: true,
    selected: true,
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
    selected: true,
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const GroupsColumns = [
  {
    name: "image",
    label: "Group",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
    selected: false,
    disableSorting: true,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
    selected: true,
  },
  {
    name: "supercategories",
    label: "Super Categories",
    renderComponent: ({ value }) => value?.length || 0,
    selected: true,
    disableSorting: true,
  },
  {
    name: "websites",
    label: "Websites",
    renderComponent: ({ value }) => value?.length || 0,
    disableSorting: true,
    selected: true,
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
    disableSorting: true,
    selected: true,
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
    selected: true,
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const ProductCategoriesColumns = [
  {
    name: "image",
    label: "Category",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
    disableSorting: true,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
    selected: true,
  },
  {
    name: "supercategories",
    label: "Super Categories",
    renderComponent: ({ value }) =>
      Array.isArray(value)
        ? value.map((x) => (
            <Chip
              icon={<Label />}
              label={x?.supercategory?.name || x?.supercategory_id}
              size="small"
            />
          ))
        : null,
    disableSorting: true,
    selected: true,
  },
  {
    name: "products_aggregate",
    label: "Products",
    renderComponent: ({ value }) => value?.count || 0,
    disableSorting: true,
    selected: true,
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
    disableSorting: true,
    selected: true,
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
    selected: true,
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const ProductsColumns = [
  {
    selected: true,
    name: "image",
    label: "Image",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
    disableSorting: true,
  },
  {
    selected: true,
    name: "name",
    label: "Name",
    renderComponent: ({ value, row }) => (
      <Link to={`/products/${row.id}`}>
        <Chip icon={<ShoppingBag />} label={value} size="small" />
      </Link>
    ),
  },
  {
    selected: false,
    name: "sku",
    label: "SKU",
    disableSorting: true,
  },
  {
    name: "super_category",
    label: "Super Category",
    disableSorting: true,

    renderComponent: ({ value, row }) => (
      <Chip
        icon={<Label />}
        label={row?.category?.supercategory?.name}
        size="small"
      />
    ),
  },
  {
    name: "category",
    label: "Category",
    disableSorting: true,

    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value?.name} size="small" />
    ),
  },
  {
    name: "brand",
    label: "Brand",
    disableSorting: true,

    renderComponent: ({ value }) =>
      value ? (
        <Chip icon={<BrandingWatermark />} label={value?.name} size="small" />
      ) : (
        "No brand"
      ),
  },
  {
    selected: true,
    name: "price",
    label: "Price",
    formatter: (value) => `${value} €`,
  },
  {
    selected: true,
    name: "quantity",
    label: "Quantity",
  },
  {
    selected: true,
    name: "enabled",
    label: "Status",
    disableSorting: true,

    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
    selected: true,
  },

  {
    selected: true,
    name: "actions",
    label: "Actions",
    disableSorting: true,

    renderComponent: ActionsMenu,
  },
];

export const ProductsBrandsColumns = [
  {
    name: "image",
    label: "image",
    renderComponent: ({ value }) => <Avatar src={toServerImage(value)} />,
    disableSorting: true,
  },
  {
    name: "name",
    label: "Name",
    renderComponent: ({ value }) => (
      <Chip icon={<ShoppingBag />} label={value} size="small" />
    ),
  },
  {
    name: "enabled",
    label: "Status",
    renderComponent: ({ value }) => (
      <Tooltip title={value ? "Enabled" : "Disabled"}>
        {value ? <Check color="success" /> : <Block color="error" />}
      </Tooltip>
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

export const productFiltersConfig = [
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "name",
    label: "Product Name",
    type: "text",
    formatter: (value) => value || undefined,
  },
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "keywords",
    label: "Keywords",
    type: "text",
    formatter: (value) => value || undefined,
  },
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "from_price",
    label: "From Price €",
    type: "number",
    formatter: (value) => value || undefined,
  },
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "to_price",
    label: "To Price €",
    type: "number",
    formatter: (value) => value || undefined,
  },
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "from_quantity",
    label: "From Quantity",
    type: "number",
    formatter: (value) => value || undefined,
  },
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "to_quantity",
    label: "To Quantity",
    type: "number",
    formatter: (value) => value || undefined,
  },
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "from_weight",
    label: "From Weight (kg)",
    type: "number",
    formatter: (value) => value || undefined,
  },
  {
    grid: {
      md: 6,
      xs: 12,
    },
    name: "to_weight",
    label: "To Weight (kg)",
    type: "number",
    formatter: (value) => value || undefined,
  },

  {
    name: "sku",
    label: "SKU",
    type: "text",
    formatter: (value) => value || undefined,
  },
  {
    name: "enabled",
    label: "Status",
    type: "select",
    value: undefined,
    options: [
      { value: "All", label: "All" },
      { label: "Active", value: true },
      { label: "Disabled", value: false },
    ],
    formatter: (value) => (value === "All" ? undefined : value),
  },
  {
    name: "labels",
    label: "Labels",
    type: "text",
    formatter: (value) => value || undefined,
  },
];
export const productBrandsFiltersConfig = [
  { name: "name", label: "Brand", type: "text" },
  {
    name: "enabled",
    label: "Status",
    type: "select",
    value: undefined,
    options: [
      { value: "All", label: "All" },
      { label: "Active", value: true },
      { label: "Disabled", value: false },
    ],
    formatter: (value) => (value === "All" ? undefined : value),
  },
];

export const productGroupsFiltersConfig = [
  { name: "name", label: "Group", type: "text" },
  {
    name: "enabled",
    label: "Status",
    type: "select",
    value: undefined,
    options: [
      { value: "All", label: "All" },
      { label: "Active", value: true },
      { label: "Disabled", value: false },
    ],
    formatter: (value) => (value === "All" ? undefined : value),
  },
];
export const productCategoriesFiltersConfig = [
  { name: "name", label: "Category", type: "text" },
  {
    name: "enabled",
    label: "Status",
    type: "select",
    value: undefined,
    options: [
      { value: "All", label: "All" },
      { label: "Active", value: true },
      { label: "Disabled", value: false },
    ],
    formatter: (value) => (value === "All" ? undefined : value),
  },
];

export const productSuperCategoriesFiltersConfig = [
  { name: "name", label: "Super Category", type: "text" },
  {
    name: "enabled",
    label: "Status",
    type: "select",
    value: undefined,
    options: [
      { value: "All", label: "All" },
      { label: "Active", value: true },
      { label: "Disabled", value: false },
    ],
    formatter: (value) => (value === "All" ? undefined : value),
  },
];

export const ProductReviewsColumns = [
  {
    name: "rating",
    label: "Rating",
    renderComponent: ({ value }) => (
      <Rating value={toFixedDecimal(value, 0)} precision={0.1} readOnly />
    ),
  },
  {
    name: "user",
    label: "User",
    renderComponent: ({ value }) => (
      <Chip
        icon={<People />}
        label={`${getUserFullName(value)} - ${value?.email}`}
        size="small"
      />
    ),
  },
  {
    name: "website",
    label: "Website",
    renderComponent: ({ value }) => (
      <Chip icon={<Language />} label={value?.domain || "-"} size="small" />
    ),
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];
