import { Checkbox, Chip, Grid, IconButton } from "@mui/material";
import React from "react";
import Input from "../forms/Input";
import SelectInput from "../forms/SelectInput";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getProductBrands,
  getProductCategories,
} from "../../store/actions/products";
import useProducts from "../../store/hooks/useProducts";
import BackdropLoader from "../ui/BackdropLoader";
import { getToArrayOrToEmptyArray } from "../../utils/generic";
import { useState } from "react";
import { Add, Label } from "@mui/icons-material";
import CustomSwitch from "../ui/Switch";

export default function ProductBulkUpdateForm({
  form,
  handleChange,
  handleBaseChange,
  isLoading,
  enabledConfig,
  setEnabledConfig,
  selectedItems,
}) {
  const { product_categories, product_brands, loading } = useProducts();
  const [label, setLabel] = useState("");
  const dispatch = useDispatch();
  const handleEnabledCheck = (e, value) => {
    setEnabledConfig((prev) => ({ ...prev, [e.target.name]: value }));
  };

  useEffect(() => {
    dispatch(getProductCategories());

    dispatch(getProductBrands());
  }, [dispatch]);

  const productCategoryOptions = useMemo(() => {
    if (!product_categories?.length) {
      return [];
    }

    return getToArrayOrToEmptyArray(product_categories).map(
      (product_category) => ({
        label: product_category.name,
        value: product_category.id,
      })
    );
  }, [product_categories]);

  const productBrandsOptions = useMemo(() => {
    if (!product_brands?.length) {
      return [];
    }

    return [{ label: "No brand", value: null }].concat(
      getToArrayOrToEmptyArray(product_brands).map((x) => ({
        label: x.name,
        value: x.id,
      }))
    );
  }, [product_brands]);
  return (
    <Grid container spacing={3}>
      <BackdropLoader open={loading || isLoading} />
      <Grid item md={12} xs={12}>
        <small>* Only checked inputs will be sent for update</small>
      </Grid>
      {/* <Grid item md={12} xs={12}>
        <CustomSwitch
          disabled={!enabledConfig.excludeSelectedProducts}
          type="number"
          name="excludeSelectedProducts"
          checked={form.excludeSelectedProducts.value}
          label="Exclude Selected Products"
          onChange={handleChange}
        />
      </Grid> */}
      <Grid item md={4} xs={12}>
        <Input
          {...form.pricePercentageStep}
          value={form.pricePercentageStep.value}
          disabled={!enabledConfig.pricePercentageStep}
          helperText="* This operation will be applied after the fixed value step"
          label="Transform Prices By Percentage"
          name="pricePercentageStep"
          type="number"
          onChange={handleChange}
        />
        <Checkbox
          checked={enabledConfig.pricePercentageStep}
          name="pricePercentageStep"
          onChange={handleEnabledCheck}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <Input
          {...form.fixedPriceStep}
          value={form.fixedPriceStep.value}
          disabled={!enabledConfig.fixedPriceStep}
          label="Transform Prices By Fixed Value"
          name="fixedPriceStep"
          type="number"
          onChange={handleChange}
        />
        <Checkbox
          checked={enabledConfig.fixedPriceStep}
          name="fixedPriceStep"
          onChange={handleEnabledCheck}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          {...form.discount}
          disabled={!enabledConfig.discount}
          type="number"
          name="discount"
          value={form.discount.value}
          label="Discount %"
          onChange={handleChange}
        />
        <Checkbox
          checked={enabledConfig.discount}
          name="discount"
          onChange={handleEnabledCheck}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          {...form.quantity}
          disabled={!enabledConfig.quantity}
          type="number"
          name="quantity"
          value={form.quantity.value}
          label="Quantity"
          onChange={handleChange}
        />
        <Checkbox
          checked={enabledConfig.quantity}
          name="quantity"
          onChange={handleEnabledCheck}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CustomSwitch
          disabled={!enabledConfig.enabled}
          type="number"
          name="enabled"
          checked={form.enabled.value}
          label="Product Visibility"
          onChange={handleChange}
        />
        <Checkbox
          checked={enabledConfig.enabled}
          name="enabled"
          onChange={handleEnabledCheck}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          {...form.categories}
          disabled={
            !productCategoryOptions?.length || !enabledConfig.categories
          }
          name="categories"
          label="Categories"
          onChange={handleChange}
          options={productCategoryOptions}
          multiple
          autoComplete
        />
        <Checkbox
          checked={enabledConfig.categories}
          name="categories"
          onChange={handleEnabledCheck}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectInput
          {...form.brand_id}
          disabled={!productBrandsOptions?.length || !enabledConfig.brand_id}
          name="brand_id"
          label="Brand"
          onChange={handleChange}
          options={productBrandsOptions}
          autoComplete
        />
        <Checkbox
          checked={enabledConfig.brand_id}
          name="brand_id"
          onChange={handleEnabledCheck}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Input
          disabled={!enabledConfig.labels}
          {...form.labels}
          name="labels"
          onChange={(e) => setLabel(e.target.value)}
          label="Product Labels"
          value={label}
          endIcon={
            <IconButton
              onClick={() => {
                handleBaseChange(
                  "labels",
                  form.labels.value
                    .filter(
                      (x) => x.toLowerCase() !== label.toLocaleLowerCase()
                    )
                    .concat(label.trim())
                );
                setLabel("");
              }}
              disabled={label?.trim()?.length < 2}
            >
              <Add />
            </IconButton>
          }
        />
        <Checkbox
          checked={enabledConfig.labels}
          name="labels"
          onChange={handleEnabledCheck}
        />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {(form.labels.value || []).map((label) => (
            <Grid item key={label}>
              <Chip
                icon={<Label />}
                label={label}
                onDelete={() => {
                  handleBaseChange(
                    "labels",
                    form.labels.value.filter((x) => x !== label)
                  );
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
