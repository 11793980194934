import ValidationService from "../../services/ValidationService";
const persistedLanguage = localStorage.getItem("language");

if (
  ValidationService.isNullOrUndefinedOrEmpty(persistedLanguage) ||
  !ValidationService.isOneOf({
    value: persistedLanguage,
    options: ["en", "gr"],
  })
) {
  localStorage.setItem("language", "gr");
}

const initialState = {
  language: localStorage.getItem("language"),
  translations: {},
};

export const LanguagesActions = {
  SET_LANGUAGE: "SET_LANGUAGE",
};

export const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LanguagesActions.SET_LANGUAGE:
      const isValid = ["en", "gr"].indexOf(action.payload) === -1;
      if (!isValid) {
        return state;
      }
      localStorage.setItem("language", action.payload);
      return {
        ...state,
        language: action.payload,
      };

    default:
      return state;
  }
};
