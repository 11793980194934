import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Category } from "@mui/icons-material";
import ProductAttributeCategories from "../components/products/ProductAttributeCategories";
import GoBackButton from "../components/ui/GoBackButton";

function ProductAttributeCategoriesPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          title={"Product Attribute Categories"}
          icon={<Category />}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid>
      <Grid item md={12} xs={12}>
        <ProductAttributeCategories />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(ProductAttributeCategoriesPage, [ROLES.SUPER_ADMIN])
);
