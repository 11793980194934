import React, { useEffect, useState } from "react";
import withAuth from "../hoc/withAuth";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  BookmarkAdd,
  BookmarkAddedOutlined,
  BookmarkAddedTwoTone,
  CalendarMonth,
  CalendarToday,
  Delete,
  Edit,
  Info,
  Mail,
  Person,
  PhotoAlbum,
  Settings,
} from "@mui/icons-material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Message from "../components/ui/Message";
import { getBoards, setBoardsLoading } from "../store/actions/boards";
import useBoards from "../store/hooks/useBoards";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import GoBackButton from "../components/ui/GoBackButton";
import BackdropLoader from "../components/ui/BackdropLoader";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import {
  getUserFullName,
  toBoardsApiUrl,
  toGreekDate,
  toServerImage,
  uploadFile,
} from "../utils/generic";
import useForm from "../store/hooks/useForm";
import ValidationService from "../services/ValidationService";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import Title from "../components/ui/Title";
import Input from "../components/forms/Input";
import FileImport from "../components/forms/FileImport";
import axios from "../apiClient";
import { addNotification } from "../store/actions/notifications";
import Paginator from "../components/ui/Paginator";
import ConfirmationDialog from "../components/dialogs/ConfirmationDialog";

function BoardsPage({ getBoards, setBoardsLoading, user }) {
  const navigate = useNavigate();

  const { loading, boards, total, page, view } = useBoards();
  useEffect(() => {
    getBoards();
  }, [getBoards]);

  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const {
    form,
    resetForm,
    isTouched,
    isValid,
    handleChange,
    handleBaseChange,
    getFormValues,
  } = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({
          value: value?.trim(),
          min: 2,
          max: 50,
        }),
    },
    description: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({
          value: value?.trim(),
          min: 2,
          max: 2000,
        }),
    },
    image: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({
          value: value?.trim(),
          min: 2,
          max: 2000,
        }),
    },
  });

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={12} xs={12}>
        <AppWidgetSummary title={"Tasks"} icon={<BookmarkAddedOutlined />} />
      </Grid>
      <Grid item>
        <GoBackButton to="/" />
      </Grid>
      <Grid item>
        <Tooltip title="New Task Board">
          <IconButton
            disabled={loading}
            onClick={() => {
              resetForm({});
              setOpen(true);
            }}
          >
            <BookmarkAdd />
          </IconButton>
        </Tooltip>
      </Grid>
      {!boards?.length ? (
        <Grid item md={12} xs={12}>
          <Message message={"Nothing at the moment"} />
        </Grid>
      ) : null}
      <Grid item md={12} xs={12}></Grid>
      {boards?.length
        ? boards.map((board) => (
            <Grid item md={4} xs={12}>
              <AppWidgetSummary
                title={board.name}
                icon={
                  board?.image ? (
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                      }}
                      src={toServerImage(board?.image)}
                      alt={board?.name}
                    />
                  ) : (
                    <BookmarkAddedOutlined />
                  )
                }
              >
                <Tooltip
                  title={board?.description || "No Description Available"}
                >
                  <IconButton>
                    <Info />
                  </IconButton>
                </Tooltip>
                {board?.user?.id === user?.id ? (
                  <>
                    <Tooltip title={"Manage"}>
                      <IconButton
                        disabled={loading}
                        onClick={() => {
                          resetForm({
                            image: board?.image || "",
                            name: board?.name,
                            description: board?.description,
                            id: board?._id,
                          });
                          setOpen(true);
                        }}
                      >
                        <Settings />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete"}>
                      <IconButton
                        disabled={loading}
                        onClick={() => {
                          resetForm({
                            id: board?._id,
                          });
                          setConfirmOpen(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null}
                <Tooltip title={"Tasks"}>
                  <IconButton
                    disabled={loading}
                    onClick={() => {
                      navigate(`/boards/${board._id}`);
                    }}
                  >
                    <BookmarkAddedTwoTone />
                  </IconButton>
                </Tooltip>
                <Grid container spacing={3} sx={{ my: 2 }}>
                  <Grid item md={12} xs={12}>
                    <Title
                      sx={{
                        fontSize: "12px",
                        fontWeight: "100",
                        justifyContent: "center",
                      }}
                    >
                      <CalendarToday sx={{ mr: 2 }} />
                      Created At: {toGreekDate(board?.createdAt)}
                    </Title>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Title
                      sx={{
                        fontSize: "12px",
                        fontWeight: "100",
                        justifyContent: "center",
                      }}
                    >
                      <CalendarMonth sx={{ mr: 2 }} />
                      Updated At: {toGreekDate(board?.updatedAt)}
                    </Title>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Title
                      sx={{
                        fontSize: "12px",
                        fontWeight: "100",
                        justifyContent: "center",
                      }}
                    >
                      <Person sx={{ mr: 2 }} />
                      {getUserFullName(board?.user)}
                    </Title>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Title
                      sx={{
                        fontSize: "12px",
                        fontWeight: "100",
                        justifyContent: "center",
                      }}
                    >
                      <Mail />
                      {board?.user?.email}
                    </Title>
                  </Grid>
                </Grid>
              </AppWidgetSummary>
            </Grid>
          ))
        : null}
      <Grid md={12} xs={12}>
        {!boards?.length ? null : (
          <Paginator
            allowedViews={[6, 12, 24]}
            total={total}
            page={page}
            view={view}
            onChange={(page, view) => getBoards({ page, view })}
          />
        )}
      </Grid>
      <SimpleDialog
        onConfirm={async () => {
          try {
            setBoardsLoading(true);
            const form = getFormValues();
            const { id, ...rest } = form;

            if (id) {
              await axios.put(toBoardsApiUrl(`/boards/${id}`), rest);
            } else {
              await axios.post(toBoardsApiUrl("/boards"), rest);
            }
            await getBoards();
            addNotification("Action Completed", "success");
            resetForm({});
            setOpen(false);
          } catch (error) {
            addNotification("Action Failed", "error");
          } finally {
            setBoardsLoading(false);
          }
        }}
        open={open}
        disableConfirm={!isTouched || !isValid || loading}
        disableBackdropClick={loading}
        onCancel={() => {
          resetForm({});
          setOpen(false);
        }}
        title={
          <Title>
            <Edit sx={{ mr: 2 }} /> {form?.id?.value ? "Edit" : "New"} Task
            Board
          </Title>
        }
      >
        {!open ? null : (
          <>
            {form.image.value ? (
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  maxWidth: "100%",
                  maxHeight: "300px",
                }}
                src={toServerImage(form.image.value)}
                alt="product main"
              />
            ) : null}
            <FileImport
              accept={"image/*"}
              label={
                <Title divider>
                  <PhotoAlbum /> Board Image
                </Title>
              }
              name="image"
              onFileUpload={async (files) => {
                setBoardsLoading(true);
                const file = await uploadFile(files);
                if (file) {
                  handleBaseChange("image", file?.filename);
                }
                setBoardsLoading(false);
              }}
            />

            <Input
              {...form.name}
              name={"name"}
              onChange={handleChange}
              label="Board Name"
              sx={{ my: 2 }}
            />
            <Input
              {...form.description}
              name="description"
              multiline
              rows={4}
              onChange={handleChange}
              label="Board Name"
              helperText={`${
                form.description?.value?.length || 0
              } / 2000 characters`}
              sx={{ my: 2 }}
            />
          </>
        )}
      </SimpleDialog>
      <ConfirmationDialog
        open={confirmOpen}
        onCancel={() => {
          resetForm({});
          setConfirmOpen(false);
        }}
        onConfirm={async () => {
          try {
            setBoardsLoading(true);
            const { id } = getFormValues();
            await axios.delete(toBoardsApiUrl(`/boards/${id}`));
            addNotification("Action Completed", "success");
            await getBoards();
            setConfirmOpen(false);
            resetForm({});
          } catch (error) {
            addNotification("Action Failed", "error");
            setConfirmOpen(false);
            resetForm({});
          } finally {
            setBoardsLoading(false);
          }
        }}
        disableBackdropClick={loading}
        disableConfirm={!form?.id?.value}
      />
    </Grid>
  );
}

export default withAuth(
  withRoles(connect(null, { getBoards, setBoardsLoading })(BoardsPage), [
    ROLES.ADMIN,
    ROLES.SUPER_ADMIN,
  ])
);
