import { Button, Chip, Grid } from "@mui/material";
import React from "react";
import { toServerImage } from "../../utils/generic";
import Input from "../forms/Input";
import { Add, ShoppingBag } from "@mui/icons-material";

export default function InventoryForm({
  form,
  handleChange,
  isLoading,
  onSubmit,
  children,
}) {
  if (!form) {
    return null;
  }

  if (!form.id.value) {
    return (
      <Button
        size="sm"
        startIcon={<Add />}
        disabled={isLoading}
        onClick={onSubmit}
      >
        Create Stock
      </Button>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        {form?.product?.value?.image ? (
          <img
            style={{
              objectFit: "contain",
              height: "300px",
            }}
            src={toServerImage(form.product?.value?.image)}
            alt="product main"
          />
        ) : null}
      </Grid>
      <Grid item>
        <Chip icon={<ShoppingBag />} label={form?.product?.value?.name} />
      </Grid>
      <Grid item md={12}></Grid>

      {children}
    </Grid>
  );
}
