import React, { useEffect, useMemo, useState } from "react";
import axios from "../../apiClient";
import { toApiUrl, toSupplierApiUrl } from "../../utils/generic";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import BackdropLoader from "../ui/BackdropLoader";
import { AccountTreeOutlined, ExpandMore, Settings } from "@mui/icons-material";
import LargeJsonDisplay from "./LargeJsonDisplay";
import ListView from "../ui/ListView";
import SelectInput from "../forms/SelectInput";
import { addNotification } from "../../store/actions/notifications";
import ValidationService from "../../services/ValidationService";
import { productFieldOptions } from "./constants";
import FieldAdvancedOptions from "./FieldAdvancedOptions";

export default function XMLViewer({ url, supplier, onSave }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [formValues, setFormValues] = useState(
    () => supplier?.meta?.xmlMapping || {}
  );

  useEffect(() => {
    if (!url) {
      return;
    }
    setLoading(true);
    axios
      .post(toSupplierApiUrl("/xml-tree"), { url })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  const json = useMemo(() => {
    if (data?.data) {
      return JSON.stringify(data?.data || {}, null, 2);
    }
    return null;
  }, [data?.data]);

  const isValid = useMemo(() => {
    return (
      Object.values(formValues).every(
        (x, idx, items) =>
          !items.some((p, i) => !!p?.key && i !== idx && p?.key === x?.key)
      ) &&
      productFieldOptions.every(
        (f) =>
          !f?.required ||
          (f?.required &&
            Object.values(formValues).some((p) => p?.key === f.value))
      )
    );
  }, [formValues]);

  return (
    <Grid container spacing={3}>
      <BackdropLoader open={loading} />
      <Grid item md={5} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            XML DATA
          </AccordionSummary>
          <AccordionDetails>
            {json ? <LargeJsonDisplay json={json} /> : null}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item md={7} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            XML NODES
          </AccordionSummary>
          <AccordionDetails>
            <ListView
              sx={{ maxHeight: "532px", overflowY: "auto" }}
              itemSX={{ my: 4 }}
              items={(data?.nodePaths || []).map((nodePath) => ({
                primary: nodePath,
                icon: (
                  <Avatar>
                    <AccountTreeOutlined />
                  </Avatar>
                ),

                renderActions: () => (
                  <Grid container spacing={2}>
                    <Grid item>
                      <SelectInput
                        autoComplete
                        value={formValues?.[nodePath]?.key}
                        onChange={(e) => {
                          setFormValues((prev) => {
                            const currentField = productFieldOptions.find(
                              (x) => x.value === e.target.value
                            )?.required;
                            return {
                              ...prev,
                              [nodePath]: {
                                ...prev?.[nodePath],
                                key: e.target.value,
                                functions: structuredClone(
                                  currentField?.functions || []
                                ),
                                required: currentField?.required,
                              },
                            };
                          });
                        }}
                        isValid={isValid}
                        isTouched
                        fullWidth
                        sx={{ width: "200px" }}
                        options={productFieldOptions}
                        label="Product Field"
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Settings">
                        <IconButton
                          onClick={() => setSelectedField(nodePath)}
                          disabled={!formValues?.[nodePath]?.key}
                        >
                          <Settings />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ),
              }))}
            />
            <Button
              variant="contained"
              disabled={!isValid || loading}
              onClick={async () => {
                try {
                  setLoading(true);
                  await axios.put(toApiUrl(`/suppliers/${supplier?.id}`), {
                    meta: {
                      ...(ValidationService.isObject(supplier?.meta) &&
                        supplier.meta),
                      xmlMapping: formValues,
                    },
                  });
                  await onSave();
                  addNotification("Supplier xml mapping updated", "success");
                } catch (error) {
                  addNotification(
                    "Supplier xml mapping was not updated",
                    "success"
                  );
                } finally {
                  setLoading(false);
                }
              }}
            >
              Save
            </Button>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <FieldAdvancedOptions
        open={!!selectedField}
        nodePath={selectedField}
        onClose={() => setSelectedField(null)}
        form={formValues}
        onChange={(newNodeConfig) => {
          setFormValues((prev) => {
            const newForm = {
              ...prev,
              [selectedField]: newNodeConfig,
            };

            return newForm;
          });
        }}
      />
    </Grid>
  );
}
