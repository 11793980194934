import React, { useMemo } from "react";
import { productFieldOptions } from "./constants";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import { Settings } from "@mui/icons-material";
import { Checkbox, Grid } from "@mui/material";

export default function FieldAdvancedOptions({
  form,
  nodePath,
  open,
  onClose,
  onChange,
}) {
  const config = useMemo(() => {
    const field = form?.[nodePath];
    if (!field) {
      return null;
    }
    return productFieldOptions.find((item) => item.value === field?.key);
  }, [form, nodePath]);

  if (!config) {
    return null;
  }

  const renderInput = (input) => {};

  return (
    <SimpleDialog
      open={open}
      onCancel={onClose}
      title={
        <Title>
          <Settings /> {config?.label}
        </Title>
      }
    >
      {config?.functions?.map((func, funcIdx) => {
        const formFunctionValue =
          form?.[nodePath]?.functions?.find((f) => f.value === func?.value) ||
          func;
        return (
          <Grid container spacing={3} key={func.value}>
            <Grid item md={12} xs={12}>
              <Title divider>
                <Checkbox
                  checked={formFunctionValue.defaultChecked}
                  disabled={func.disabled}
                  onChange={(_, checked) => {
                    onChange({
                      ...form[nodePath],
                      functions: config.functions.map((f, i) => {
                        const funcFromForm =
                          form?.[nodePath]?.functions?.find(
                            (fun) => fun.value === f.value
                          ) || f;
                        return func.value !== funcFromForm.value
                          ? f
                          : { ...f, defaultChecked: checked };
                      }),
                    });
                  }}
                />{" "}
                {func.label}
              </Title>
              <small> {func?.helperText}</small>
            </Grid>
          </Grid>
        );
      })}
    </SimpleDialog>
  );
}
