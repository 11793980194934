import {
  Edit,
  FavoriteBorder,
  Info,
  Security,
  ShoppingCart,
} from "@mui/icons-material";

export const ProfileTabs = [
  { name: "basic_info", label: "Basic Info", icon: <Info /> },
  { name: "edit_profile", label: "Edit Profile", icon: <Edit /> },
  { name: "security", label: "Security", icon: <Security /> },
];

export const UserTabs = [
  { name: "edit_profile", label: "Edit Profile", icon: <Edit /> },
  { name: "favorites", label: "User Favorites", icon: <FavoriteBorder /> },
  { name: "edit_profile", label: "User Orders", icon: <ShoppingCart /> },
  { name: "edit_user_password", label: "Security", icon: <Security /> },
];
