import { CssBaseline, ThemeProvider } from "@mui/material";
import Router from "./Router";
import { lightTheme } from "./theme/light";
import { darkTheme } from "./theme/dark";
import Navbar from "./components/navigation/Navbar";
import useTheme from "./store/hooks/useTheme";
import { useMemo } from "react";
import ReduxToastr from "react-redux-toastr";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { themeMode } = useTheme();

  const theme = useMemo(() => {
    return themeMode === "dark" ? darkTheme : lightTheme;
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.notifications}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <CssBaseline />
      <Navbar>
        <Router />
      </Navbar>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}

export default App;
