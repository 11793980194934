import { ShoppingBag } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function OpenProductSupplierPage({ product }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  if (!product) {
    return null;
  }
  const handleNavigation = () => {
    try {
      const { external_id } = product || {};
      if (!external_id) {
        navigate(`/products/${product.id}`);
        return;
      }

      const [supplier, ...rest] = external_id.split(":");
      const product_id = rest.pop();
      if (supplier.toLowerCase().includes("datamedia")) {
        window.open(
          `https://v2.data-media.gr/p/${product_id}`,
          "_blank",
          "rel=noopener noreferrer"
        );
      } else if (supplier.toLowerCase().includes("tradesor")) {
        window.open(
          `https://www.tradesor.gr/?s=${product.name}&post_type=product&dgwt_wcas=1&lang=el`,
          "_blank",
          "rel=noopener noreferrer"
        );
      } else {
        navigate(`/products/${product_id}`);
      }
    } catch (error) {}
  };
  return (
    <Chip
      icon={<ShoppingBag />}
      label={product?.name}
      onClick={handleNavigation}
      size="small"
    />
  );
}
