export const BoardsActions = {
  loading: "boards::loading",
  getBoars: "boards::getBoards",
  getBoard: "boards::getBoard",
};

const initialState = {
  boards: null,
  board: null,
  loading: false,
  page: 1,
  view: 6,
  total: 0,
  limit: 0,
};

export const boardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BoardsActions.getBoard:
      return {
        ...state,
        board: action.payload,
      };
    case BoardsActions.getBoars:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
