import React, { useState } from "react";
import { IntegrationInstructions } from "@mui/icons-material";
import axios from "../../apiClient";
import { toSupplierApiUrl } from "../../utils/generic";
import { addNotification } from "../../store/actions/notifications";
import BackdropLoader from "../ui/BackdropLoader";
import { IconButton, Tooltip } from "@mui/material";

export default function ScanXMLButton({ supplier }) {
  const [loading, setLoading] = useState(false);

  const handleScanXML = async () => {
    setLoading(true);
    axios
      .post(toSupplierApiUrl("/parse"), {
        url: supplier.website,
        name: supplier.name,
      })
      .then((res) => {
        setLoading(false);
        addNotification("XML Scanning started", "success");
      })
      .catch((err) => {
        setLoading(false);

        addNotification(
          err?.response?.data?.message || "Action Failed",
          "error"
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <BackdropLoader open={loading} />
      <Tooltip title="Scan XML">
        <IconButton
          disabled={!supplier?.website || loading}
          onClick={handleScanXML}
        >
          <IntegrationInstructions />
        </IconButton>
      </Tooltip>
    </>
  );
}
