/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Autocomplete, TextField } from "@mui/material";
import ValidationService from "../../services/ValidationService";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function generateRandomId(prefix = "id") {
  return `${prefix}-${Math.floor(Math.random() * 100000)}`;
}

export default function SelectInput({
  name,
  onChange,
  value,
  label,
  startIcon,
  endIcon,
  type,
  isValid,
  isTouched = true,
  text,
  error,
  sx,
  disabled,
  id,
  className,
  style,
  multiple,
  renderValue,
  options,
  fullWidth = true,
  placeholder,
  withChip,
  withCheckBox,
  onDelete,
  placeholderDisabled,
  placeholderValue,
  autoComplete,
  disablePortal,
  limitTags,
}) {
  const [key, setKey] = React.useState(generateRandomId());
  const renderHelperText = () => {
    if (!isValid && isTouched) return error || "";
    if (text) return text;
  };

  const getDisplayValue = React.useMemo(() => {
    if (renderValue) return (selected) => renderValue(selected);

    if (withChip) {
      return (selected) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.map((value, idx) => (
            <Chip
              key={idx}
              label={options.find((opt) => opt.value === value)?.label}
              onDelete={(e) => {
                e.stopPropagation();
                if (onDelete) {
                  onDelete(selected.filter((item) => item !== value));
                }
              }}
            />
          ))}
        </Box>
      );
    }

    if (multiple) {
      return (selected) =>
        options
          .filter((opt) => selected?.some((val) => val === opt.value))
          .map(({ label }) => label)
          .join(", ");
    }

    return () => options.find((opt) => opt.value === value)?.label || "";
  }, [multiple, onDelete, options, renderValue, value, withChip]);

  const renderPlaceholder = () => {
    if (!placeholder) {
      return null;
    }

    return (
      <MenuItem disabled={placeholderDisabled} value={placeholderValue || ""}>
        <em>{placeholder ?? "-"}</em>
      </MenuItem>
    );
  };

  React.useEffect(() => {
    if (autoComplete) {
      setKey((prev) => {
        let k = generateRandomId();
        while (k === prev) {
          k = generateRandomId();
        }
        return k;
      });
    }
  }, [options, autoComplete]);

  if (autoComplete) {
    return (
      <Autocomplete
        size="small"
        key={key}
        renderTags={(value, getTagProps) => {
          return value.map((x, index) => (
            <Chip
              label={options.find((o) => o.value === x)?.label}
              {...getTagProps({ index })}
            />
          ));
        }}
        disablePortal={disablePortal}
        name={name}
        id={id}
        getOptionLabel={(option) => option?.label}
        options={options}
        fullWidth
        renderOption={(props, option) => {
          return (
            <li {...props} key={option?.value || option?.label}>
              {option.label}
            </li>
          );
        }}
        multiple={multiple}
        onChange={(e, newValue) => {
          const value = multiple
            ? [
                ...new Set(
                  (newValue || []).map((x) =>
                    ValidationService.isObject(x) ? x?.value : x
                  )
                ),
              ]
            : newValue?.value || null;
          onChange({
            target: {
              name: name,
              value,
            },
            value,
            persist: () => {},
          });
        }}
        limitTags={limitTags}
        value={
          multiple
            ? (value || []).filter((x) =>
                (options || []).some((p) => p.value === x)
              )
            : (options || []).find((p) => p.value === value)
        }
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            error={isTouched && !isValid}
            name={name}
            disabled={disabled}
            fullWidth
            sx={sx}
            label={label}
            helperText={renderHelperText()}
          />
        )}
      />
    );
  }

  return (
    <FormControl sx={sx} fullWidth={fullWidth}>
      <InputLabel>
        {startIcon} {label} {endIcon}
      </InputLabel>
      <Select
        size="small"
        multiple={multiple}
        id={id}
        MenuProps={MenuProps}
        className={className}
        style={style}
        disabled={disabled}
        value={value}
        name={name}
        type={type}
        error={isTouched && !isValid}
        onChange={onChange}
        input={<OutlinedInput label={label} fullWidth />}
        // {...(typeof renderValue !== 'undefined' && { renderValue: (selected) => renderValue(selected) })}
        renderValue={getDisplayValue}
        helperText={renderHelperText()}
      >
        {renderPlaceholder()}
        {options.map(({ label, value }, idx) => (
          <MenuItem key={idx} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
