import React, { useCallback, useEffect, useState } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import useAuth, { useRoles } from "../store/hooks/useAuth";
import axios from "../apiClient";
import { getUserFullName, toApiUrl } from "../utils/generic";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import Title from "../components/ui/Title";
import GoBackButton from "../components/ui/GoBackButton";
import { Person } from "@mui/icons-material";
import BackdropLoader from "../components/ui/BackdropLoader";
import UserForm from "../components/administration/UserForm";
import TabContainer from "../components/tabs/TabContainer";
import { UserTabs } from "../components/profile/constants";
import UserFavorites from "../components/favorites/UserFavorites";
import Orders from "../components/orders/Orders";
import { getWebsites } from "../store/actions/products";
import { useDispatch } from "react-redux";
import UpdatePassword from "../components/auth/UpdatePassword";

function EditUserPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: authUser } = useAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { roleOptions } = useRoles();

  const getUser = useCallback(
    (id) => {
      setLoading(true);
      return axios
        .get(toApiUrl(`/users/${id}`))
        .then((res) => {
          if (!res.data) {
            navigate("/404");
            return;
          }
          setUser(res.data);
          setLoading(false);
        })
        .catch((err) => {
          navigate("/404");
          setLoading(false);
        });
    },
    [navigate]
  );

  useEffect(() => {
    dispatch(getWebsites());
    getUser(id);
  }, [dispatch, getUser, id]);

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={12} xs={12}>
        <Title divider sx={{ mb: 2 }}>
          <GoBackButton to={-1} />
          <Person sx={{ mr: 2, ml: 3 }} />
          {user ? getUserFullName(user) : ""}
        </Title>
      </Grid>
      {user ? (
        <Grid item md={12} xs={12}>
          <TabContainer tabs={UserTabs} sx={{ p: 2, alignItems: "flex-start" }}>
            <UserForm user={user} roles={roleOptions} loading={loading} />
            <UserFavorites user={user} />
            <Orders identifier="user_orders" filters={{ user_id: user?.id }} />
            <UpdatePassword user_id={id} />
          </TabContainer>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default withAuth(withRoles(EditUserPage, [ROLES.SUPER_ADMIN]));
