import { Chip } from "@mui/material";
import ActionsMenu from "../dataGrid/ActionsMenu";
import { Label } from "@mui/icons-material";

export const NewsletterColumns = [
  {
    name: "email",
    label: "Email",
    renderComponent: ({ value }) => (
      <Chip icon={<Label />} label={value} size="small" />
    ),
  },
  {
    name: "website",
    label: "Website",
    renderComponent: ({ value }) => value?.domain || "-",
    disableSorting: true,
  },
  {
    name: "created_at",
    label: "Created At",
    formatter: (value) => new Date(value).toLocaleString(),
  },

  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
    disableSorting: true,
  },
];

export const newsletterFilters = [
  { name: "email", label: "Email", type: "text" },
];
