import { AttachmentOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import SimpleDialog from "../dialogs/SimpleDialog";
import FileLibraryList from "./FileLibraryList";

export default function FileLibraryDialog() {
  const [open, setOpen] = useState(false);
  if (!open) {
    return (
      <IconButton onClick={() => setOpen(true)}>
        <AttachmentOutlined />
      </IconButton>
    );
  }
  return (
    <SimpleDialog maxWidth="xl" open={open} onCancel={() => setOpen(false)}>
      <FileLibraryList />
    </SimpleDialog>
  );
}
